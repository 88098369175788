.catalog-nav{
	width: 220px;
	display: none;
	flex-shrink: 0;
	@include media($def){
		display: flex;
	}
	&__button{
		display: flex;
		font-size: 14px;
		justify-content: space-between;
		line-height: 24px;
		color: $c-orange;
		font-weight: 500;
		font-family: 'Museo Sans';
		height: 70px;
		width: 100%;
		text-transform: uppercase;
		align-items: center;
		cursor: pointer;
		z-index: 15;
		user-select: none;
		position: relative;
		transition: background-color 0.5s ease;
		&.is-disabled{
			pointer-events: none;
			cursor: default;
		}
		svg{
			width: 40px;
			height: 22px;
			fill: $c-orange;
		}

		.anim-burger{
			display: inline-block;
			width: 40px;
			height: 22px;
			position: relative;
			span{
				display: block;
				position: absolute;
				height: 2px;
				width: 100%;
				background: #ff7e2a;
				border-radius: 9px;
				opacity: 1;
				left: 0;
				transform: rotate(0deg);
				transition: .25s ease-in-out;
				&:nth-child(1) {
					top: 0px;
					left:5px;
					width:25px;
				}

				&:nth-child(2) {
					top: 10px;
					width:100%;
					background: #fe5400;
				}

				&:nth-child(3) {
					top: 20px;
					left: auto;
					right:5px;
					width:25px;
				}
			}
		}
		&.open{
			.anim-burger{
				span:nth-child(1) {
					top: 10px;
					transform: rotate(135deg);
					width:100%;
					left:0;
					right:0;
				}
				span:nth-child(2) {
					opacity: 0;
					left: -20px;
					width:100%;

				}
				span:nth-child(3) {
					top: 10px;
					transform: rotate(-135deg);
					width:100%;
					left:0;
					right:0;
				}
			}
		}
	}
	&__content{
		display: none;
		flex-direction: column;
		position: absolute;
		top: calc(100% + 2px);
		background-color: $c-white;
		left: 0;
		width: 100%;
		z-index: 10;
		opacity: 0;
		visibility: hidden;
		transition: visibility 0s 0.5s ease, opacity 0.5s 0s ease, transform 0.5s 0s ease;
		transform: translateY(-400px);
		@include media($def){
			display: flex;
		}
		&.is-active{
			visibility: visible;
			opacity: 1;
			transition: visibility 0s 0s ease, opacity 0.5s 0.1s ease, transform 0.5s 0.1s ease;
			transform: translateY(0);
		}
	}
	&__list{
		background-color: white;
		list-style-type: none;
		padding: 0;
		margin: 0;
		list-style-image: url(data:0);
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}
	&__list-item{
		width: 20%;
		padding: 0 20px;
		min-width: 250px;
	}
	&__sub-list{
		list-style-type: none;
		padding: 0;
		margin: 0 0 20px 0;
		list-style-image: url(data:0);
	}
	&__unlisted-items{
		width: 20%;
		padding-right: 20px;
		min-width: 280px;
	}
	&__title{
		text-transform: uppercase;
		padding-bottom: 18px;
		padding-top: 6px;
		margin-bottom: 14px;
		border-bottom: 1px solid $c-lblue;
		display: block;
		color: $c-dblue;
		font-size: 14px;
		text-decoration: none;
		font-weight: 700;
		position: relative;
		&:after{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 2px;
			content: "";
			background-color: $c-blue;
			transform: scaleX(0);
			transition: transform 0.3s ease;
			transform-origin: left;
		}
		&:hover {
			color: $c-dark;
			&:after{
				transform: scaleX(1);
			}
		}
	}
	&__link {
		display: inline-block;
		margin-bottom: 10px;
		padding: 1px 0 3px 0;
		font-size: 18px;
		font-weight: 300;
		font-family: 'Museo Sans';
		color: $c-dblue;
		text-decoration: none;
		position: relative;
		transition: border-color 0.5s ease, color 0.5s ease;
		&:after{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 2px;
			content: "";
			background-color: $c-dblue;
			transform: scaleX(0);
			transition: transform 0.3s ease;
			transform-origin: left;
		}
		&:hover {
			color: $c-dark;
			&:after{
				transform: scaleX(1);
			}
		}
	}
}

.catalog-nav-content-wrapper{
	display: flex;
	background-color: $c-white;
	padding: 32px 20px 70px 20px;
	border-radius: 0 0 4px 4px;
	position: relative;
}

.catalog-nav-overlay{
	position: fixed;
	background-color: #33363e;
	opacity: 0;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 49;
	visibility: hidden;
	display: none;
	transition: opacity 0.3s 0s ease, visibility 0s 0.3s ease;

	@include media($def){
		display: block;
	}

	&.is-active{
		opacity: 0.89;
		visibility: visible;
		transition: opacity 0.3s 0.1s ease, visibility 0s 0s ease;
	}
}