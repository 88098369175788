.enter-reg{
	display: flex;
	justify-content: space-between;
	position: relative;
	flex-wrap: wrap;
	padding: 22px 12px 30px;

	@include media($md){
	    flex-wrap: nowrap;
		padding: 22px 66px 50px;
	}

	&__title{
		margin-bottom: 30px;
		font-size: 18px;
		font-weight: 300;
		font-family: 'Museo Sans';
		position: relative;
		z-index: 2;
		padding-bottom: 12px;
		border-bottom: 1px solid #bde0f1;
		padding-right:20px;
		@include media($ms){
			font-size: 20px;
		}
		@include media($md){
			font-size: 24px;
			margin-bottom: 60px;
		}
		@include media($def){
			font-size: 28px;
		}
	}

	&:before{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 82px;
		border-radius: 6px 6px 0 0;
		background-color: #ecf6fb;
		content: "";
	}

	.control-holder{
		width: 100%;
	}
	.form__group{
		width: 100%;
		margin-bottom: 20px;
	}
	.control-holder--text input, .control-holder--text textarea, .control-holder--text select{
		margin-bottom: 0;
	}
	.button{
		margin-top: 8px;
	}
}

.enter-block{
	display:none;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.2s ease, visibility 0s 0.2s ease;
	width: 100%;
	@include media($md){
		width: calc(50% - 20px);
	}
	&.is-active{
		display:block;
		visibility: visible;
		opacity: 1;
		position: relative;
		transition: opacity 0.5s 0.1s ease, visibility 0s ease;
	}
	&__form{
		height: 100%;
		width: 100%;
		.form__group{
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.form__caption{
			width: 150px;
			font-weight: 300;
			font-family: 'Museo Sans';
			font-size: 18px;
			color: $c-black;
		}
	}
}

.repassword-block{
	width: 100%;
	display: none;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.2s ease, visibility 0s 0.2s ease;

	@include media($md){
		width: calc(50% - 20px);
	}
	&.is-active{
		display:block;
		left: 0;
		top: 0;
		visibility: visible;
		opacity: 1;
		position: relative;
		transition: opacity 0.5s 0.1s ease, visibility 0s ease;
	}
	&__form{
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;

		.form__caption{
			font-weight: 300;
			font-family: 'Museo Sans';
			font-size: 18px;
			color: $c-black;
		}
	}
	&__buttons{
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		width: 100%;
		.button{
			margin-bottom: 20px;
		}
	}
}

.reg-block{
	width: 100%;
	margin-top: 20px;
	@include media($md){
		margin-top: 0;
		width: calc(50% - 20px);
	}
	&__form{
		height: 100%;
		width: 100%;

		.form__group{
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.form__caption{
			width: 150px;
			font-weight: 300;
			font-family: 'Museo Sans';
			font-size: 18px;
			color: $c-black;
		}
		.control-holder{
			width: 100%;
		}
	}
}

.lose-password{
	margin-bottom: 14px;
	cursor: pointer;
	transition: color 0.5s ease;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	color: $c-black;
	border-bottom: 1px dotted $c-black;
	font-family: 'Museo Sans';
	display: inline-block;
	&:hover{
		color: $c-orange;
	}
}

.info-user{
	color:#000;
}

.row-lk{
	display:flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin:0px -20px;
}
.col-lk{
	padding:0px 20px;
	display: inline-block;
	flex-basis: auto;
	width:auto;
}
