////
/// @group _partials/view-section.scss
////


// scss
// ================================

.view-section {
	position: relative;
	z-index: 2;
	background-color: $c-white;
}
