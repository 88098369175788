@charset "UTF-8";
@media only screen and (min-width: 320px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 375px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 412px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 480px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 640px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 760px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1025px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1280px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1366px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1440px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1439px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1365px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1279px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1024px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 759px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 639px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 479px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 411px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 374px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 319px) {
  /*! $mqpacker hack */
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure {
  display: block;
}

figure {
  margin: 1em 0;
}

main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
}

b, strong {
  font-weight: bold;
}

code, kbd, samp {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

a:active, a:hover {
  outline-width: 0;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

abbr[title], dfn[title] {
  cursor: help;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[readonly] {
  cursor: default;
}

input:disabled {
  cursor: not-allowed;
}

html {
  position: relative;
  height: 100%;
  font-size: 12px;
}

@media only screen and (min-width: 760px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

*, :after, :before {
  box-sizing: inherit;
}

svg {
  transition: fill 0.3s ease;
}

a {
  color: #43f;
  transition: color 0.3s ease;
}

a:hover {
  color: #fe5400;
}

body {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  min-width: 320px;
  font-family: 'Museo Sans';
  background-color: #fff;
}

pre {
  padding: 1.2em;
  tab-size: 4;
  border-width: 0;
  white-space: pre;
  white-space: pre-wrap;
  background: #eee;
  word-wrap: break-word;
}

img {
  max-width: 100%;
  height: auto;
}

small {
  display: inline-block;
  vertical-align: text-bottom;
}

svg {
  pointer-events: none;
}

hr {
  border-width: 0;
  border-top: 1px solid #aaa;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-moz-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-ms-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

input {
  box-shadow: none;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
  opacity: .5;
}

input:-ms-input-placeholder {
  color: #999;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea {
  box-shadow: none;
}

textarea::-webkit-input-placeholder {
  color: #999;
}

textarea::-moz-placeholder {
  color: #999;
  opacity: .5;
}

textarea:-ms-input-placeholder {
  color: #999;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.view-wrapper {
  position: relative;
  min-height: 100%;
  overflow: hidden;
  padding-top: 60px;
}

@media only screen and (min-width: 1025px) {
  .view-wrapper {
    padding-top: 0;
  }
}

.view-size {
  position: relative;
  max-width: 1600px;
  max-width: calc(1600px + 3rem);
  padding: 0 1.5rem;
  margin: 0 auto;
}

.view-size--no-gap {
  max-width: 1600px;
}

.view-size--md {
  max-width: 1320px;
  max-width: calc(1320px + 3rem);
}

.view-size--sm {
  max-width: 1020px;
  max-width: calc(1020px + 3rem);
}

.view-size--no-pd {
  padding: 0;
}

.view-section {
  position: relative;
  z-index: 2;
  background-color: #fff;
}

.view-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  height: 60px;
  background-color: #fff;
}

@media only screen and (min-width: 1025px) {
  .view-header {
    position: relative;
    height: auto;
  }
}

.view-header__bottom-content {
  display: flex;
  border-top: none;
  position: absolute;
  right: 0;
  top: 8px;
  align-items: center;
}

@media only screen and (min-width: 1025px) {
  .view-header__bottom-content {
    border-top: 1px solid #bde0f1;
    position: relative;
    top: 0;
  }
}

.view-header__top-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  width: 100%;
}

@media only screen and (min-width: 1025px) {
  .view-header__top-content {
    margin-bottom: 18px;
  }
}

.view-header__content {
  position: relative;
  z-index: 15;
  height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
}

@media only screen and (min-width: 1025px) {
  .view-header__content {
    height: auto;
    width: auto;
    display: block;
    align-items: stretch;
  }
}

.view-header__widgets {
  display: flex;
  padding-top: 0;
  padding-right: 50px;
}

@media only screen and (min-width: 760px) {
  .view-header__widgets {
    padding-right: 60px;
  }
}

@media only screen and (min-width: 1025px) {
  .view-header__widgets {
    padding-top: 14px;
    padding-right: 0;
  }
}

.view-header__logo {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .view-header__logo {
    display: block;
  }
}

.view-header__callback-btn {
  align-items: center;
  justify-content: center;
  display: none;
}

@media only screen and (min-width: 1025px) {
  .view-header__callback-btn {
    display: flex;
  }
}

.view-header__callback-btn .button__content {
  height: 48px;
  width: 48px;
  padding: 0;
  transition: background-color 0.3s ease;
}

@media only screen and (min-width: 1280px) {
  .view-header__callback-btn .button__content {
    height: 48px;
    width: 230px;
  }
}

.view-header__callback-btn .button__content span {
  display: none;
}

@media only screen and (min-width: 1280px) {
  .view-header__callback-btn .button__content span {
    display: flex;
  }
}

.view-header__callback-btn .button__content:before, .view-header__callback-btn .button__content:after {
  display: none !important;
}

@media only screen and (min-width: 1280px) {
  .view-header__callback-btn .button__content:before, .view-header__callback-btn .button__content:after {
    display: flex !important;
  }
}

.view-header__callback-btn .button:hover .button__icon {
  fill: #fff;
}

.view-header__callback-btn .button:hover .button__content {
  background-color: #fe5400;
}

.view-header__callback-btn .button__icon {
  display: flex;
  margin: 0;
  width: 28px;
  height: 28px;
  fill: #445568;
}

@media only screen and (min-width: 1280px) {
  .view-header__callback-btn .button__icon {
    display: none;
  }
}

.view-header__contacts-block {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-top: 32px;
  margin: 0 14px;
  display: none;
}

@media only screen and (min-width: 1025px) {
  .view-header__contacts-block {
    display: flex;
  }
}

.view-footer {
  padding-bottom: 36px;
  width: 100%;
  z-index: 0;
  background-color: #fff;
  padding-top: 50px;
}

.view-footer.touch-fix {
  position: relative;
}

.view-footer__bottom-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 1025px) {
  .view-footer__bottom-row {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.view-footer__logo {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1025px) {
  .view-footer__logo {
    margin-bottom: 0;
  }
}

.view-footer__addres {
  font-weight: 500;
  font-size: 18px;
  color: #445568;
  margin-bottom: 10px;
}

.view-footer__contacts-group {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .view-footer__contacts-group {
    display: block;
  }
}

.view-footer__title {
  margin-bottom: 32px;
  font-size: 18px;
  color: #445568;
  font-weight: 700;
}

.view-footer__graph {
  font-weight: 500;
  font-size: 14px;
  color: #445568;
  margin-bottom: 16px;
}

.view-footer__categorys {
  margin: 0 20px;
  display: none;
}

@media only screen and (min-width: 1025px) {
  .view-footer__categorys {
    display: block;
  }
}

.view-footer__nav {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .view-footer__nav {
    display: block;
  }
}

.view-footer__top-row {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 1025px) {
  .view-footer__top-row {
    justify-content: space-between;
  }
}

.view-footer__copy {
  margin-bottom: 14px;
}

.view-footer__btns-row {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}

.view-footer__btns-row .button {
  margin: 0 22px 12px 0;
}

.view-footer .phones-block {
  flex-wrap: wrap;
}

.zoom-in {
  /* animate in */
  /* animate out */
  /* Dark overlay, start state */
  /* animate in */
  /* animate out */
}

.zoom-in .hidden-wrapper {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(0.8);
}

.zoom-in.mfp-ready .hidden-wrapper {
  opacity: 1;
  transform: scale(1);
}

.zoom-in.mfp-removing .hidden-wrapper {
  transform: scale(0.8);
  opacity: 0;
}

.zoom-in.mfp-bg {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.hidden-wrapper {
  position: relative;
  padding: 0;
  margin: 1.2rem auto;
  background-color: #fff;
  width: 96%;
  max-width: 1024px;
  border-radius: 4px;
}

.hidden-wrapper--lg {
  max-width: 1280px;
}

.hidden-wrapper--md {
  max-width: 876px;
}

.hidden-wrapper--sm {
  max-width: 480px;
}

.hidden-wrapper--strip {
  background: none;
  padding: 0;
}

.hidden-wrapper--no-gap {
  padding: 0;
}

.hidden-wrapper ._show-in--hidden-wrapper {
  display: block;
}

.view-text {
  color: inherit;
  position: relative;
  font-size: 1rem;
  line-height: 1.5;
}

.view-text blockquote > :first-child, .view-text > :first-child {
  margin-top: 0;
}

.view-text blockquote > :last-child, .view-text > :last-child {
  margin-bottom: 0;
}

.view-text--chara table tr td:first-of-type {
  position: relative;
}

.view-text--chara table tr td:first-of-type:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
  content: ":";
  color: #4da2cb;
  font-weight: 900;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
}

.view-text h1, .view-text h2, .view-text h3, .view-text h4, .view-text h5, .view-text h6 {
  clear: both;
  line-height: 100%;
  font-weight: 500;
  color: #445568;
  margin: 18px 0 22px;
}

.view-text h1 {
  font-size: 36px;
}

.view-text h2 {
  font-size: 2.25em;
}

.view-text h3 {
  font-size: 24px;
}

.view-text h4 {
  font-size: 1.5em;
}

.view-text h5 {
  font-size: 1.25em;
}

.view-text h6 {
  font-size: 1em;
}

.view-text p, .view-text ul, .view-text ol, .view-text hr, .view-text dl, .view-text pre, .view-text address, .view-text blockquote, .view-text table,
.view-text .table-wrapper, .view-text .media-wrapper {
  margin: 1em 0;
}

.view-text p {
  font-size: 16px;
  color: #445568;
  line-height: 24px;
  font-weight: 300;
}

.view-text blockquote {
  padding: 1em 1.5em;
  background-color: #f7f8e2;
}

.view-text hr {
  clear: both;
  border-width: 0;
  border-top-width: 1px;
}

.view-text ul, .view-text ol {
  padding-left: 27px;
  margin: 1.6em 0;
}

.view-text li {
  padding-left: 7px;
  color: #4da2cb;
  font-weight: 500;
}

.view-text li span {
  color: #445568;
}

.view-text dd {
  margin-left: 1.5em;
}

.view-text dt {
  font-weight: bold;
}

.view-text dd + dt {
  margin: 1em 0 0;
}

.view-text pre, .view-text code, .view-text kbd, .view-text samp {
  font-style: normal;
  font-weight: normal;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
}

.view-text pre {
  tab-size: 4;
  padding: 1em 1.5em;
  background-color: #f5f5f5;
}

.view-text code, .view-text kbd, .view-text samp {
  padding: 0 .4em;
}

.view-text code {
  background-color: #f5f5f5;
}

.view-text kbd {
  background-color: #e1f0fa;
}

.view-text samp {
  background-color: #f7f8e2;
}

.view-text iframe {
  border-width: 0;
}

.view-text audio {
  width: 100%;
}

.view-text audio[controls] {
  display: block;
}

.view-text .media-wrapper__holder {
  background-color: #f5f5f5;
  position: relative;
}

.view-text .media-wrapper__holder iframe, .view-text .media-wrapper__holder video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.view-text table {
  width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 740px;
  border-top: 1px solid #bde0f1;
}

.view-text caption {
  margin-bottom: .5em;
  caption-side: top;
}

.view-text tbody, .view-text tfoot, .view-text thead, .view-text tr, .view-text th, .view-text td {
  border: inherit;
}

.view-text th, .view-text td {
  padding: 12px 0;
  text-align: left;
  vertical-align: top;
  border-bottom: 1px solid #bde0f1;
  font-size: 18px;
  color: #445568;
}

.view-text th {
  font-weight: bold;
  text-align: center;
  background-color: #f5f5f5;
}

.view-text .table-null, .view-text .table-null th, .view-text .table-null td {
  border-color: transparent;
}

.view-text .table-null th {
  background: transparent;
}

.view-text .table-zebra, .view-text .table-zebra th, .view-text .table-zebra td {
  border-color: transparent;
}

.view-text .table-zebra th {
  background: transparent;
}

.view-text .table-zebra tr:nth-child(odd) {
  background-color: #f5f5f5;
}

.view-text a {
  color: #43f;
}

.view-text a:visited {
  color: #0e00ad;
}

.view-text a:hover {
  color: #f34;
}

.view-text a:active {
  color: #000;
}

.view-text b, .view-text strong {
  font-weight: bold;
}

.view-text i, .view-text cite, .view-text em, .view-text var, .view-text address, .view-text dfn, .view-text caption {
  font-style: italic;
}

.view-text abbr[title], .view-text dfn[title] {
  text-decoration: none;
  border-bottom: 1px dotted;
  cursor: help;
}

@media only screen and (max-width: 1024px) {
  .view-text > blockquote,
  .view-text > pre,
  .view-text > .media-wrapper,
  .view-text > video,
  .view-text > iframe {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .view-text > blockquote,
  .view-text > pre {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.view-text--article > p,
.view-text--article > h1,
.view-text--article > h2,
.view-text--article > h3,
.view-text--article > h4,
.view-text--article > h5,
.view-text--article > h6,
.view-text--article > hr,
.view-text--article > ul,
.view-text--article > ol,
.view-text--article > dl,
.view-text--article > audio,
.view-text--article > address {
  max-width: 1025px;
  margin-left: auto;
  margin-right: auto;
}

.table-wrapper {
  clear: both;
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
}

.table-wrapper__holder {
  max-width: 100%;
  overflow-x: auto;
  padding: 0;
  margin: 0;
}

.table-wrapper__table {
  width: 100%;
  border-collapse: collapse;
}

.table-wrapper:before, .table-wrapper:after {
  content: '';
  width: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity .25s linear;
}

.table-wrapper:before {
  left: 0;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, transparent 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, transparent 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, transparent 100%);
}

.table-wrapper:after {
  right: 0;
  background: -moz-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
  background: -webkit-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
  background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
}

.table-wrapper--outside-left:before {
  opacity: .3;
}

.table-wrapper--outside-right:after {
  opacity: .3;
}

.content-image {
  position: relative;
}

.content-image--width-1200-and-more {
  display: block;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.view-text--article .content-image--width-600-and-more {
  display: block;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media only screen and (max-width: 1439px) {
  .content-image--width-1000-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1365px) {
  .content-image--width-900-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1279px) {
  .content-image--width-800-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 759px) {
  .content-image--width-500-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 639px) {
  .content-image--width-300-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 479px) {
  .content-image--width-200-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 374px) {
  .content-image--width-100-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.title {
  line-height: 1.2;
  font-weight: bold;
  color: #404040;
  font-size: 2rem;
  margin: 1.2em 0 1rem;
}

.title:first-child {
  margin-top: 0;
}

.title--sup {
  font-size: 2.5rem;
}

.title--sub {
  font-size: 1.5rem;
}

.breadcrumbs {
  width: 100%;
  display: block;
  line-height: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 100;
  padding-top: 48px;
  padding-bottom: 50px;
  position: relative;
}

.breadcrumbs__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.breadcrumbs:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  content: "";
  z-index: -1;
  background: linear-gradient(to bottom, #e7eef7 0%, rgba(255, 255, 255, 0) 100%);
}

.breadcrumbs:before {
  z-index: 1;
  width: 647px;
  height: 735px;
  position: absolute;
  right: 0;
  top: -290px;
  background-image: url(../images/Circle.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  content: "";
}

.breadcrumbs a {
  color: #4da2cb;
  text-decoration: none;
}

.breadcrumbs a:hover {
  color: #fe5400;
}

.breadcrumbs span {
  color: #445568;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 1.4;
  margin-right: .5em;
  position: relative;
  z-index: 3;
}

.breadcrumbs span:before {
  margin-right: .5em;
  content: '•';
  color: #4da2cb;
  display: inline-block;
  vertical-align: top;
}

.breadcrumbs span:first-child:before {
  display: none;
}

@media only screen and (max-width: 759px) {
  .breadcrumbs {
    display: block;
  }
  .breadcrumbs span, .breadcrumbs span:before {
    display: none;
  }
  .breadcrumbs span:nth-last-child(2) {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    background: none;
    margin: 0;
  }
  .breadcrumbs span:nth-last-child(2):before {
    content: '<';
    margin: 0 5px 0 3px;
    display: inline-block;
  }
}

.sitemap {
  padding: 1rem;
  position: relative;
  font-size: 14px;
  font-family: 'Museo Sans';
  font-weight: 600;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 100% !important;
  background-color: transparent;
}

@media only screen and (min-width: 760px) {
  .sitemap {
    font-size: 16px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.sitemap ul {
  list-style: none;
  padding: 0 0 0 2em;
  margin: 0;
}

.sitemap li {
  list-style: none;
  line-height: 1.4em;
}

.sitemap a {
  padding: .2em 1em .2em .5em;
  display: block;
  color: #445568;
}

.sitemap a:visited {
  color: #0e00ad;
}

.sitemap a:hover {
  color: #fe5400;
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap a:active {
  color: #000;
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap > ul {
  padding: 0;
  margin: 1em 0;
  position: relative;
  overflow: hidden;
}

.sitemap > ul > li > ul {
  padding-left: 0;
}

.sitemap > ul ul {
  list-style: none;
  padding: 0 0 0 2em;
  margin: 0;
}

.sitemap > ul ul li {
  position: relative;
  margin: .1em 0;
}

.sitemap > ul ul li:hover {
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap > ul ul li a {
  position: relative;
  padding-left: 2.5em;
}

.sitemap > ul ul li a:before, .sitemap > ul ul li a:after {
  content: '';
  position: absolute;
  top: .2em;
  width: 1600px;
  height: 1.4em;
  text-align: right;
  opacity: .3;
  background: transparent repeat-x center right;
}

.sitemap > ul ul li a:before {
  left: 0;
  width: 2em;
  background-image: url(pic/sitemap-start.svg);
}

.sitemap > ul ul li a:after {
  right: 100%;
  background-image: url(pic/sitemap-middle.svg);
}

.sitemap > ul ul li:last-child > a:only-child:before {
  background-image: url(pic/sitemap-end.svg);
}

.pagination {
  text-align: center;
}

.form {
  position: relative;
  font-size: 1rem;
}

.form__group {
  display: block;
  position: relative;
  margin: 0 0 15px;
}

.form__group .form__group {
  margin: 0 0 0;
}

.form__label {
  cursor: default;
  display: inline-block;
  vertical-align: top;
  user-select: none;
  margin-bottom: .4rem;
}

.form__caption {
  font-size: 16px;
  font-weight: 100;
  margin-bottom: 6px;
  color: #4da2cb;
}

.form__info {
  display: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  font-size: .7em;
  background-color: #000;
  color: #fff;
  padding: .1em .3em;
}

input:focus ~ .form__info,
textarea:focus ~ .form__info,
select:focus ~ .form__info {
  display: block;
}

label.form__label {
  cursor: pointer;
}

.control-holder {
  position: relative;
}

.control-holder label.has-error {
  display: inline-block;
  vertical-align: top;
  user-select: none;
  font-size: .75em;
  line-height: 1em;
  padding: .1em .5em;
  background-color: transparent;
  color: tomato;
  position: absolute;
  top: 100%;
  left: 9px;
}

.control-holder--text {
  margin-bottom: 10px;
}

.control-holder--text input, .control-holder--text textarea, .control-holder--text select {
  display: block;
  width: 100%;
  border-radius: 8px;
  border: 2px solid #bde0f1;
  padding: 0 14px;
  outline: none;
  margin-bottom: 0;
}

.control-holder--text input.has-error, .control-holder--text textarea.has-error, .control-holder--text select.has-error {
  border-color: tomato;
}

.control-holder--text input, .control-holder--text select {
  height: 48px;
}

.control-holder--text textarea {
  resize: none;
  padding: 14px;
  min-height: 7em;
  max-height: 18em;
}

.control-holder--flag label {
  user-select: none;
  margin-right: 1em;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.control-holder--flag label:only-child, .control-holder--flag label.has-error {
  cursor: default;
  margin-right: 0;
}

.control-holder--flag ins {
  position: relative;
  display: inline-block;
  vertical-align: text-bottom;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #bde0f1;
}

.control-holder--flag input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
}

.control-holder--flag input[type="radio"] ~ ins {
  border-radius: 50%;
}

.control-holder--flag input:checked ~ ins {
  background-color: #445568;
  box-shadow: inset 0 0 0 3px #fff;
}

.control-holder--flag input:disabled ~ ins,
.control-holder--flag input:disabled ~ span {
  opacity: .5;
  cursor: not-allowed;
}

.control-holder--file label {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.control-holder--file input {
  position: absolute;
  outline: none;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.control-holder--file ._ellipsis {
  max-width: 200px;
  width: auto;
  display: inline-block;
  vertical-align: top;
}

#fPopUp {
  max-width: 80% !important;
  min-width: 280px !important;
}

@media only screen and (min-width: 760px) {
  #fPopUp {
    max-width: 50% !important;
    min-width: 400px !important;
  }
}

.button {
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  outline: none;
  background: transparent;
  text-decoration: none;
}

.button--big-news {
  margin-top: 40px;
}

.button--main .button__content {
  color: #33363e;
  letter-spacing: 0.1em;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 100%;
  font-weight: 700;
  font-family: 'Museo Sans';
  border-radius: 8px;
  border: 2px solid #fe5400;
  background-color: transparent;
  transition: transform 0.3s, background-color 0.2s 0.2s ease;
  transform-style: preserve-3d;
  overflow: hidden;
  position: relative;
}

.button--main .button__content:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fe5400;
  transform: scale(0.2);
  opacity: 0;
  content: "";
  transition: opacity 0.3s 0.2s ease, transform 0.5s 0.2s ease;
  border-radius: 8px;
  z-index: 1;
}

.button--main .button__content:before {
  content: attr(data-hover-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #fff;
  z-index: 2;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.button--main .button__content span {
  position: relative;
  z-index: 3;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s 0.3s ease, transform 0.3s 0.3s ease;
}

.button--main:hover .button__content {
  background-color: #fe5400;
}

.button--main:hover .button__content span {
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.button--main:hover .button__content:after {
  transform: scale(1.3);
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.5s ease;
}

.button--main:hover .button__content:before {
  transform: scale(1);
  opacity: 1;
  transition: opacity 0.3s 0.2s ease, transform 0.3s 0.2s ease;
}

.button__icon {
  width: 12px;
  height: 12px;
  fill: blue;
  transition: fill 0.3s ease;
  margin-left: 12px;
}

.button--buy .button__content {
  background-color: transparent;
  color: #000;
  letter-spacing: 0.1em;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 100%;
  font-weight: 700;
  font-family: 'Museo Sans';
  border-radius: 8px;
  transition: transform 0.3s, background-color 0.2s 0.2s ease;
  transform-style: preserve-3d;
  overflow: hidden;
  position: relative;
  border: 2px solid #fe5400;
}

.button--buy .button__content:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fe5400;
  transform: scale(0.2);
  opacity: 0;
  content: "";
  transition: opacity 0.3s 0.2s ease, transform 0.5s 0.2s ease;
  border-radius: 8px;
  z-index: 1;
}

.button--buy .button__content:before {
  content: attr(data-hover-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #fff;
  z-index: 2;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.button--buy .button__content span {
  position: relative;
  z-index: 3;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s 0.3s ease, transform 0.3s 0.3s ease;
}

.button--buy:hover .button__content {
  background-color: #fe5400;
}

.button--buy:hover .button__content span {
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.button--buy:hover .button__content:after {
  transform: scale(1.3);
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.5s ease;
}

.button--buy:hover .button__content:before {
  transform: scale(1);
  opacity: 1;
  transition: opacity 0.3s 0.2s ease, transform 0.3s 0.2s ease;
}

.button__content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 10px;
}

.button--not-available .button__content {
  background-color: transparent;
  color: grey;
  border: 1px solid grey;
  letter-spacing: 0.05em;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 100%;
  font-weight: 700;
  font-family: 'Museo Sans';
  transition: background-color 0.5s ease;
  text-align: center;
}

.button--not-available:hover .button__content {
  background-color: grey;
}

.button--disabled {
  pointer-events: none;
}

.button--w100 {
  width: 100%;
}

.button:active .button__content {
  background-color: #ff6519;
  transition: background-color 0s ease;
}

.button--sub .button__content {
  background-color: grey;
  color: white;
  letter-spacing: 0.05em;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 100%;
  font-weight: 700;
  font-family: 'Museo Sans';
  transition: background-color 0.5s ease;
  border-radius: 8px;
}

.button--sub:hover .button__content {
  background-color: blue;
}

.button--sub2 .button__content {
  color: blue;
  border: 1px solid blue;
  transition: color 0.5s ease, border-color 0.5s ease, transform 0.5s ease;
}

.button--sub2:hover .button__content {
  color: blue;
  border-color: blue;
}

.button--sub2:active .button__content {
  transform: scale(0.95);
  color: #1a1aff;
  border-color: #1a1aff;
  background-color: transparent;
  transition: color 0.5s ease, border-color 0.5s ease, transform 0s ease;
}

.button--mgsm {
  min-width: 30px;
  height: 30px;
}

.button--sm {
  height: 30px;
  display: inline-block;
}

.button--sm .button__content {
  height: 30px;
  min-width: 120px;
}

.button--mgs {
  height: 30px;
}

.button--mgs .button__content {
  height: 30px;
  width: 40px;
}

.button--sm-f {
  height: 30px;
  display: inline-block;
}

.button--sm-f .button__content {
  height: 30px;
  width: 120px;
}

.button--md {
  height: 44px;
  display: inline-block;
}

.button--md .button__content {
  height: 48px;
  min-width: 230px;
}

.button--mg {
  height: 48px;
  display: inline-block;
}

.button--mg .button__content {
  height: 48px;
  min-width: 280px;
}

@media only screen and (min-width: 760px) {
  .button--mg .button__content {
    min-width: 340px;
  }
}

.button--omgmd {
  height: 40px;
  display: inline-block;
}

@media only screen and (min-width: 1024px) {
  .button--omgmd {
    height: 50px;
  }
}

.button--omgmd .button__content {
  height: 40px;
  min-width: 190px;
}

@media only screen and (min-width: 1024px) {
  .button--omgmd .button__content {
    height: 50px;
    min-width: 210px;
  }
}

.button--omgmd2 {
  height: 50px;
  display: inline-block;
}

.button--omgmd2 .button__content {
  height: 50px;
  min-width: 230px;
}

.button--mdl {
  height: 50px;
  display: inline-block;
  max-width: 240px;
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (min-width: 640px) {
  .button--mdl {
    max-width: 250px;
  }
}

.button--mdl .button__content {
  height: 46px;
  width: 100%;
}

.button--lgmd {
  height: 40px;
  display: inline-block;
}

.button--lgmd .button__content {
  height: 40px;
  min-width: 260px;
}

@media only screen and (min-width: 1280px) {
  .button--lgmd .button__content {
    min-width: 330px;
  }
}

.button--white .button__content {
  color: #fff;
}

.button--white .button__icon {
  fill: #bde0f1;
  height: 12px;
  width: 20px;
  position: relative;
  transition: transform 0.5s 0.3s ease;
  z-index: 15;
}

.button--white:hover .button__icon {
  transition: transform 0.5s ease;
  transform: translateX(50px) scale(0);
}

.button--lg {
  height: 50px;
  display: inline-block;
}

.button--lg .button__content {
  height: 50px;
  min-width: 210px;
}

@media only screen and (min-width: 640px) {
  .button--lg .button__content {
    min-width: 250px;
  }
}

.button--w100 {
  width: 100%;
}

.button--w100 .button__content {
  width: 100%;
}

.show-all-button {
  text-transform: uppercase;
  color: blue;
  font-weight: 500;
  transition: color 0.5s ease;
  font-size: 10px;
  font-family: 'Museo Sans';
  letter-spacing: 0.1em;
}

.show-all-button:hover {
  color: blue;
}

.show-more-button {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.5s ease;
  font-size: 10px;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-family: 'Museo Sans';
}

.show-more-button svg {
  width: 14px;
  height: 10px;
  fill: blue;
  margin-left: 10px;
  transition: fill 0.5s ease;
}

.show-more-button:hover {
  color: blue;
}

.show-more-button:hover svg {
  fill: blue;
}

@font-face {
  font-family: 'Museo Sans';
  src: url("Media/css/fonts/subset-MuseoSansCyrl-100.woff2") format("woff2"), url("Media/css/fonts/subset-MuseoSansCyrl-100.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url("Media/css/fonts/subset-MuseoSansCyrl-900.woff2") format("woff2"), url("Media/css/fonts/subset-MuseoSansCyrl-900.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url("Media/css/fonts/subset-MuseoSansCyrl-300.woff2") format("woff2"), url("Media/css/fonts/subset-MuseoSansCyrl-300.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url("Media/css/fonts/subset-MuseoSansCyrl-700.woff2") format("woff2"), url("Media/css/fonts/subset-MuseoSansCyrl-700.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url("Media/css/fonts/subset-MuseoSansCyrl-500.woff2") format("woff2"), url("Media/css/fonts/subset-MuseoSansCyrl-500.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url("Media/css/fonts/subset-PlayfairDisplay-Regular.woff2") format("woff2"), url("Media/css/fonts/subset-PlayfairDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url("Media/css/fonts/subset-PlayfairDisplay-Italic.woff2") format("woff2"), url("Media/css/fonts/subset-PlayfairDisplay-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

.catalog-nav {
  width: 220px;
  display: none;
  flex-shrink: 0;
}

@media only screen and (min-width: 1025px) {
  .catalog-nav {
    display: flex;
  }
}

.catalog-nav__button {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 24px;
  color: #fe5400;
  font-weight: 500;
  font-family: 'Museo Sans';
  height: 70px;
  width: 100%;
  text-transform: uppercase;
  align-items: center;
  cursor: pointer;
  z-index: 15;
  user-select: none;
  position: relative;
  transition: background-color 0.5s ease;
}

.catalog-nav__button.is-disabled {
  pointer-events: none;
  cursor: default;
}

.catalog-nav__button svg {
  width: 40px;
  height: 22px;
  fill: #fe5400;
}

.catalog-nav__button .anim-burger {
  display: inline-block;
  width: 40px;
  height: 22px;
  position: relative;
}

.catalog-nav__button .anim-burger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #ff7e2a;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.catalog-nav__button .anim-burger span:nth-child(1) {
  top: 0px;
  left: 5px;
  width: 25px;
}

.catalog-nav__button .anim-burger span:nth-child(2) {
  top: 10px;
  width: 100%;
  background: #fe5400;
}

.catalog-nav__button .anim-burger span:nth-child(3) {
  top: 20px;
  left: auto;
  right: 5px;
  width: 25px;
}

.catalog-nav__button.open .anim-burger span:nth-child(1) {
  top: 10px;
  transform: rotate(135deg);
  width: 100%;
  left: 0;
  right: 0;
}

.catalog-nav__button.open .anim-burger span:nth-child(2) {
  opacity: 0;
  left: -20px;
  width: 100%;
}

.catalog-nav__button.open .anim-burger span:nth-child(3) {
  top: 10px;
  transform: rotate(-135deg);
  width: 100%;
  left: 0;
  right: 0;
}

.catalog-nav__content {
  display: none;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 2px);
  background-color: #fff;
  left: 0;
  width: 100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s 0.5s ease, opacity 0.5s 0s ease, transform 0.5s 0s ease;
  transform: translateY(-400px);
}

@media only screen and (min-width: 1025px) {
  .catalog-nav__content {
    display: flex;
  }
}

.catalog-nav__content.is-active {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s 0s ease, opacity 0.5s 0.1s ease, transform 0.5s 0.1s ease;
  transform: translateY(0);
}

.catalog-nav__list {
  background-color: white;
  list-style-type: none;
  padding: 0;
  margin: 0;
  list-style-image: url(data:0);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.catalog-nav__list-item {
  width: 20%;
  padding: 0 20px;
  min-width: 250px;
}

.catalog-nav__sub-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px 0;
  list-style-image: url(data:0);
}

.catalog-nav__unlisted-items {
  width: 20%;
  padding-right: 20px;
  min-width: 280px;
}

.catalog-nav__title {
  text-transform: uppercase;
  padding-bottom: 18px;
  padding-top: 6px;
  margin-bottom: 14px;
  border-bottom: 1px solid #bde0f1;
  display: block;
  color: #445568;
  font-size: 14px;
  text-decoration: none;
  font-weight: 700;
  position: relative;
}

.catalog-nav__title:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #4da2cb;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: left;
}

.catalog-nav__title:hover {
  color: #33363e;
}

.catalog-nav__title:hover:after {
  transform: scaleX(1);
}

.catalog-nav__link {
  display: inline-block;
  margin-bottom: 10px;
  padding: 1px 0 3px 0;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Museo Sans';
  color: #445568;
  text-decoration: none;
  position: relative;
  transition: border-color 0.5s ease, color 0.5s ease;
}

.catalog-nav__link:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #445568;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: left;
}

.catalog-nav__link:hover {
  color: #33363e;
}

.catalog-nav__link:hover:after {
  transform: scaleX(1);
}

.catalog-nav-content-wrapper {
  display: flex;
  background-color: #fff;
  padding: 32px 20px 70px 20px;
  border-radius: 0 0 4px 4px;
  position: relative;
}

.catalog-nav-overlay {
  position: fixed;
  background-color: #33363e;
  opacity: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 49;
  visibility: hidden;
  display: none;
  transition: opacity 0.3s 0s ease, visibility 0s 0.3s ease;
}

@media only screen and (min-width: 1025px) {
  .catalog-nav-overlay {
    display: block;
  }
}

.catalog-nav-overlay.is-active {
  opacity: 0.89;
  visibility: visible;
  transition: opacity 0.3s 0.1s ease, visibility 0s 0s ease;
}

.hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  cursor: pointer;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  transition-property: opacity, -webkit-filter;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  padding: 10px 10px 10px 0 !important;
}

.hamburger:hover {
  opacity: .7;
}

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  position: absolute;
  width: 40px;
  height: 4px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  transition-property: -webkit-transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  border-radius: 4px;
}

.hamburger-inner:after, .hamburger-inner:before {
  display: block;
  content: '';
}

.hamburger-inner:before {
  top: -7px;
}

@media only screen and (min-width: 760px) {
  .hamburger-inner:before {
    top: -10px;
  }
}

.hamburger-inner:after {
  bottom: -7px;
}

@media only screen and (min-width: 760px) {
  .hamburger-inner:after {
    bottom: -10px;
  }
}

.hamburger--3dx .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dx .hamburger-inner {
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner:after, .hamburger--3dx .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.hamburger--3dx.is-active .hamburger-inner {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background-color: transparent;
}

.hamburger--3dx.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dx-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner:after, .hamburger--3dx-r .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.hamburger--3dx-r.is-active .hamburger-inner {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  background-color: transparent;
}

.hamburger--3dx-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dy .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dy .hamburger-inner {
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner:after, .hamburger--3dy .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.hamburger--3dy.is-active .hamburger-inner {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
  background-color: transparent;
}

.hamburger--3dy.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dy-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner:after, .hamburger--3dy-r .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.hamburger--3dy-r.is-active .hamburger-inner {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  background-color: transparent;
}

.hamburger--3dy-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--arrow.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrow.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrow-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrow-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrowalt .hamburger-inner:before {
  transition: top 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: top 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner:after {
  transition: bottom 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: bottom 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transition: top 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: top 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: top 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transform: translate3d(-8px, -10px, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrowalt.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: bottom 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: bottom 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transform: translate3d(-8px, 10px, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrowalt-r .hamburger-inner:before {
  transition: top 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: top 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner:after {
  transition: bottom 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: bottom 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transition: top 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: top 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: top 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transform: translate3d(8px, -10px, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(8px, -10px, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: bottom 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: bottom 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transform: translate3d(8px, 10px, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner:after, .hamburger--boring .hamburger-inner:before {
  -webkit-transition-property: none;
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: .15s;
  transition-delay: .15s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
}

.hamburger--collapse .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0.1s linear;
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner:before {
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--collapse.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.27s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.27s;
  opacity: 0;
}

.hamburger--collapse.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: .15s;
  transition-delay: .15s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
}

.hamburger--collapse-r .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0.1s linear;
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0.1s linear;
}

.hamburger--collapse-r .hamburger-inner:before {
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(45deg);
  transform: translate3d(0, -10px, 0) rotate(45deg);
}

.hamburger--collapse-r.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.27s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.27s;
  opacity: 0;
}

.hamburger--collapse-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburger--elastic .hamburger-inner {
  top: 2px;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
}

.hamburger--elastic .hamburger-inner:before {
  top: 10px;
  -webkit-transition: opacity .15s ease .4s;
  transition: opacity .15s ease .4s;
}

.hamburger--elastic .hamburger-inner:after {
  top: 20px;
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
  transform: translate3d(0, 10px, 0) rotate(135deg);
}

.hamburger--elastic.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner:after {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
  transform: translate3d(0, -20px, 0) rotate(-270deg);
}

.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
}

.hamburger--elastic-r .hamburger-inner:before {
  top: 10px;
  -webkit-transition: opacity .15s ease .4s;
  transition: opacity .15s ease .4s;
}

.hamburger--elastic-r .hamburger-inner:after {
  top: 20px;
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
  transform: translate3d(0, 10px, 0) rotate(-135deg);
}

.hamburger--elastic-r.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner:after {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
  transform: translate3d(0, -20px, 0) rotate(270deg);
}

.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  -webkit-transition: background-color .2s ease-in .25s;
  transition: background-color .2s ease-in .25s;
}

.hamburger--emphatic .hamburger-inner:before {
  left: 0;
  transition: top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, left 0.2s ease-in 0.25s;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic .hamburger-inner:after {
  top: 10px;
  right: 0;
  transition: top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, right 0.2s ease-in 0.25s;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic.is-active .hamburger-inner {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger--emphatic.is-active .hamburger-inner:before {
  top: -80px;
  left: -80px;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transition: left 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transform: translate3d(80px, 80px, 0) rotate(45deg);
  transform: translate3d(80px, 80px, 0) rotate(45deg);
}

.hamburger--emphatic.is-active .hamburger-inner:after {
  top: -80px;
  right: -80px;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transition: right 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
}

.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  -webkit-transition: background-color .2s ease-in .25s;
  transition: background-color .2s ease-in .25s;
}

.hamburger--emphatic-r .hamburger-inner:before {
  left: 0;
  transition: top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, left 0.2s ease-in 0.25s;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic-r .hamburger-inner:after {
  top: 10px;
  right: 0;
  transition: top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, right 0.2s ease-in 0.25s;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger--emphatic-r.is-active .hamburger-inner:before {
  top: 80px;
  left: -80px;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transition: left 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
}

.hamburger--emphatic-r.is-active .hamburger-inner:after {
  top: 80px;
  right: -80px;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transition: right 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
}

.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner:before {
  top: 10px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  transition-property: opacity, -webkit-transform;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}

.hamburger--slider .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner:before {
  -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

.hamburger--slider-r .hamburger-inner {
  top: 2px;
}

.hamburger--slider-r .hamburger-inner:before {
  top: 10px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  transition-property: opacity, -webkit-transform;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}

.hamburger--slider-r .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner:before {
  -webkit-transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(90deg);
  transform: translate3d(0, -20px, 0) rotate(90deg);
}

.hamburger--spring .hamburger-inner {
  top: 2px;
  -webkit-transition: background-color 0s linear .15s;
  transition: background-color 0s linear .15s;
}

.hamburger--spring .hamburger-inner:before {
  top: 10px;
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner:after {
  top: 20px;
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  background-color: transparent;
}

.hamburger--spring.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner:after {
  top: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
}

.hamburger--spring-r .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0s linear;
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner:before {
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--spring-r.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s linear 0.32s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s linear 0.32s;
  opacity: 0;
}

.hamburger--spring-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburger--stand .hamburger-inner {
  transition: background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
  -webkit-transition: background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
  transition: transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s, background-color 0s linear 0.1s;
  transition: transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s, background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
}

.hamburger--stand .hamburger-inner:before {
  transition: top 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -webkit-transition: top 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -webkit-transition: bottom 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand.is-active .hamburger-inner {
  transition: background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transition: background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.22s;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: transparent;
}

.hamburger--stand.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transition: top 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger--stand.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transition: bottom 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: bottom 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: bottom 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--stand-r .hamburger-inner {
  transition: background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
  -webkit-transition: background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
  transition: transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s, background-color 0s linear 0.1s;
  transition: transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s, background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
}

.hamburger--stand-r .hamburger-inner:before {
  transition: top 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -webkit-transition: top 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand-r .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -webkit-transition: bottom 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand-r.is-active .hamburger-inner {
  transition: background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transition: background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.22s;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  background-color: transparent;
}

.hamburger--stand-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transition: top 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger--stand-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transition: bottom 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: bottom 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: bottom 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--spin .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger--spin .hamburger-inner:before {
  -webkit-transition: top .1s ease-in .34s, opacity .1s ease-in;
  transition: top .1s ease-in .34s, opacity .1s ease-in;
}

.hamburger--spin .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.34s, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: bottom 0.1s ease-in 0.34s, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.34s, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.34s, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transition-delay: .14s;
  transition-delay: .14s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.hamburger--spin.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease-out, opacity .1s ease-out .14s;
  transition: top .1s ease-out, opacity .1s ease-out .14s;
  opacity: 0;
}

.hamburger--spin.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease-out, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease-out, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--spin-r .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger--spin-r .hamburger-inner:before {
  -webkit-transition: top .1s ease-in .34s, opacity .1s ease-in;
  transition: top .1s ease-in .34s, opacity .1s ease-in;
}

.hamburger--spin-r .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.34s, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: bottom 0.1s ease-in 0.34s, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.34s, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.34s, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  -webkit-transition-delay: .14s;
  transition-delay: .14s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg);
}

.hamburger--spin-r.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease-out, opacity .1s ease-out .14s;
  transition: top .1s ease-out, opacity .1s ease-out .14s;
  opacity: 0;
}

.hamburger--spin-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease-out, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease-out, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--squeeze .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .1s;
  transition-duration: .1s;
}

.hamburger--squeeze .hamburger-inner:before {
  -webkit-transition: top .1s ease .14s, opacity .1s ease;
  transition: top .1s ease .14s, opacity .1s ease;
}

.hamburger--squeeze .hamburger-inner:after {
  transition: bottom 0.1s ease 0.14s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: bottom 0.1s ease 0.14s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease 0.14s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease 0.14s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transition-delay: .14s;
  transition-delay: .14s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--squeeze.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease, opacity .1s ease .14s;
  transition: top .1s ease, opacity .1s ease .14s;
  opacity: 0;
}

.hamburger--squeeze.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--vortex .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger--vortex .hamburger-inner:after, .hamburger--vortex .hamburger-inner:before {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.hamburger--vortex .hamburger-inner:before {
  -webkit-transition-property: top, opacity;
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner:after {
  transition-property: bottom, -webkit-transform;
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
  transition-property: bottom, transform, -webkit-transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: rotate(765deg);
  transform: rotate(765deg);
}

.hamburger--vortex.is-active .hamburger-inner:after, .hamburger--vortex.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburger--vortex-r .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger--vortex-r .hamburger-inner:after, .hamburger--vortex-r .hamburger-inner:before {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.hamburger--vortex-r .hamburger-inner:before {
  -webkit-transition-property: top, opacity;
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner:after {
  transition-property: bottom, -webkit-transform;
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
  transition-property: bottom, transform, -webkit-transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: rotate(-765deg);
  transform: rotate(-765deg);
}

.hamburger--vortex-r.is-active .hamburger-inner:after, .hamburger--vortex-r.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  background-color: #445568;
}

.hamburger {
  padding: 10px;
  align-items: center;
  display: flex;
  z-index: 25;
}

@media only screen and (min-width: 1025px) {
  .hamburger {
    display: none;
  }
}

@media (max-width: 375px) {
  .hamburger {
    padding: 4px;
  }
  .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before, .hamburger-box {
    width: 24px;
  }
}

@media only screen and (max-width: 375px) and (min-width: 760px) {
  .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before, .hamburger-box {
    width: 30px;
  }
}

.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.ui-helper-clearfix:before, .ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}

.ui-helper-clearfix:after {
  clear: both;
}

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
}

.ui-front {
  z-index: 100;
}

.ui-state-disabled {
  cursor: default !important;
  pointer-events: none;
}

.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block;
}

.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: 0;
}

.ui-menu .ui-menu {
  position: absolute;
}

.ui-menu .ui-menu-item {
  margin: 0;
  cursor: pointer;
  list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
}

.ui-menu .ui-menu-item-wrapper {
  position: relative;
}

.ui-menu .ui-menu-divider {
  margin: 5px 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0;
}

.ui-menu .ui-state-focus, .ui-menu .ui-state-active {
  margin: -1px;
}

.ui-menu-icons {
  position: relative;
}

.ui-menu-icons .ui-menu-item-wrapper {
  padding-left: 2em;
}

.ui-menu .ui-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: .2em;
  margin: auto 0;
}

.ui-menu .ui-menu-icon {
  left: auto;
  right: 0;
}

.ui-selectmenu-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.ui-selectmenu-menu .ui-menu {
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 1px;
}

.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
  font-size: 1em;
  font-weight: bold;
  line-height: 1.5;
  padding: 2px 0.4em;
  margin: 0.5em 0 0 0;
  height: auto;
  border: 0;
}

.ui-selectmenu-open {
  display: block;
}

.ui-selectmenu-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui-selectmenu-button.ui-button {
  text-align: left;
  white-space: nowrap;
}

.ui-selectmenu-icon.ui-icon {
  float: right;
  margin-top: 0;
}

.ui-button {
  padding: .4em 1em;
  display: inline-block;
  position: relative;
  line-height: normal;
  margin-right: .1em;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: visible;
}

.ui-button, .ui-button:link, .ui-button:visited, .ui-button:hover, .ui-button:active {
  text-decoration: none;
}

.ui-button-icon-only {
  width: 2em;
  box-sizing: border-box;
  text-indent: -9999px;
  white-space: nowrap;
}

input.ui-button.ui-button-icon-only {
  text-indent: 0;
}

.ui-button-icon-only .ui-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
}

.ui-button.ui-icon-notext .ui-icon {
  padding: 0;
  width: 2.1em;
  height: 2.1em;
  text-indent: -9999px;
  white-space: nowrap;
}

input.ui-button.ui-icon-notext .ui-icon {
  width: auto;
  height: auto;
  text-indent: 0;
  white-space: normal;
  padding: .4em 1em;
}

input.ui-button::-moz-focus-inner, button.ui-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ui-slider {
  position: relative;
  text-align: left;
}

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  -ms-touch-action: none;
  touch-action: none;
}

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0;
}

.ui-slider.ui-state-disabled .ui-slider-handle, .ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit;
}

.ui-slider-horizontal {
  height: .8em;
}

.ui-slider-horizontal.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: .8em;
  height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-left: 0;
  margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}

.ui-selectmenu-button {
  outline: none;
  background-color: #fff;
  border: 2px solid #bde0f1;
  border-radius: 8px;
  height: 44px;
  align-items: center;
  display: flex;
  padding-right: 30px;
  padding-left: 14px;
  max-width: auto;
}

.ui-selectmenu-button:hover .ui-selectmenu-text {
  color: #000;
}

.ui-menu-item {
  min-height: 44px;
  display: flex;
  align-items: center;
  padding-right: 30px;
  padding-left: 14px;
  background-color: #fff;
  border-bottom: 1px solid #bde0f1;
  height: 44px;
}

.ui-menu-item:hover .ui-selectmenu-text,
.ui-menu-item:hover .ui-menu-item-wrapper {
  color: #445568;
}

.ui-menu-item-wrapper {
  padding: 0;
}

.ui-selectmenu-text {
  margin-right: 10px;
}

.ui-selectmenu-text,
.ui-menu-item-wrapper {
  font-weight: 100;
  font-family: 'Museo Sans';
  font-size: 14px;
  color: #445568;
  transition: color 0.5s ease;
}

.ui-menu {
  border: 1px solid #bde0f1;
  border-radius: 3px;
}

.ui-selectmenu-button.ui-button:after {
  background: url(../images/arrow_bottom.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 14px;
  height: 6px;
  position: absolute;
  right: 14px;
  top: 2px;
  bottom: 0;
  margin: auto 0;
  content: "";
  transition: transform .3s ease;
}

.ui-selectmenu-button-open.ui-selectmenu-button.ui-button:after {
  transform: scale(-1);
}

.filter {
  width: 280px;
  position: fixed;
  flex-shrink: 0;
  background-color: #fff;
  z-index: 25;
  transform: translateX(-400%) scale(0.9);
  opacity: 0.5;
  top: 60px;
  left: 0;
  padding: 10px;
  transition: transform 0.3s ease, z-index 0s 0.3s, opacity 0.3s;
  height: calc(100% - 70px);
  overflow: overlay;
}

.filter__list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@media only screen and (min-width: 1025px) {
  .filter {
    top: 0;
    position: relative;
    transform: translate(0);
    height: auto;
    opacity: 1;
    padding: 0;
  }
}

.filter__items-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.filter.is-active {
  transform: translateY(0);
  z-index: 25;
  opacity: 1;
  transition: transform 0.3s ease, z-index 0s 0s, opacity 0.3s;
}

.filter__item-label.disabled {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.filter__show-more {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #000;
  font-family: 'Museo Sans';
  font-weight: 100;
  transition: color 0.3s ease;
}

.filter__show-more:hover {
  color: #fe5400;
}

.filter__show-more-icon {
  width: 20px;
  height: 20px;
  margin-right: 18px;
  font-size: 30px;
  font-weight: 100;
  line-height: 58%;
  padding-left: 2px;
  color: #bde0f1;
}

.filter__item-link {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.filter__item-link:hover ins {
  border-color: #445568;
}

.filter__item-link:hover .filter__item-text {
  color: #445568;
}

.filter__item-input {
  display: none;
}

.filter__item-text {
  color: #000;
  transition: color 0.3s ease;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Museo Sans';
  font-weight: 100;
  line-height: 110%;
}

.filter__item-ins {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 4px;
  border: 1px solid #bde0f1;
  margin-right: 18px;
  display: block;
  position: relative;
  transition: border-color 0.3s ease;
}

.filter__group {
  margin-bottom: 32px;
  padding: 0px 10px;
}

@media only screen and (min-width: 1025px) {
  .filter__group {
    padding: 0;
  }
}

.filter__group.is-active .filter__title svg {
  transform: scale(1);
}

.filter__title {
  padding: 18px 0;
  color: #000;
  margin-bottom: 12px;
  border-bottom: 2px solid #bde0f1;
  border-top: 2px solid #bde0f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: color 0.3s ease;
  font-weight: 700;
  font-family: 'Museo Sans';
  text-transform: uppercase;
  font-size: 14px;
  line-height: 110%;
}

.filter__title svg {
  width: 16px;
  height: 12px;
  fill: #bde0f1;
  margin-right: 2px;
  transform: scale(-1);
  transition: transform 0.3s ease;
}

.filter__title:hover {
  color: #fe5400;
}

.filter__reset-button {
  text-decoration: none;
  position: relative;
}

.filter__reset-button svg {
  width: 28px;
  height: 28px;
  margin-right: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 2px;
}

.filter__reset-button span {
  text-decoration: none;
}

.filter__reset-button:hover svg {
  fill: #bde0f1;
}

.filter__reset-button.js-loading svg {
  animation: spin 5s infinite;
}

.filter__item {
  margin-bottom: 10px;
}

.filter__item.is-active .filter__item-text {
  text-decoration: underline;
}

.filter__item.is-active .filter__item-ins {
  border: 2px solid #bde0f1;
}

.filter__item.is-active .filter__item-ins:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 12px;
  height: 12px;
  background-color: #445568;
  border-radius: 3px;
  content: "";
}

.filter__item:hover .filter__item-text {
  color: #fe5400;
}

.filter__item:hover .filter__item-ins {
  border-color: #bde0f1;
}

.filter__item:hover .filter__item-label.disabled {
  opacity: 0.6;
}

.filter__item .filter__item-label.disabled {
  opacity: 0.6;
}

.filter__item .filter__item-label.disabled .filter__item-text {
  color: #000;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(720deg);
  }
}

.range-slider {
  width: 100%;
  padding-top: 30px;
}

.range-slider__slider-wrapper {
  margin-bottom: 28px;
  width: calc(100% - 30px);
  margin-left: 15px;
}

.range-slider__slider {
  width: 100%;
}

.range-slider-sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.range-slider-sort__type {
  outline: none;
  width: 82px;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  font-weight: 500;
  font-family: 'Museo Sans';
  color: #000;
  border: 1px solid #bde0f1;
  border-radius: 10px;
  height: 50px;
  transition: color 0.3s ease;
}

@media only screen and (min-width: 1025px) {
  .range-slider-sort__type {
    width: 96px;
  }
}

.range-slider-sort__type:focus {
  color: #fe5400;
}

.range-slider-sort__range-button .button__content {
  width: 62px;
  height: 50px;
}

.range-slider-sort__text, .range-slider-sort__del {
  color: grey;
  font-weight: 500;
  font-family: 'Museo Sans';
  font-size: 16px;
}

.range-slider-sort__del {
  margin: 0 6px;
}

.noUi-handle {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #a9acbd;
  top: -15px;
  left: -15px;
  border-radius: 100%;
  outline: none;
  transition: background-color 0.5s ease;
}

.noUi-handle:hover {
  background-color: #fe5400;
}

.noUi-connect {
  background-color: #fe5400;
  height: 1px;
}

.noUi-horizontal,
.noUi-vertical {
  height: 1px;
}

.noUi-target {
  background: #445568;
  border: 1px solid #445568;
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  /* Fix 401 */
}

.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-origin {
  position: absolute;
  height: 0;
  width: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Styling;
 */
.noUi-target {
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.noUi-connect {
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate3d(-50%, 50%, 0);
  transform: translate3d(-50%, 50%, 0);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  padding-left: 25px;
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.item-slider {
  display: flex;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (min-width: 480px) {
  .item-slider {
    max-width: 486px;
  }
}

@media only screen and (min-width: 760px) {
  .item-slider {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .item-slider {
    max-width: 800px;
  }
}

@media only screen and (min-width: 1025px) {
  .item-slider {
    width: 50%;
    margin: 0;
  }
}

.item-slider__main-image {
  width: 100%;
  height: 234px;
  max-width: 600px;
  position: relative;
  margin-left: 0;
  order: 1;
  cursor: pointer;
}

@media only screen and (min-width: 480px) {
  .item-slider__main-image {
    height: 380px;
  }
}

@media only screen and (min-width: 1280px) {
  .item-slider__main-image {
    height: 600px;
  }
}

@media only screen and (min-width: 1025px) {
  .item-slider__main-image {
    order: 2;
  }
}

.item-slider__main-image img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  max-height: 100%;
  box-shadow: 0px 9px 33px 0px rgba(0, 0, 0, 0.4);
}

.item-slider__thumbs-wrapper {
  position: relative;
  width: 66px;
  height: 234px;
  order: 1;
  flex-shrink: 0;
  margin: 0;
  padding: 0 8px;
}

@media only screen and (min-width: 480px) {
  .item-slider__thumbs-wrapper {
    width: 104px;
    height: 386px;
  }
}

@media only screen and (min-width: 1024px) {
  .item-slider__thumbs-wrapper {
    margin: auto 0;
  }
}

@media only screen and (min-width: 1280px) {
  .item-slider__thumbs-wrapper {
    height: 510px;
    width: 136px;
  }
}

@media only screen and (min-width: 1440px) {
  .item-slider__thumbs-wrapper {
    padding: 0 38px;
    width: 196px;
  }
}

.item-slider__thumb {
  transform: rotate(0);
  position: relative;
  width: 50px !important;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}

@media only screen and (min-width: 480px) {
  .item-slider__thumb {
    width: 88px !important;
    height: 88px;
  }
}

@media only screen and (min-width: 1280px) {
  .item-slider__thumb {
    width: 120px !important;
    height: 120px;
  }
}

.item-slider__thumb:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 34px -2px rgba(0, 0, 0, 0.4);
  content: "";
  border-radius: 22px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.item-slider__thumb:hover:after {
  opacity: 1;
}

.item-slider__thumb-decor {
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 20;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.item-slider__thumbs {
  height: 234px;
  width: 50px;
  position: relative;
  left: 0;
  top: 0;
}

@media only screen and (min-width: 480px) {
  .item-slider__thumbs {
    width: 88px;
    height: 386px;
  }
}

@media only screen and (min-width: 1280px) {
  .item-slider__thumbs {
    width: 120px;
    height: 510px;
  }
}

.item-slider .slider-custom-arrow {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 25;
  display: none;
}

@media only screen and (min-width: 1025px) {
  .item-slider .slider-custom-arrow {
    display: flex;
  }
}

.item-slider .slider-custom-arrow--left {
  bottom: 0;
}

.item-slider .slider-custom-arrow--right {
  top: 0;
}

.swiper-slider-wrapper-custom {
  display: flex;
  position: relative;
}

.item-tabs {
  width: 100%;
  padding-right: 0;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1024px) {
  .item-tabs {
    width: calc(100% - 344px);
    margin-bottom: 0;
    padding-right: 3%;
  }
}

@media only screen and (min-width: 1280px) {
  .item-tabs {
    padding-right: 14.3%;
  }
}

.item-tabs__buttons {
  display: none;
  flex-wrap: wrap;
  border-bottom: 1px solid #4da2cb;
  margin-bottom: 38px;
}

@media only screen and (min-width: 760px) {
  .item-tabs__buttons {
    display: flex;
    margin-bottom: 60px;
  }
}

.item-tabs__title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 100%;
  font-weight: 500;
  font-family: 'Museo Sans';
  color: #445568;
}

@media only screen and (min-width: 1025px) {
  .item-tabs__title {
    margin-bottom: 32px;
    font-size: 24px;
  }
}

.item-tabs__title span {
  color: #000;
}

.item-tabs__button {
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 100%;
  font-weight: 700;
  font-family: 'Museo Sans';
  color: #445568;
  position: relative;
  transition: color 0.5s ease;
  margin-right: 0;
  border-bottom: 1px solid #4da2cb;
}

@media only screen and (min-width: 760px) {
  .item-tabs__button {
    margin-right: 20px;
    border-bottom: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .item-tabs__button {
    margin-right: 46px;
    font-size: 30px;
  }
}

.item-tabs__button:after {
  position: absolute;
  content: "";
  top: 100%;
  height: 2px;
  width: 100%;
  background-color: #fe5400;
  left: 0;
  opacity: 0;
}

.item-tabs__button.is-active {
  color: #000;
}

.item-tabs__button.is-active:after {
  opacity: 1;
}

.reviews-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.review-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.review-item:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  background-color: #bde0f1;
  height: 1px;
  content: "";
}

.review-item__top-row {
  margin-bottom: 10px;
  min-height: 52px;
  padding: 10px 0;
  border-top: 3px solid #bde0f1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #bde0f1;
}

@media only screen and (min-width: 760px) {
  .review-item__top-row {
    flex-direction: row;
    align-items: center;
  }
}

.review-item__title {
  font-size: 18px;
  line-height: 110%;
  color: #445568;
  font-weight: 500;
  font-family: 'Museo Sans';
  margin-right: 34px;
}

.review-item--admin {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.review-item--admin:after {
  display: none;
}

.review-item__text {
  font-size: 16px;
  line-height: 110%;
  font-weight: 300;
  font-family: 'Museo Sans';
}

.review-item__date {
  color: #fe5400;
  font-weight: 100;
  letter-spacing: 0.2em;
  font-family: 'Museo Sans';
  font-size: 14px;
}

.video-review__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.video-review__item {
  width: 100%;
  height: 238px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  margin-bottom: 10px;
  position: relative;
}

@media only screen and (min-width: 760px) {
  .video-review__item {
    width: calc(50% - 5px);
  }
}

.video-review__item-decor {
  width: 70px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.video-review__item-decor svg {
  width: 70px;
  height: 50px;
  fill: red;
  position: relative;
  z-index: 1;
}

.video-review__item-decor:after {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  content: "";
  width: 30px;
  height: 30px;
  background-color: #fff;
}

.order-cart {
  width: 50%;
  padding-left: 40px;
  display: none;
  z-index: 15;
}

@media only screen and (min-width: 1024px) {
  .order-cart {
    display: block;
  }
}

@media only screen and (min-width: 1280px) {
  .order-cart {
    padding-left: 90px;
  }
}

.order-cart__end-block {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  padding: 40px 0 30px 0;
}

.order-cart__end-sum {
  margin-bottom: 28px;
  font-size: 24px;
  font-weight: 500;
  color: #000;
  line-height: 110%;
}

.order-cart__item {
  display: flex;
  width: 100%;
  margin-bottom: 36px;
}

.order-cart__item-name {
  font-size: 14px;
  color: #4da2cb;
  line-height: 18px;
  text-decoration: none;
  font-weight: 300;
}

.order-cart__item-name:hover {
  color: #bde0f1;
}

.order-cart__item-image {
  flex-shrink: 0;
  border: 1px solid #f5f5f8;
  width: 75px;
  height: 75px;
}

.order-cart__item-descr {
  margin-left: 20px;
}

.order-cart__item-price {
  font-weight: 500;
  color: #000;
}

.order-cart__edit-cart {
  color: #4da2cb;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  font-size: 10px;
}

.order-cart__edit-cart:hover {
  color: #bde0f1;
}

.order-cart__sum {
  font-size: 14px;
  font-weight: 300;
  color: #4da2cb;
  margin-bottom: 28px;
}

.order-cart__title {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  padding-bottom: 14px;
  margin-bottom: 24px;
  border-bottom: 1px solid #bde0f1;
}

.cart-widget__group {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cart-widget__end {
  align-items: flex-start !important;
}

.continue-buys {
  outline: none;
}

.cart {
  position: relative;
  font-size: 1.1rem;
  padding: 1rem 1.5rem;
}

.cart:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #ecf6fb;
  content: "";
  height: 82px;
  z-index: -1;
}

@media only screen and (min-width: 760px) {
  .cart {
    padding: 1.5rem 2.5rem;
  }
}

@media only screen and (max-width: 479px) {
  .view-size .cart {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}

.cart-div {
  position: relative;
}

@media only screen and (min-width: 760px) {
  .cart-footer .cart-div--emphasis {
    padding: 1.5rem 2.5rem;
  }
}

.cart-item .cart-div--emphasis {
  display: inline-block;
}

@media only screen and (max-width: 479px) {
  .cart-item .cart-div--emphasis {
    padding: .5em;
  }
}

.cart-header {
  position: relative;
  margin-bottom: 10px;
}

.hidden-wrapper .cart-header {
  padding-right: 2rem;
}

.cart-header__title {
  text-align: center;
  display: block;
  border-bottom: 1px solid #4da2cb;
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  color: #445568;
  margin-bottom: 16px;
}

.cart-section {
  position: relative;
}

.cart-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.cart-item {
  position: relative;
  list-style-type: none;
  padding: 20px 40px 20px 14px;
  margin: 0;
  width: 100%;
}

@media only screen and (min-width: 760px) {
  .cart-item {
    padding: 20px 70px 20px 14px;
  }
}

@media only screen and (min-width: 1280px) {
  .cart-item {
    width: auto;
  }
}

.cart-item:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: calc(100% - 120px);
  content: "";
  height: 1px;
  background-color: #bde0f1;
}

.cart-item:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0;
  transition: opacity 0.3s ease;
  box-shadow: 0px 4px 44px 3px rgba(169, 172, 189, 0.4);
  z-index: -1;
}

.cart-item:hover {
  z-index: 25;
}

.cart-item:hover:after {
  opacity: 1;
}

.cart-item__head:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
}

@media only screen and (min-width: 924px) {
  .cart-item__head {
    display: none;
  }
}

.cart-item__preview {
  width: 52px;
  float: left;
  margin-right: 1em;
}

@media only screen and (min-width: 480px) {
  .cart-item__preview {
    width: 70px;
  }
}

@media only screen and (min-width: 760px) {
  .cart-item__preview {
    width: 100px;
  }
}

.cart-item__opener {
  float: right;
  margin-left: 1em;
}

.cart-item__short-info {
  overflow: hidden;
}

.cart-item__short-info > * {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cart--info .cart-item__short-info > * {
  white-space: normal;
  overflow: visible;
}

@media only screen and (max-width: 923px) {
  .cart-item__body {
    display: none;
  }
}

.cart--compact .cart-item__body {
  display: none;
}

.cart-item__main {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
}

@media only screen and (min-width: 924px) {
  .cart-item__main {
    flex-wrap: nowrap;
    padding-top: 0;
  }
}

.cart-item__cell {
  padding-left: 12px;
}

@media only screen and (min-width: 924px) {
  .cart-item__cell {
    padding-left: 22px;
  }
}

.cart-item__cell--remover {
  flex-shrink: 0;
  width: 40%;
  padding-bottom: 1em;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

@media only screen and (min-width: 924px) {
  .cart-item__cell--remover {
    width: 2em;
    width: auto;
    margin: 0;
    padding: 0;
    border-width: 0;
  }
}

.cart-item__cell--image {
  display: none;
}

.cart-item__cell--image a {
  border: 1px solid transparent;
  display: block;
  max-width: 100%;
  transition: border-color 0.3s ease;
  height: 100px;
  line-height: 100px;
  position: relative;
}

.cart-item__cell--image img {
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
}

@media only screen and (min-width: 924px) {
  .cart-item__cell--image {
    display: block;
    width: 120px;
    flex-shrink: 0;
    padding-left: 1em;
  }
}

.cart-item__cell--info {
  display: none;
  justify-content: center;
  flex-direction: column;
  max-width: 312px;
}

@media only screen and (min-width: 924px) {
  .cart-item__cell--info {
    display: flex;
    flex-grow: 1;
  }
}

.cart-item__cell--spinner {
  flex-shrink: 0;
  width: 60%;
  padding-bottom: 1em;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

@media only screen and (min-width: 924px) {
  .cart-item__cell--spinner {
    padding-left: 22px;
    width: auto;
    padding-bottom: 0;
    margin-bottom: 0;
    border-width: 0;
    align-items: center;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 923px) {
  .cart-item__cell--spinner .cart-item__label {
    display: none;
  }
}

.cart-item__cell--spinner .cart-input {
  width: 40px !important;
  margin: 0 !important;
  font-size: 24px !important;
}

.cart-item__cell--spinner .cart-button {
  width: 34px;
  height: 46px;
  min-width: 34px;
  line-height: 46px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.cart-item__cell--prices {
  flex-shrink: 0;
  width: 40%;
}

@media only screen and (min-width: 924px) {
  .cart-item__cell--prices {
    display: none;
  }
}

@media only screen and (max-width: 923px) {
  .cart-item__cell--prices {
    padding-left: 0;
  }
}

.cart-item__cell--totals {
  flex-shrink: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
}

@media only screen and (min-width: 924px) {
  .cart-item__cell--totals {
    min-width: 166px;
    width: auto;
    margin-top: 0;
    justify-content: flex-end;
  }
}

.cart-item__cell--totals .cart-price .cart-price {
  display: flex;
  align-items: center;
}

.cart-item__cell--totals .cart-price span.js-item-total-price {
  font-size: 24px;
  color: #fe5400;
  font-weight: 500;
  line-height: 100%;
}

.cart-item__cell--totals .cart-price small {
  font-size: 24px;
  font-weight: 500;
}

.cart-item__title {
  margin-bottom: 20px;
  max-width: 252px;
}

.cart-item__title a {
  color: #445568;
  text-decoration: none;
}

.cart-item__title a:hover {
  color: #fe5400;
}

.cart-item__sub-title {
  color: #807f7f;
}

.cart-item__head .cart-item__title {
  margin-bottom: .5em;
  line-height: 1.3em;
}

.cart-item__label {
  display: block;
  margin-bottom: .4em;
  color: #404040;
  font-size: 1.2em;
}

@media only screen and (min-width: 924px) {
  .cart-item__label {
    font-size: 1em;
    margin-bottom: 1em;
  }
}

@media only screen and (max-width: 923px) {
  .cart-item.is-opened {
    background: linear-gradient(to bottom, #fcfcfc 0%, #fcfcfc 75%, #f7f7f7 100%);
  }
  .cart-item.is-opened:before {
    border-top: 2px dotted #807f7f;
    left: 0;
    right: 0;
  }
  .cart-item.is-opened .cart-item__head {
    padding-bottom: .5em;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 1em;
  }
  .cart-item.is-opened .cart-item__opener svg:first-child {
    display: none;
  }
  .cart-item.is-opened .cart-item__opener svg:last-child {
    display: block;
  }
  .cart-item.is-opened .cart-item__short-info .cart-item__title {
    white-space: normal;
  }
  .cart-item.is-opened .cart-item__short-info .cart-item__sub-title {
    display: none;
  }
  .cart-item.is-opened .cart-item__body {
    display: block;
  }
}

.cart--compact .cart-item.is-opened {
  background: linear-gradient(to bottom, #fcfcfc 0%, #fcfcfc 75%, #f7f7f7 100%);
}

.cart--compact .cart-item.is-opened:before {
  border-top: 2px dotted #807f7f;
  left: 0;
  right: 0;
}

.cart--compact .cart-item.is-opened .cart-item__head {
  padding-bottom: .5em;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 1em;
}

.cart--compact .cart-item.is-opened .cart-item__opener svg:first-child {
  display: none;
}

.cart--compact .cart-item.is-opened .cart-item__opener svg:last-child {
  display: block;
}

.cart--compact .cart-item.is-opened .cart-item__short-info .cart-item__title {
  white-space: normal;
}

.cart--compact .cart-item.is-opened .cart-item__short-info .cart-item__sub-title {
  display: none;
}

.cart--compact .cart-item.is-opened .cart-item__body {
  display: block;
}

.cart-price {
  display: flex;
  align-items: center;
  color: #807f7f;
}

.cart-price--end {
  flex-direction: column;
  align-items: stretch;
}

.cart-price--end strong {
  margin-bottom: 8px;
}

.cart-price--end div span,
.cart-price--end div small {
  font-size: 30px !important;
  font-weight: 500 !important;
}

@media only screen and (min-width: 1025px) {
  .cart-price--end div span,
  .cart-price--end div small {
    font-size: 32px !important;
    font-weight: 500 !important;
  }
}

@media only screen and (min-width: 1280px) {
  .cart-price--end div span,
  .cart-price--end div small {
    font-size: 36px !important;
    font-weight: 500 !important;
  }
}

.cart-price + .cart-price {
  padding-top: .25em;
}

.cart-price > * {
  display: inline-block;
}

.cart-price strong {
  font-size: 1.4em;
  font-weight: normal;
  margin-right: .2em;
}

.cart-price small {
  font-size: 100%;
  vertical-align: initial;
  font-weight: 100;
  margin-left: 4px;
  color: #fe5400;
}

.cart-div--emphasis .cart-price:not(.cart-price--old) {
  color: #000;
}

.cart-div--emphasis .cart-price:not(.cart-price--old) small {
  color: #fe5400;
}

.cart-price:not(.cart-price--old) span {
  font-weight: 100;
  font-size: 16px;
  line-height: 100%;
}

@media only screen and (min-width: 480px) {
  .cart-price:not(.cart-price--old) span {
    font-size: 18px;
  }
}

@media only screen and (min-width: 480px) {
  .cart-item__cell--totals .cart-price:not(.cart-price--old) span {
    font-size: 1.4em;
  }
}

.cart-div--emphasis .cart-price:not(.cart-price--old) span {
  color: #fe5400;
}

.cart-results .cart-price:not(.cart-price--old) span {
  font-size: 1.8em;
}

.cart-price--old {
  color: #807f7f;
}

.cart-price--old small {
  color: #807f7f;
}

.cart-price--old span {
  text-decoration: line-through;
  font-weight: 100;
}

.cart-notice {
  display: none;
  position: absolute;
  margin: -.75em 0 0 -1em;
  padding: .75em 1.5em 1em;
  border-right: .25em;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(64, 64, 64, 0.5);
}

.cart-notice .button {
  margin: 0 .3em .3em 0;
}

@media only screen and (min-width: 760px) {
  .cart-notice {
    margin-left: -1.5em;
  }
}

.cart-notice__question {
  user-select: none;
  line-height: 1.4em;
  padding: .3em 0;
  margin-bottom: .25em;
}

.cart-notice__answer {
  white-space: nowrap;
}

.cart-item.is-notice .cart-notice,
.cart-item.is-notice .cart-notice-overlay {
  display: block;
}

.cart-button {
  display: block;
  box-sizing: border-box;
  height: 100%;
  border-radius: .2em;
  text-align: center;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  padding: .5em;
  line-height: 100%;
  color: #404040;
  fill: currentColor;
  user-select: none;
  outline: none;
  font-size: 30px;
  font-weight: 100;
  border-width: 0;
  width: 42px;
}

.cart-button span {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: auto;
}

.cart-button.is-disabled {
  display: block;
  pointer-events: none;
  opacity: .6;
  background-color: #e6e6e6;
  color: #807f7f;
}

.cart-button svg {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  fill: #33363e;
  transition: fill 0.3s ease;
}

.cart-button svg + svg {
  display: none;
}

.cart-button:hover {
  color: #404040;
  background-color: #e6e6e6;
}

.cart-button:active svg {
  fill: #4da2cb;
  transition: fill 0s ease;
}

.cart-input {
  display: block;
  width: 100%;
  text-align: center;
  border: none;
  outline: none;
  font-family: "Museo Sans";
  border-radius: .2em;
}

.cart-spinner {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  user-select: none;
  border: 1px solid #bde0f1;
  border-radius: 10px;
  overflow: hidden;
  height: 48px;
}

.cart-spinner > * {
  flex-shrink: 0;
}

.cart-spinner .cart-input {
  display: inline-flex;
  width: 50px;
  margin: 0 .25em;
  font-size: 30px;
  font-weight: 500;
  user-select: initial;
}

.continue-buys {
  display: flex;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
  font-weight: 700;
  letter-spacing: 0.1em;
  align-items: center;
}

.continue-buys svg {
  width: 20px;
  height: 14px;
  margin-right: 10px;
  fill: #bde0f1;
}

.cart-total__action {
  min-width: 234px;
  margin: 20px 0;
}

@media only screen and (min-width: 1025px) {
  .cart-total__action {
    min-width: 260px;
  }
}

.cart-total__action .button__content {
  min-width: 200px !important;
}

@media only screen and (min-width: 1025px) {
  .cart-total__action .button__content {
    min-width: 250px !important;
  }
}

.cart-footer {
  position: relative;
}

.cart-results {
  padding: 1rem 0;
  text-align: center;
}

@media only screen and (min-width: 760px) {
  .cart-results {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.cart--compact .cart-results,
.cart--info .cart-results {
  display: block;
}

@media only screen and (min-width: 760px) {
  .cart-results .cart-div--emphasis {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
}

.cart-results__center .cart-results .cart-div--emphasis,
.cart--compact .cart-results .cart-div--emphasis,
.cart--info .cart-results .cart-div--emphasis {
  margin: 0 auto;
  padding: 1em;
  max-width: 320px;
  width: 100%;
}

.cart--compact .cart-results .cart-div--emphasis {
  margin-top: 1em;
}

.cart-results__left {
  min-width: 120px;
}

@media only screen and (min-width: 1025px) {
  .cart-results__left {
    min-width: 150px;
  }
}

@media only screen and (max-width: 759px) {
  .cart-results__left {
    display: inline-flex;
    min-width: 60%;
    margin-bottom: 1em;
    justify-content: center;
  }
  .cart-results__left .button {
    font-size: 1.25em;
  }
}

.cart-results__right {
  min-width: 246px;
}

@media only screen and (min-width: 1025px) {
  .cart-results__right {
    min-width: 284px;
  }
}

@media only screen and (max-width: 759px) {
  .cart-results__right {
    display: inline-block;
    min-width: 60%;
  }
  .cart-results__right .cart-price span {
    font-size: 2em;
  }
  .cart-results__right .button {
    font-size: 1.25em;
  }
}

.js-cart-widget .order-cart__title {
  padding-top: 14px;
}

.js-cart-remove-item {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background-color: #f1fafb;
  margin: auto 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.js-cart-remove-item svg {
  display: inline-block;
  width: 22px;
  height: 22px;
  fill: #c3e3f2;
}

.js-cart-remove-item:hover {
  background-color: #f1fafb;
}

.js-cart-remove-item:hover svg {
  fill: #fe5400;
}

.resp-tabs-buttons {
  position: relative;
  width: 100%;
  min-height: 48px;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  margin-bottom: 20px;
}

@media only screen and (min-width: 760px) {
  .resp-tabs-buttons {
    display: none;
  }
}

.resp-tabs-buttons__active, .resp-tabs-buttons__button {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 100%;
  font-weight: 300;
  font-family: 'Museo Sans';
  color: #4da2cb;
  position: relative;
  transition: color 0.5s ease;
  margin-right: 0;
  border-bottom: 1px solid #4da2cb;
  background-color: #fff;
  z-index: 5;
  cursor: pointer;
}

.resp-tabs-buttons__active.is-active, .resp-tabs-buttons__button.is-active {
  background-color: #e4ebee;
}

.resp-tabs-buttons__active {
  width: 100%;
  cursor: pointer;
  z-index: 15;
  position: relative;
}

.resp-tabs-buttons__content {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  transform: scale(0.98) translateY(-80px);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.5s 0s ease, opacity 0.5s 0s ease, visibility 0s 0.5s ease;
  z-index: 5;
}

.resp-tabs-buttons__content.is-active {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: transform 0.5s 0.1s ease, opacity 0.5s 0.1s ease, visibility 0s 0s ease;
}

.js-tabs-block__button {
  cursor: pointer;
}

.js-tabs-block__button.is-active {
  user-select: none;
  cursor: default;
}

.js-tabs-block__item {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transition: opacity 0.5s 0s ease, visibility 0s 0.5s ease;
  left: 0;
  top: 0;
  width: 100%;
  padding-bottom: 70px;
}

.js-tabs-block__item.is-active {
  position: relative;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s 0.1s ease, visibility 0s 0s ease;
}

.js-tabs-block__content {
  position: relative;
}

.enter-reg {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  padding: 22px 12px 30px;
}

@media only screen and (min-width: 760px) {
  .enter-reg {
    flex-wrap: nowrap;
    padding: 22px 66px 50px;
  }
}

.enter-reg__title {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Museo Sans';
  position: relative;
  z-index: 2;
  padding-bottom: 12px;
  border-bottom: 1px solid #bde0f1;
  padding-right: 20px;
}

@media only screen and (min-width: 640px) {
  .enter-reg__title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 760px) {
  .enter-reg__title {
    font-size: 24px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 1025px) {
  .enter-reg__title {
    font-size: 28px;
  }
}

.enter-reg:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 82px;
  border-radius: 6px 6px 0 0;
  background-color: #ecf6fb;
  content: "";
}

.enter-reg .control-holder {
  width: 100%;
}

.enter-reg .form__group {
  width: 100%;
  margin-bottom: 20px;
}

.enter-reg .control-holder--text input, .enter-reg .control-holder--text textarea, .enter-reg .control-holder--text select {
  margin-bottom: 0;
}

.enter-reg .button {
  margin-top: 8px;
}

.enter-block {
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0s 0.2s ease;
  width: 100%;
}

@media only screen and (min-width: 760px) {
  .enter-block {
    width: calc(50% - 20px);
  }
}

.enter-block.is-active {
  display: block;
  visibility: visible;
  opacity: 1;
  position: relative;
  transition: opacity 0.5s 0.1s ease, visibility 0s ease;
}

.enter-block__form {
  height: 100%;
  width: 100%;
}

.enter-block__form .form__group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.enter-block__form .form__caption {
  width: 150px;
  font-weight: 300;
  font-family: 'Museo Sans';
  font-size: 18px;
  color: #000;
}

.repassword-block {
  width: 100%;
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0s 0.2s ease;
}

@media only screen and (min-width: 760px) {
  .repassword-block {
    width: calc(50% - 20px);
  }
}

.repassword-block.is-active {
  display: block;
  left: 0;
  top: 0;
  visibility: visible;
  opacity: 1;
  position: relative;
  transition: opacity 0.5s 0.1s ease, visibility 0s ease;
}

.repassword-block__form {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.repassword-block__form .form__caption {
  font-weight: 300;
  font-family: 'Museo Sans';
  font-size: 18px;
  color: #000;
}

.repassword-block__buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.repassword-block__buttons .button {
  margin-bottom: 20px;
}

.reg-block {
  width: 100%;
  margin-top: 20px;
}

@media only screen and (min-width: 760px) {
  .reg-block {
    margin-top: 0;
    width: calc(50% - 20px);
  }
}

.reg-block__form {
  height: 100%;
  width: 100%;
}

.reg-block__form .form__group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.reg-block__form .form__caption {
  width: 150px;
  font-weight: 300;
  font-family: 'Museo Sans';
  font-size: 18px;
  color: #000;
}

.reg-block__form .control-holder {
  width: 100%;
}

.lose-password {
  margin-bottom: 14px;
  cursor: pointer;
  transition: color 0.5s ease;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  border-bottom: 1px dotted #000;
  font-family: 'Museo Sans';
  display: inline-block;
}

.lose-password:hover {
  color: #fe5400;
}

.info-user {
  color: #000;
}

.row-lk {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0px -20px;
}

.col-lk {
  padding: 0px 20px;
  display: inline-block;
  flex-basis: auto;
  width: auto;
}

.order {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 34px;
  z-index: 25;
  position: relative;
}

.order .order-content .ui-selectmenu-button {
  max-width: 100%;
}

.next-order {
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.1em;
  transition: color 0.3s ease;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 30px;
}

.next-order span {
  border-bottom: 1px dotted #000;
  margin-right: 8px;
}

.next-order svg {
  width: 20px;
  height: 12px;
  fill: #bde0f1;
}

.next-order:hover {
  color: #fe5400;
}

.order-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 20px 0 !important;
  border-right: none;
}

@media only screen and (min-width: 1025px) {
  .order-content {
    padding: 0 10px 20px 20px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .order-content {
    width: 50%;
    padding: 0 40px 20px 90px !important;
    border-right: 1px solid #bde0f1;
  }
}

.order-content__map {
  position: relative;
  width: 100%;
  height: 248px;
  margin-left: 0;
}

@media only screen and (min-width: 760px) {
  .order-content__map {
    height: 358px;
  }
}

@media only screen and (min-width: 1280px) {
  .order-content__map {
    margin-left: 240px;
    width: calc(100% - 240px);
  }
}

.order-content .ui-selectmenu-button {
  width: 100%;
  max-width: 518px;
}

.order-content__input-group {
  display: flex;
  margin-bottom: 6px;
  align-items: flex-start;
  flex-direction: column;
}

.order-content__text {
  margin-bottom: 6px;
  color: #4da2cb;
  font-weight: 100;
  font-size: 16px;
}

.order-content__title {
  font-size: 24px;
  font-weight: 500;
  color: #445568;
  margin-bottom: 20px;
  padding-bottom: 8px;
  display: flex;
  border-bottom: 1px solid #bde0f1;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (min-width: 760px) {
  .order-content__title {
    flex-direction: row;
  }
}

.order-content__edit-data {
  color: #000;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-top: 20px;
  border-bottom: 1px dotted #000;
}

@media only screen and (min-width: 760px) {
  .order-content__edit-data {
    margin-top: 0;
  }
}

.order-content .control-holder--flag label:only-child, .order-content .control-holder--flag label.has-error {
  cursor: pointer;
}

.order-content__accordeon-content {
  padding-bottom: 40px;
}

.order-content__accordeon-button {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 16px;
}

.order-content__accordeon-button .has-error {
  display: none !important;
}

.order-content__accordeon-button span {
  color: #445568;
  font-size: 18px;
  font-weight: 500;
  margin-left: 10px;
}

.order-content__accordeon-button.is-active {
  background-color: #ebebf1;
  cursor: default;
}

.order-content .form__group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.order-content__button {
  margin-right: auto;
}

.order-content .form__caption {
  margin-bottom: 6px;
}

.order-content__feed-btn {
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 10px;
  font-weight: 500;
  transition: color 0.3s ease;
  margin: 30px 0 38px 0;
}

@media only screen and (min-width: 1280px) {
  .order-content__feed-btn {
    margin: 30px 0 38px 240px;
  }
}

.order-content__feed-btn:hover {
  color: #bde0f1;
}

.order-content__bucklets {
  margin-left: 0;
}

@media only screen and (min-width: 1280px) {
  .order-content__bucklets {
    margin-left: 240px;
  }
}

.order-content__bucklets ins {
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: none;
  position: relative;
}

.order-content__bucklets ins:after {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 16px;
  height: 12px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url(../images/okay_check.svg);
  opacity: 0;
  content: "";
  transition: opacity 0.3s ease;
}

.order-content__bucklets span {
  transition: color 0.3s ease;
}

.order-content__bucklets input:checked ~ ins {
  background-color: #fff;
}

.order-content__bucklets input:checked ~ ins:after {
  opacity: 1;
}

.order-content__bucklets input:checked span {
  text-decoration: underline;
}

.order-content__step--first {
  margin-bottom: 20px;
  position: relative;
  padding-left: 0;
}

@media only screen and (min-width: 640px) {
  .order-content__step--first {
    padding-left: 46px;
  }
}

@media only screen and (min-width: 1025px) {
  .order-content__step--first {
    padding-left: 0;
  }
}

.order-content__step--first:before {
  position: absolute;
  content: "1";
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background-color: #fe5400;
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  display: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 100%;
}

@media only screen and (min-width: 640px) {
  .order-content__step--first:before {
    display: flex;
  }
}

@media only screen and (min-width: 1025px) {
  .order-content__step--first:before {
    left: -50px;
  }
}

.order-content__step--second {
  position: relative;
  padding-left: 0;
}

@media only screen and (min-width: 640px) {
  .order-content__step--second {
    padding-left: 46px;
  }
}

@media only screen and (min-width: 1025px) {
  .order-content__step--second {
    padding-left: 0;
  }
}

.order-content__step--second:before {
  position: absolute;
  content: "2";
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background-color: #a9acbd;
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  display: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 100%;
}

@media only screen and (min-width: 640px) {
  .order-content__step--second:before {
    display: flex;
  }
}

@media only screen and (min-width: 1025px) {
  .order-content__step--second:before {
    left: -50px;
  }
}

.order-content__step--second.is-active:before {
  background-color: #fe5400;
}

.order-content__step--third > .form__group {
  margin-bottom: 5px;
}

.order-content__step--third .order-content__accordeon-button {
  margin-bottom: 0;
}

.order-content .control-holder {
  width: 100%;
}

.order-content__sub-text {
  font-size: 16px;
  color: #0d1017;
  font-weight: 300;
  margin-left: 0;
}

@media only screen and (min-width: 1280px) {
  .order-content__sub-text {
    margin-left: 240px;
  }
}

.order-types {
  display: flex;
  margin-bottom: 44px;
  flex-wrap: wrap;
}

.order-types .form__group {
  width: 100%;
}

@media only screen and (min-width: 760px) {
  .order-types .form__group {
    width: 50%;
  }
}

.ui-selectmenu-menu .ui-menu {
  max-height: 50vh;
}

.to-top {
  position: fixed;
  bottom: 10%;
  right: 5%;
  z-index: 255;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(1, 0.15, 1, 0.01), opacity 0.3s ease;
  border-radius: 100%;
  display: none;
  transform: scale(0);
  transform-origin: center;
  backface-visibility: hidden;
  opacity: 0;
}

@media only screen and (min-width: 760px) {
  .to-top {
    display: flex;
  }
}

.to-top.js-active {
  transform: scale(1);
  opacity: 1;
  transition: transform 0.5s cubic-bezier(0.78, 0.77, 0.74, 1.15), opacity 0.5s ease;
}

.to-top .to-top__wrapper {
  background-color: #fe5400;
  border-radius: 100%;
  transition: background-color 0.5s ease,transform 0.2s ease;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;
  transform-origin: center;
  transform: rotate(-90deg);
}

.to-top .to-top__wrapper:hover {
  transform: scale(0.9) rotate(-90deg);
  background-color: #ff7632;
}

.to-top__image {
  fill: #fff;
  stroke: #fff;
  max-width: 60%;
}

.to-top__border {
  border: 2px solid #fe5400;
  background-color: transparent;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  transition: transform 0.5s ease;
}

.to-top__border:hover {
  animation: arrow-to-top 1s linear infinite;
}

@keyframes arrow-to-top {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.15);
  }
  100% {
    opacity: 0;
    transform: scale(1.3);
  }
}

.show-more {
  position: absolute;
  top: -60px;
  right: 32px;
  z-index: 15;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.5s cubic-bezier(0.78, 0.77, 0.74, 1.15), opacity 0.5s ease;
  border-radius: 100%;
  display: flex;
  transform: scale(1);
  transform-origin: center;
  backface-visibility: hidden;
  opacity: 1;
  outline: none;
  border: none;
  background: none;
}

.show-more .show-more__wrapper {
  background-color: #4da2cb;
  border-radius: 100%;
  transition: background-color 0.5s ease,transform 0.2s ease;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  width: 30px;
  height: 30px;
  transform-origin: center;
}

@media only screen and (min-width: 480px) {
  .show-more .show-more__wrapper {
    width: 50px;
    height: 50px;
  }
}

.show-more .show-more__wrapper:hover {
  transform: scale(0.9);
  background-color: #74b7d7;
}

.show-more__image {
  fill: #fff;
  max-width: 80%;
}

.show-more__border {
  border: 2px solid #4da2cb;
  background-color: transparent;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  transition: transform 0.5s ease;
}

.show-more__border:hover {
  animation: arrow-to-top 1s linear infinite;
}

.lk-orders__head, .lk-orders__order {
  background-color: transparent;
  text-align: center;
  transition: .25s ease;
}

.lk-orders__head {
  color: #000;
  font-weight: bold;
  border-bottom-width: 12px;
}

.lk-orders__order:last-child {
  border-bottom-width: 0;
}

.lk-orders__order--done .lk-orders__status {
  color: #4dab19;
}

.lk-orders__order--done {
  background-color: #eefbe6;
}

.lk-orders__order--in-progress .lk-orders__status {
  color: blue;
}

.lk-orders__order--canceled {
  background-color: #ff8a8a;
}

.lk-orders__order--canceled .lk-orders__status {
  color: red;
}

.lk-orders__toggler {
  cursor: pointer;
  user-select: none;
  transition: background-color 0.5s ease;
}

.no-touchevents .lk-orders__toggler:hover {
  background-color: #ecf6fb;
}

.lk-orders__toggler td:first-child {
  width: 14px;
}

.lk-orders__toggler td:first-child svg {
  width: 14px;
  height: 12px;
}

.lk-orders__info {
  border-top: 1px solid #000;
}

:not(.is-active) > .lk-orders__info {
  display: none;
}

.lk-history {
  padding: 20px 0;
  overflow: overlay;
}

.lk-history__table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.lk-history__table td {
  padding: 11px .5rem;
}

.lk-order {
  width: 100%;
  max-width: 708px;
}

.lk-order td:first-child {
  width: 2rem;
}

.lk-order__service td {
  padding-top: 0;
}

.lk-order__item:not(:first-child) td {
  border-top: 1px solid #bababa;
}

.lk-order__img {
  display: block;
  padding: 10px;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  transition: border-color 0.5s ease;
}

.lk-order__img span {
  display: block;
  width: 90px;
  height: 80px;
  background: center no-repeat;
  background-size: contain;
  margin: auto;
}

.lk-order__service .lk-order__img span {
  height: 50px;
  width: 65px;
}

.lk-order__service .lk-order__img {
  margin-left: 25px;
}

.lk-order__img:hover {
  border-color: #807f7f;
}

.lk-order__link {
  white-space: normal !important;
  text-align: left;
  line-height: 1.4;
}

.lk-order__link a {
  font-weight: bold;
  text-decoration: none;
  font-size: 90%;
  color: inherit;
  transition: color 0.5s ease;
}

.lk-order__link a:hover {
  color: blue;
}

.lk-order__total > td {
  width: auto;
  border-top: 1px solid #999999;
  padding-bottom: 0;
}

._color-black {
  color: #000;
}

._color-gray90 {
  color: #1a1a1a;
}

._color-gray75 {
  color: #404040;
}

._color-gray50 {
  color: #807f7f;
}

._color-gray25 {
  color: #bfbfbf;
}

._color-gray10 {
  color: #e6e6e6;
}

._color-white {
  color: #fff;
}

._color-link {
  color: #43f;
}

._color-primary {
  color: #8074ff;
}

._color-secondary {
  color: #e652a6;
}

._color-success {
  color: #c1af7a;
}

._color-info {
  color: #00b3fe;
}

._color-warning {
  color: #c89600;
}

._color-danger {
  color: #ff6262;
}

._bgcolor-black {
  background-color: #000;
}

._bgcolor-gray90 {
  background-color: #1a1a1a;
}

._bgcolor-gray75 {
  background-color: #404040;
}

._bgcolor-gray50 {
  background-color: #807f7f;
}

._bgcolor-gray25 {
  background-color: #bfbfbf;
}

._bgcolor-gray10 {
  background-color: #e6e6e6;
}

._bgcolor-white {
  background-color: #fff;
}

._bgcolor-link {
  background-color: #43f;
}

._bgcolor-primary {
  background-color: #8074ff;
}

._bgcolor-secondary {
  background-color: #e652a6;
}

._bgcolor-success {
  background-color: #c1af7a;
}

._bgcolor-info {
  background-color: #00b3fe;
}

._bgcolor-warning {
  background-color: #c89600;
}

._bgcolor-danger {
  background-color: #ff6262;
}

._fill-black {
  fill: #000;
}

._fill-gray90 {
  fill: #1a1a1a;
}

._fill-gray75 {
  fill: #404040;
}

._fill-gray50 {
  fill: #807f7f;
}

._fill-gray25 {
  fill: #bfbfbf;
}

._fill-gray10 {
  fill: #e6e6e6;
}

._fill-white {
  fill: #fff;
}

._fill-link {
  fill: #43f;
}

._fill-primary {
  fill: #8074ff;
}

._fill-secondary {
  fill: #e652a6;
}

._fill-success {
  fill: #c1af7a;
}

._fill-info {
  fill: #00b3fe;
}

._fill-warning {
  fill: #c89600;
}

._fill-danger {
  fill: #ff6262;
}

._left {
  float: left;
}

._right {
  float: right;
}

._overflow {
  overflow: hidden;
}

._clear {
  clear: both;
}

._clear-after:after {
  content: '';
  clear: both;
  display: block;
  height: 0;
}

@media only screen and (min-width: 375px) {
  ._xs-left {
    float: left;
  }
  ._xs-right {
    float: right;
  }
  ._xs-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-left {
    float: left;
  }
  ._sm-right {
    float: right;
  }
  ._sm-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-left {
    float: left;
  }
  ._ms-right {
    float: right;
  }
  ._ms-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 760px) {
  ._md-left {
    float: left;
  }
  ._md-right {
    float: right;
  }
  ._md-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1024px) {
  ._defp-left {
    float: left;
  }
  ._defp-right {
    float: right;
  }
  ._defp-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1025px) {
  ._def-left {
    float: left;
  }
  ._def-right {
    float: right;
  }
  ._def-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-left {
    float: left;
  }
  ._lg-right {
    float: right;
  }
  ._lg-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1440px) {
  ._mg-left {
    float: left;
  }
  ._mg-right {
    float: right;
  }
  ._mg-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1560px) {
  ._xxl-left {
    float: left;
  }
  ._xxl-right {
    float: right;
  }
  ._xxl-overflow {
    overflow: hidden;
  }
}

._text-left {
  text-align: left;
}

._text-right {
  text-align: right;
}

._text-center {
  text-align: center;
}

._ellipsis {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 375px) {
  ._xs-text-left {
    text-align: left;
  }
  ._xs-text-right {
    text-align: right;
  }
  ._xs-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-text-left {
    text-align: left;
  }
  ._sm-text-right {
    text-align: right;
  }
  ._sm-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-text-left {
    text-align: left;
  }
  ._ms-text-right {
    text-align: right;
  }
  ._ms-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 760px) {
  ._md-text-left {
    text-align: left;
  }
  ._md-text-right {
    text-align: right;
  }
  ._md-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1024px) {
  ._defp-text-left {
    text-align: left;
  }
  ._defp-text-right {
    text-align: right;
  }
  ._defp-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1025px) {
  ._def-text-left {
    text-align: left;
  }
  ._def-text-right {
    text-align: right;
  }
  ._def-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-text-left {
    text-align: left;
  }
  ._lg-text-right {
    text-align: right;
  }
  ._lg-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1440px) {
  ._mg-text-left {
    text-align: left;
  }
  ._mg-text-right {
    text-align: right;
  }
  ._mg-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1560px) {
  ._xxl-text-left {
    text-align: left;
  }
  ._xxl-text-right {
    text-align: right;
  }
  ._xxl-text-center {
    text-align: center;
  }
}

._hide {
  display: none;
}

._hide\! {
  display: none !important;
}

@media print {
  ._noprint {
    display: none !important;
  }
}

@media only screen and (min-width: 375px) {
  ._xs-hide {
    display: none;
  }
  ._xs-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 374px) {
  ._xs-show {
    display: none;
  }
  ._xs-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-hide {
    display: none;
  }
  ._sm-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 479px) {
  ._sm-show {
    display: none;
  }
  ._sm-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-hide {
    display: none;
  }
  ._ms-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 639px) {
  ._ms-show {
    display: none;
  }
  ._ms-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 760px) {
  ._md-hide {
    display: none;
  }
  ._md-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 759px) {
  ._md-show {
    display: none;
  }
  ._md-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  ._defp-hide {
    display: none;
  }
  ._defp-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1023px) {
  ._defp-show {
    display: none;
  }
  ._defp-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1025px) {
  ._def-hide {
    display: none;
  }
  ._def-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1024px) {
  ._def-show {
    display: none;
  }
  ._def-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-hide {
    display: none;
  }
  ._lg-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1279px) {
  ._lg-show {
    display: none;
  }
  ._lg-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1440px) {
  ._mg-hide {
    display: none;
  }
  ._mg-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1439px) {
  ._mg-show {
    display: none;
  }
  ._mg-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1560px) {
  ._xxl-hide {
    display: none;
  }
  ._xxl-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1559px) {
  ._xxl-show {
    display: none;
  }
  ._xxl-show\! {
    display: none !important;
  }
}

._m-sm {
  margin: 0.4166667rem !important;
}

._m-md {
  margin: 0.8333333rem !important;
}

._m-def {
  margin: 1.6666667rem !important;
}

._m-lg {
  margin: 2.5rem !important;
}

._m-xl {
  margin: 3.3333333rem !important;
}

._m-mg {
  margin: 4.1666667rem !important;
}

._mt-sm {
  margin-top: 0.4166667rem !important;
}

._mt-md {
  margin-top: 0.8333333rem !important;
}

._mt-def {
  margin-top: 1.6666667rem !important;
}

._mt-lg {
  margin-top: 2.5rem !important;
}

._mt-xl {
  margin-top: 3.3333333rem !important;
}

._mt-mg {
  margin-top: 4.1666667rem !important;
}

._mt-none {
  margin-top: 0 !important;
}

._mr-sm {
  margin-right: 0.4166667rem !important;
}

._mr-md {
  margin-right: 0.8333333rem !important;
}

._mr-def {
  margin-right: 1.6666667rem !important;
}

._mr-lg {
  margin-right: 2.5rem !important;
}

._mr-xl {
  margin-right: 3.3333333rem !important;
}

._mr-mg {
  margin-right: 4.1666667rem !important;
}

._mr-none {
  margin-right: 0 !important;
}

._mb-sm {
  margin-bottom: 0.4166667rem !important;
}

._mb-md {
  margin-bottom: 0.8333333rem !important;
}

._mb-def {
  margin-bottom: 1.6666667rem !important;
}

._mb-lg {
  margin-bottom: 2.5rem !important;
}

._mb-xl {
  margin-bottom: 3.3333333rem !important;
}

._mb-mg {
  margin-bottom: 4.1666667rem !important;
}

._mb-none {
  margin-bottom: 0 !important;
}

._ml-sm {
  margin-left: 0.4166667rem !important;
}

._ml-md {
  margin-left: 0.8333333rem !important;
}

._ml-def {
  margin-left: 1.6666667rem !important;
}

._ml-lg {
  margin-left: 2.5rem !important;
}

._ml-xl {
  margin-left: 3.3333333rem !important;
}

._ml-mg {
  margin-left: 4.1666667rem !important;
}

._ml-none {
  margin-left: 0 !important;
}

._m-none {
  margin: 0 !important;
}

._p-sm {
  padding: 0.4166667rem !important;
}

._p-md {
  padding: 0.8333333rem !important;
}

._p-def {
  padding: 1.6666667rem !important;
}

._p-lg {
  padding: 2.5rem !important;
}

._p-xl {
  padding: 3.3333333rem !important;
}

._p-mg {
  padding: 4.1666667rem !important;
}

._pt-sm {
  padding-top: 0.4166667rem !important;
}

._pt-md {
  padding-top: 0.8333333rem !important;
}

._pt-def {
  padding-top: 1.6666667rem !important;
}

._pt-lg {
  padding-top: 2.5rem !important;
}

._pt-xl {
  padding-top: 3.3333333rem !important;
}

._pt-mg {
  padding-top: 4.1666667rem !important;
}

._pt-none {
  padding-top: 0 !important;
}

._pr-sm {
  padding-right: 0.4166667rem !important;
}

._pr-md {
  padding-right: 0.8333333rem !important;
}

._pr-def {
  padding-right: 1.6666667rem !important;
}

._pr-lg {
  padding-right: 2.5rem !important;
}

._pr-xl {
  padding-right: 3.3333333rem !important;
}

._pr-mg {
  padding-right: 4.1666667rem !important;
}

._pr-none {
  padding-right: 0 !important;
}

._pb-sm {
  padding-bottom: 0.4166667rem !important;
}

._pb-md {
  padding-bottom: 0.8333333rem !important;
}

._pb-def {
  padding-bottom: 1.6666667rem !important;
}

._pb-lg {
  padding-bottom: 2.5rem !important;
}

._pb-xl {
  padding-bottom: 3.3333333rem !important;
}

._pb-mg {
  padding-bottom: 4.1666667rem !important;
}

._pb-none {
  padding-bottom: 0 !important;
}

._pl-sm {
  padding-left: 0.4166667rem !important;
}

._pl-md {
  padding-left: 0.8333333rem !important;
}

._pl-def {
  padding-left: 1.6666667rem !important;
}

._pl-lg {
  padding-left: 2.5rem !important;
}

._pl-xl {
  padding-left: 3.3333333rem !important;
}

._pl-mg {
  padding-left: 4.1666667rem !important;
}

._pl-none {
  padding-left: 0 !important;
}

._p-none {
  padding: 0 !important;
}

._m-auto {
  margin: auto !important;
}

._ml-auto {
  margin-left: auto !important;
}

._mr-auto {
  margin-right: auto !important;
}

@media only screen and (min-width: 375px) {
  ._xs-m-sm {
    margin: 0.4166667rem !important;
  }
  ._xs-m-md {
    margin: 0.8333333rem !important;
  }
  ._xs-m-def {
    margin: 1.6666667rem !important;
  }
  ._xs-m-lg {
    margin: 2.5rem !important;
  }
  ._xs-m-xl {
    margin: 3.3333333rem !important;
  }
  ._xs-m-mg {
    margin: 4.1666667rem !important;
  }
  ._xs-mt-sm {
    margin-top: 0.4166667rem !important;
  }
  ._xs-mt-md {
    margin-top: 0.8333333rem !important;
  }
  ._xs-mt-def {
    margin-top: 1.6666667rem !important;
  }
  ._xs-mt-lg {
    margin-top: 2.5rem !important;
  }
  ._xs-mt-xl {
    margin-top: 3.3333333rem !important;
  }
  ._xs-mt-mg {
    margin-top: 4.1666667rem !important;
  }
  ._xs-mt-none {
    margin-top: 0 !important;
  }
  ._xs-mr-sm {
    margin-right: 0.4166667rem !important;
  }
  ._xs-mr-md {
    margin-right: 0.8333333rem !important;
  }
  ._xs-mr-def {
    margin-right: 1.6666667rem !important;
  }
  ._xs-mr-lg {
    margin-right: 2.5rem !important;
  }
  ._xs-mr-xl {
    margin-right: 3.3333333rem !important;
  }
  ._xs-mr-mg {
    margin-right: 4.1666667rem !important;
  }
  ._xs-mr-none {
    margin-right: 0 !important;
  }
  ._xs-mb-sm {
    margin-bottom: 0.4166667rem !important;
  }
  ._xs-mb-md {
    margin-bottom: 0.8333333rem !important;
  }
  ._xs-mb-def {
    margin-bottom: 1.6666667rem !important;
  }
  ._xs-mb-lg {
    margin-bottom: 2.5rem !important;
  }
  ._xs-mb-xl {
    margin-bottom: 3.3333333rem !important;
  }
  ._xs-mb-mg {
    margin-bottom: 4.1666667rem !important;
  }
  ._xs-mb-none {
    margin-bottom: 0 !important;
  }
  ._xs-ml-sm {
    margin-left: 0.4166667rem !important;
  }
  ._xs-ml-md {
    margin-left: 0.8333333rem !important;
  }
  ._xs-ml-def {
    margin-left: 1.6666667rem !important;
  }
  ._xs-ml-lg {
    margin-left: 2.5rem !important;
  }
  ._xs-ml-xl {
    margin-left: 3.3333333rem !important;
  }
  ._xs-ml-mg {
    margin-left: 4.1666667rem !important;
  }
  ._xs-ml-none {
    margin-left: 0 !important;
  }
  ._xs-m-none {
    margin: 0 !important;
  }
  ._xs-p-sm {
    padding: 0.4166667rem !important;
  }
  ._xs-p-md {
    padding: 0.8333333rem !important;
  }
  ._xs-p-def {
    padding: 1.6666667rem !important;
  }
  ._xs-p-lg {
    padding: 2.5rem !important;
  }
  ._xs-p-xl {
    padding: 3.3333333rem !important;
  }
  ._xs-p-mg {
    padding: 4.1666667rem !important;
  }
  ._xs-pt-sm {
    padding-top: 0.4166667rem !important;
  }
  ._xs-pt-md {
    padding-top: 0.8333333rem !important;
  }
  ._xs-pt-def {
    padding-top: 1.6666667rem !important;
  }
  ._xs-pt-lg {
    padding-top: 2.5rem !important;
  }
  ._xs-pt-xl {
    padding-top: 3.3333333rem !important;
  }
  ._xs-pt-mg {
    padding-top: 4.1666667rem !important;
  }
  ._xs-pt-none {
    padding-top: 0 !important;
  }
  ._xs-pr-sm {
    padding-right: 0.4166667rem !important;
  }
  ._xs-pr-md {
    padding-right: 0.8333333rem !important;
  }
  ._xs-pr-def {
    padding-right: 1.6666667rem !important;
  }
  ._xs-pr-lg {
    padding-right: 2.5rem !important;
  }
  ._xs-pr-xl {
    padding-right: 3.3333333rem !important;
  }
  ._xs-pr-mg {
    padding-right: 4.1666667rem !important;
  }
  ._xs-pr-none {
    padding-right: 0 !important;
  }
  ._xs-pb-sm {
    padding-bottom: 0.4166667rem !important;
  }
  ._xs-pb-md {
    padding-bottom: 0.8333333rem !important;
  }
  ._xs-pb-def {
    padding-bottom: 1.6666667rem !important;
  }
  ._xs-pb-lg {
    padding-bottom: 2.5rem !important;
  }
  ._xs-pb-xl {
    padding-bottom: 3.3333333rem !important;
  }
  ._xs-pb-mg {
    padding-bottom: 4.1666667rem !important;
  }
  ._xs-pb-none {
    padding-bottom: 0 !important;
  }
  ._xs-pl-sm {
    padding-left: 0.4166667rem !important;
  }
  ._xs-pl-md {
    padding-left: 0.8333333rem !important;
  }
  ._xs-pl-def {
    padding-left: 1.6666667rem !important;
  }
  ._xs-pl-lg {
    padding-left: 2.5rem !important;
  }
  ._xs-pl-xl {
    padding-left: 3.3333333rem !important;
  }
  ._xs-pl-mg {
    padding-left: 4.1666667rem !important;
  }
  ._xs-pl-none {
    padding-left: 0 !important;
  }
  ._xs-p-none {
    padding: 0 !important;
  }
  ._xs-m-auto {
    margin: auto !important;
  }
  ._xs-ml-auto {
    margin-left: auto !important;
  }
  ._xs-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-m-sm {
    margin: 0.4166667rem !important;
  }
  ._sm-m-md {
    margin: 0.8333333rem !important;
  }
  ._sm-m-def {
    margin: 1.6666667rem !important;
  }
  ._sm-m-lg {
    margin: 2.5rem !important;
  }
  ._sm-m-xl {
    margin: 3.3333333rem !important;
  }
  ._sm-m-mg {
    margin: 4.1666667rem !important;
  }
  ._sm-mt-sm {
    margin-top: 0.4166667rem !important;
  }
  ._sm-mt-md {
    margin-top: 0.8333333rem !important;
  }
  ._sm-mt-def {
    margin-top: 1.6666667rem !important;
  }
  ._sm-mt-lg {
    margin-top: 2.5rem !important;
  }
  ._sm-mt-xl {
    margin-top: 3.3333333rem !important;
  }
  ._sm-mt-mg {
    margin-top: 4.1666667rem !important;
  }
  ._sm-mt-none {
    margin-top: 0 !important;
  }
  ._sm-mr-sm {
    margin-right: 0.4166667rem !important;
  }
  ._sm-mr-md {
    margin-right: 0.8333333rem !important;
  }
  ._sm-mr-def {
    margin-right: 1.6666667rem !important;
  }
  ._sm-mr-lg {
    margin-right: 2.5rem !important;
  }
  ._sm-mr-xl {
    margin-right: 3.3333333rem !important;
  }
  ._sm-mr-mg {
    margin-right: 4.1666667rem !important;
  }
  ._sm-mr-none {
    margin-right: 0 !important;
  }
  ._sm-mb-sm {
    margin-bottom: 0.4166667rem !important;
  }
  ._sm-mb-md {
    margin-bottom: 0.8333333rem !important;
  }
  ._sm-mb-def {
    margin-bottom: 1.6666667rem !important;
  }
  ._sm-mb-lg {
    margin-bottom: 2.5rem !important;
  }
  ._sm-mb-xl {
    margin-bottom: 3.3333333rem !important;
  }
  ._sm-mb-mg {
    margin-bottom: 4.1666667rem !important;
  }
  ._sm-mb-none {
    margin-bottom: 0 !important;
  }
  ._sm-ml-sm {
    margin-left: 0.4166667rem !important;
  }
  ._sm-ml-md {
    margin-left: 0.8333333rem !important;
  }
  ._sm-ml-def {
    margin-left: 1.6666667rem !important;
  }
  ._sm-ml-lg {
    margin-left: 2.5rem !important;
  }
  ._sm-ml-xl {
    margin-left: 3.3333333rem !important;
  }
  ._sm-ml-mg {
    margin-left: 4.1666667rem !important;
  }
  ._sm-ml-none {
    margin-left: 0 !important;
  }
  ._sm-m-none {
    margin: 0 !important;
  }
  ._sm-p-sm {
    padding: 0.4166667rem !important;
  }
  ._sm-p-md {
    padding: 0.8333333rem !important;
  }
  ._sm-p-def {
    padding: 1.6666667rem !important;
  }
  ._sm-p-lg {
    padding: 2.5rem !important;
  }
  ._sm-p-xl {
    padding: 3.3333333rem !important;
  }
  ._sm-p-mg {
    padding: 4.1666667rem !important;
  }
  ._sm-pt-sm {
    padding-top: 0.4166667rem !important;
  }
  ._sm-pt-md {
    padding-top: 0.8333333rem !important;
  }
  ._sm-pt-def {
    padding-top: 1.6666667rem !important;
  }
  ._sm-pt-lg {
    padding-top: 2.5rem !important;
  }
  ._sm-pt-xl {
    padding-top: 3.3333333rem !important;
  }
  ._sm-pt-mg {
    padding-top: 4.1666667rem !important;
  }
  ._sm-pt-none {
    padding-top: 0 !important;
  }
  ._sm-pr-sm {
    padding-right: 0.4166667rem !important;
  }
  ._sm-pr-md {
    padding-right: 0.8333333rem !important;
  }
  ._sm-pr-def {
    padding-right: 1.6666667rem !important;
  }
  ._sm-pr-lg {
    padding-right: 2.5rem !important;
  }
  ._sm-pr-xl {
    padding-right: 3.3333333rem !important;
  }
  ._sm-pr-mg {
    padding-right: 4.1666667rem !important;
  }
  ._sm-pr-none {
    padding-right: 0 !important;
  }
  ._sm-pb-sm {
    padding-bottom: 0.4166667rem !important;
  }
  ._sm-pb-md {
    padding-bottom: 0.8333333rem !important;
  }
  ._sm-pb-def {
    padding-bottom: 1.6666667rem !important;
  }
  ._sm-pb-lg {
    padding-bottom: 2.5rem !important;
  }
  ._sm-pb-xl {
    padding-bottom: 3.3333333rem !important;
  }
  ._sm-pb-mg {
    padding-bottom: 4.1666667rem !important;
  }
  ._sm-pb-none {
    padding-bottom: 0 !important;
  }
  ._sm-pl-sm {
    padding-left: 0.4166667rem !important;
  }
  ._sm-pl-md {
    padding-left: 0.8333333rem !important;
  }
  ._sm-pl-def {
    padding-left: 1.6666667rem !important;
  }
  ._sm-pl-lg {
    padding-left: 2.5rem !important;
  }
  ._sm-pl-xl {
    padding-left: 3.3333333rem !important;
  }
  ._sm-pl-mg {
    padding-left: 4.1666667rem !important;
  }
  ._sm-pl-none {
    padding-left: 0 !important;
  }
  ._sm-p-none {
    padding: 0 !important;
  }
  ._sm-m-auto {
    margin: auto !important;
  }
  ._sm-ml-auto {
    margin-left: auto !important;
  }
  ._sm-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-m-sm {
    margin: 0.4166667rem !important;
  }
  ._ms-m-md {
    margin: 0.8333333rem !important;
  }
  ._ms-m-def {
    margin: 1.6666667rem !important;
  }
  ._ms-m-lg {
    margin: 2.5rem !important;
  }
  ._ms-m-xl {
    margin: 3.3333333rem !important;
  }
  ._ms-m-mg {
    margin: 4.1666667rem !important;
  }
  ._ms-mt-sm {
    margin-top: 0.4166667rem !important;
  }
  ._ms-mt-md {
    margin-top: 0.8333333rem !important;
  }
  ._ms-mt-def {
    margin-top: 1.6666667rem !important;
  }
  ._ms-mt-lg {
    margin-top: 2.5rem !important;
  }
  ._ms-mt-xl {
    margin-top: 3.3333333rem !important;
  }
  ._ms-mt-mg {
    margin-top: 4.1666667rem !important;
  }
  ._ms-mt-none {
    margin-top: 0 !important;
  }
  ._ms-mr-sm {
    margin-right: 0.4166667rem !important;
  }
  ._ms-mr-md {
    margin-right: 0.8333333rem !important;
  }
  ._ms-mr-def {
    margin-right: 1.6666667rem !important;
  }
  ._ms-mr-lg {
    margin-right: 2.5rem !important;
  }
  ._ms-mr-xl {
    margin-right: 3.3333333rem !important;
  }
  ._ms-mr-mg {
    margin-right: 4.1666667rem !important;
  }
  ._ms-mr-none {
    margin-right: 0 !important;
  }
  ._ms-mb-sm {
    margin-bottom: 0.4166667rem !important;
  }
  ._ms-mb-md {
    margin-bottom: 0.8333333rem !important;
  }
  ._ms-mb-def {
    margin-bottom: 1.6666667rem !important;
  }
  ._ms-mb-lg {
    margin-bottom: 2.5rem !important;
  }
  ._ms-mb-xl {
    margin-bottom: 3.3333333rem !important;
  }
  ._ms-mb-mg {
    margin-bottom: 4.1666667rem !important;
  }
  ._ms-mb-none {
    margin-bottom: 0 !important;
  }
  ._ms-ml-sm {
    margin-left: 0.4166667rem !important;
  }
  ._ms-ml-md {
    margin-left: 0.8333333rem !important;
  }
  ._ms-ml-def {
    margin-left: 1.6666667rem !important;
  }
  ._ms-ml-lg {
    margin-left: 2.5rem !important;
  }
  ._ms-ml-xl {
    margin-left: 3.3333333rem !important;
  }
  ._ms-ml-mg {
    margin-left: 4.1666667rem !important;
  }
  ._ms-ml-none {
    margin-left: 0 !important;
  }
  ._ms-m-none {
    margin: 0 !important;
  }
  ._ms-p-sm {
    padding: 0.4166667rem !important;
  }
  ._ms-p-md {
    padding: 0.8333333rem !important;
  }
  ._ms-p-def {
    padding: 1.6666667rem !important;
  }
  ._ms-p-lg {
    padding: 2.5rem !important;
  }
  ._ms-p-xl {
    padding: 3.3333333rem !important;
  }
  ._ms-p-mg {
    padding: 4.1666667rem !important;
  }
  ._ms-pt-sm {
    padding-top: 0.4166667rem !important;
  }
  ._ms-pt-md {
    padding-top: 0.8333333rem !important;
  }
  ._ms-pt-def {
    padding-top: 1.6666667rem !important;
  }
  ._ms-pt-lg {
    padding-top: 2.5rem !important;
  }
  ._ms-pt-xl {
    padding-top: 3.3333333rem !important;
  }
  ._ms-pt-mg {
    padding-top: 4.1666667rem !important;
  }
  ._ms-pt-none {
    padding-top: 0 !important;
  }
  ._ms-pr-sm {
    padding-right: 0.4166667rem !important;
  }
  ._ms-pr-md {
    padding-right: 0.8333333rem !important;
  }
  ._ms-pr-def {
    padding-right: 1.6666667rem !important;
  }
  ._ms-pr-lg {
    padding-right: 2.5rem !important;
  }
  ._ms-pr-xl {
    padding-right: 3.3333333rem !important;
  }
  ._ms-pr-mg {
    padding-right: 4.1666667rem !important;
  }
  ._ms-pr-none {
    padding-right: 0 !important;
  }
  ._ms-pb-sm {
    padding-bottom: 0.4166667rem !important;
  }
  ._ms-pb-md {
    padding-bottom: 0.8333333rem !important;
  }
  ._ms-pb-def {
    padding-bottom: 1.6666667rem !important;
  }
  ._ms-pb-lg {
    padding-bottom: 2.5rem !important;
  }
  ._ms-pb-xl {
    padding-bottom: 3.3333333rem !important;
  }
  ._ms-pb-mg {
    padding-bottom: 4.1666667rem !important;
  }
  ._ms-pb-none {
    padding-bottom: 0 !important;
  }
  ._ms-pl-sm {
    padding-left: 0.4166667rem !important;
  }
  ._ms-pl-md {
    padding-left: 0.8333333rem !important;
  }
  ._ms-pl-def {
    padding-left: 1.6666667rem !important;
  }
  ._ms-pl-lg {
    padding-left: 2.5rem !important;
  }
  ._ms-pl-xl {
    padding-left: 3.3333333rem !important;
  }
  ._ms-pl-mg {
    padding-left: 4.1666667rem !important;
  }
  ._ms-pl-none {
    padding-left: 0 !important;
  }
  ._ms-p-none {
    padding: 0 !important;
  }
  ._ms-m-auto {
    margin: auto !important;
  }
  ._ms-ml-auto {
    margin-left: auto !important;
  }
  ._ms-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 760px) {
  ._md-m-sm {
    margin: 0.4166667rem !important;
  }
  ._md-m-md {
    margin: 0.8333333rem !important;
  }
  ._md-m-def {
    margin: 1.6666667rem !important;
  }
  ._md-m-lg {
    margin: 2.5rem !important;
  }
  ._md-m-xl {
    margin: 3.3333333rem !important;
  }
  ._md-m-mg {
    margin: 4.1666667rem !important;
  }
  ._md-mt-sm {
    margin-top: 0.4166667rem !important;
  }
  ._md-mt-md {
    margin-top: 0.8333333rem !important;
  }
  ._md-mt-def {
    margin-top: 1.6666667rem !important;
  }
  ._md-mt-lg {
    margin-top: 2.5rem !important;
  }
  ._md-mt-xl {
    margin-top: 3.3333333rem !important;
  }
  ._md-mt-mg {
    margin-top: 4.1666667rem !important;
  }
  ._md-mt-none {
    margin-top: 0 !important;
  }
  ._md-mr-sm {
    margin-right: 0.4166667rem !important;
  }
  ._md-mr-md {
    margin-right: 0.8333333rem !important;
  }
  ._md-mr-def {
    margin-right: 1.6666667rem !important;
  }
  ._md-mr-lg {
    margin-right: 2.5rem !important;
  }
  ._md-mr-xl {
    margin-right: 3.3333333rem !important;
  }
  ._md-mr-mg {
    margin-right: 4.1666667rem !important;
  }
  ._md-mr-none {
    margin-right: 0 !important;
  }
  ._md-mb-sm {
    margin-bottom: 0.4166667rem !important;
  }
  ._md-mb-md {
    margin-bottom: 0.8333333rem !important;
  }
  ._md-mb-def {
    margin-bottom: 1.6666667rem !important;
  }
  ._md-mb-lg {
    margin-bottom: 2.5rem !important;
  }
  ._md-mb-xl {
    margin-bottom: 3.3333333rem !important;
  }
  ._md-mb-mg {
    margin-bottom: 4.1666667rem !important;
  }
  ._md-mb-none {
    margin-bottom: 0 !important;
  }
  ._md-ml-sm {
    margin-left: 0.4166667rem !important;
  }
  ._md-ml-md {
    margin-left: 0.8333333rem !important;
  }
  ._md-ml-def {
    margin-left: 1.6666667rem !important;
  }
  ._md-ml-lg {
    margin-left: 2.5rem !important;
  }
  ._md-ml-xl {
    margin-left: 3.3333333rem !important;
  }
  ._md-ml-mg {
    margin-left: 4.1666667rem !important;
  }
  ._md-ml-none {
    margin-left: 0 !important;
  }
  ._md-m-none {
    margin: 0 !important;
  }
  ._md-p-sm {
    padding: 0.4166667rem !important;
  }
  ._md-p-md {
    padding: 0.8333333rem !important;
  }
  ._md-p-def {
    padding: 1.6666667rem !important;
  }
  ._md-p-lg {
    padding: 2.5rem !important;
  }
  ._md-p-xl {
    padding: 3.3333333rem !important;
  }
  ._md-p-mg {
    padding: 4.1666667rem !important;
  }
  ._md-pt-sm {
    padding-top: 0.4166667rem !important;
  }
  ._md-pt-md {
    padding-top: 0.8333333rem !important;
  }
  ._md-pt-def {
    padding-top: 1.6666667rem !important;
  }
  ._md-pt-lg {
    padding-top: 2.5rem !important;
  }
  ._md-pt-xl {
    padding-top: 3.3333333rem !important;
  }
  ._md-pt-mg {
    padding-top: 4.1666667rem !important;
  }
  ._md-pt-none {
    padding-top: 0 !important;
  }
  ._md-pr-sm {
    padding-right: 0.4166667rem !important;
  }
  ._md-pr-md {
    padding-right: 0.8333333rem !important;
  }
  ._md-pr-def {
    padding-right: 1.6666667rem !important;
  }
  ._md-pr-lg {
    padding-right: 2.5rem !important;
  }
  ._md-pr-xl {
    padding-right: 3.3333333rem !important;
  }
  ._md-pr-mg {
    padding-right: 4.1666667rem !important;
  }
  ._md-pr-none {
    padding-right: 0 !important;
  }
  ._md-pb-sm {
    padding-bottom: 0.4166667rem !important;
  }
  ._md-pb-md {
    padding-bottom: 0.8333333rem !important;
  }
  ._md-pb-def {
    padding-bottom: 1.6666667rem !important;
  }
  ._md-pb-lg {
    padding-bottom: 2.5rem !important;
  }
  ._md-pb-xl {
    padding-bottom: 3.3333333rem !important;
  }
  ._md-pb-mg {
    padding-bottom: 4.1666667rem !important;
  }
  ._md-pb-none {
    padding-bottom: 0 !important;
  }
  ._md-pl-sm {
    padding-left: 0.4166667rem !important;
  }
  ._md-pl-md {
    padding-left: 0.8333333rem !important;
  }
  ._md-pl-def {
    padding-left: 1.6666667rem !important;
  }
  ._md-pl-lg {
    padding-left: 2.5rem !important;
  }
  ._md-pl-xl {
    padding-left: 3.3333333rem !important;
  }
  ._md-pl-mg {
    padding-left: 4.1666667rem !important;
  }
  ._md-pl-none {
    padding-left: 0 !important;
  }
  ._md-p-none {
    padding: 0 !important;
  }
  ._md-m-auto {
    margin: auto !important;
  }
  ._md-ml-auto {
    margin-left: auto !important;
  }
  ._md-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1024px) {
  ._defp-m-sm {
    margin: 0.4166667rem !important;
  }
  ._defp-m-md {
    margin: 0.8333333rem !important;
  }
  ._defp-m-def {
    margin: 1.6666667rem !important;
  }
  ._defp-m-lg {
    margin: 2.5rem !important;
  }
  ._defp-m-xl {
    margin: 3.3333333rem !important;
  }
  ._defp-m-mg {
    margin: 4.1666667rem !important;
  }
  ._defp-mt-sm {
    margin-top: 0.4166667rem !important;
  }
  ._defp-mt-md {
    margin-top: 0.8333333rem !important;
  }
  ._defp-mt-def {
    margin-top: 1.6666667rem !important;
  }
  ._defp-mt-lg {
    margin-top: 2.5rem !important;
  }
  ._defp-mt-xl {
    margin-top: 3.3333333rem !important;
  }
  ._defp-mt-mg {
    margin-top: 4.1666667rem !important;
  }
  ._defp-mt-none {
    margin-top: 0 !important;
  }
  ._defp-mr-sm {
    margin-right: 0.4166667rem !important;
  }
  ._defp-mr-md {
    margin-right: 0.8333333rem !important;
  }
  ._defp-mr-def {
    margin-right: 1.6666667rem !important;
  }
  ._defp-mr-lg {
    margin-right: 2.5rem !important;
  }
  ._defp-mr-xl {
    margin-right: 3.3333333rem !important;
  }
  ._defp-mr-mg {
    margin-right: 4.1666667rem !important;
  }
  ._defp-mr-none {
    margin-right: 0 !important;
  }
  ._defp-mb-sm {
    margin-bottom: 0.4166667rem !important;
  }
  ._defp-mb-md {
    margin-bottom: 0.8333333rem !important;
  }
  ._defp-mb-def {
    margin-bottom: 1.6666667rem !important;
  }
  ._defp-mb-lg {
    margin-bottom: 2.5rem !important;
  }
  ._defp-mb-xl {
    margin-bottom: 3.3333333rem !important;
  }
  ._defp-mb-mg {
    margin-bottom: 4.1666667rem !important;
  }
  ._defp-mb-none {
    margin-bottom: 0 !important;
  }
  ._defp-ml-sm {
    margin-left: 0.4166667rem !important;
  }
  ._defp-ml-md {
    margin-left: 0.8333333rem !important;
  }
  ._defp-ml-def {
    margin-left: 1.6666667rem !important;
  }
  ._defp-ml-lg {
    margin-left: 2.5rem !important;
  }
  ._defp-ml-xl {
    margin-left: 3.3333333rem !important;
  }
  ._defp-ml-mg {
    margin-left: 4.1666667rem !important;
  }
  ._defp-ml-none {
    margin-left: 0 !important;
  }
  ._defp-m-none {
    margin: 0 !important;
  }
  ._defp-p-sm {
    padding: 0.4166667rem !important;
  }
  ._defp-p-md {
    padding: 0.8333333rem !important;
  }
  ._defp-p-def {
    padding: 1.6666667rem !important;
  }
  ._defp-p-lg {
    padding: 2.5rem !important;
  }
  ._defp-p-xl {
    padding: 3.3333333rem !important;
  }
  ._defp-p-mg {
    padding: 4.1666667rem !important;
  }
  ._defp-pt-sm {
    padding-top: 0.4166667rem !important;
  }
  ._defp-pt-md {
    padding-top: 0.8333333rem !important;
  }
  ._defp-pt-def {
    padding-top: 1.6666667rem !important;
  }
  ._defp-pt-lg {
    padding-top: 2.5rem !important;
  }
  ._defp-pt-xl {
    padding-top: 3.3333333rem !important;
  }
  ._defp-pt-mg {
    padding-top: 4.1666667rem !important;
  }
  ._defp-pt-none {
    padding-top: 0 !important;
  }
  ._defp-pr-sm {
    padding-right: 0.4166667rem !important;
  }
  ._defp-pr-md {
    padding-right: 0.8333333rem !important;
  }
  ._defp-pr-def {
    padding-right: 1.6666667rem !important;
  }
  ._defp-pr-lg {
    padding-right: 2.5rem !important;
  }
  ._defp-pr-xl {
    padding-right: 3.3333333rem !important;
  }
  ._defp-pr-mg {
    padding-right: 4.1666667rem !important;
  }
  ._defp-pr-none {
    padding-right: 0 !important;
  }
  ._defp-pb-sm {
    padding-bottom: 0.4166667rem !important;
  }
  ._defp-pb-md {
    padding-bottom: 0.8333333rem !important;
  }
  ._defp-pb-def {
    padding-bottom: 1.6666667rem !important;
  }
  ._defp-pb-lg {
    padding-bottom: 2.5rem !important;
  }
  ._defp-pb-xl {
    padding-bottom: 3.3333333rem !important;
  }
  ._defp-pb-mg {
    padding-bottom: 4.1666667rem !important;
  }
  ._defp-pb-none {
    padding-bottom: 0 !important;
  }
  ._defp-pl-sm {
    padding-left: 0.4166667rem !important;
  }
  ._defp-pl-md {
    padding-left: 0.8333333rem !important;
  }
  ._defp-pl-def {
    padding-left: 1.6666667rem !important;
  }
  ._defp-pl-lg {
    padding-left: 2.5rem !important;
  }
  ._defp-pl-xl {
    padding-left: 3.3333333rem !important;
  }
  ._defp-pl-mg {
    padding-left: 4.1666667rem !important;
  }
  ._defp-pl-none {
    padding-left: 0 !important;
  }
  ._defp-p-none {
    padding: 0 !important;
  }
  ._defp-m-auto {
    margin: auto !important;
  }
  ._defp-ml-auto {
    margin-left: auto !important;
  }
  ._defp-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1025px) {
  ._def-m-sm {
    margin: 0.4166667rem !important;
  }
  ._def-m-md {
    margin: 0.8333333rem !important;
  }
  ._def-m-def {
    margin: 1.6666667rem !important;
  }
  ._def-m-lg {
    margin: 2.5rem !important;
  }
  ._def-m-xl {
    margin: 3.3333333rem !important;
  }
  ._def-m-mg {
    margin: 4.1666667rem !important;
  }
  ._def-mt-sm {
    margin-top: 0.4166667rem !important;
  }
  ._def-mt-md {
    margin-top: 0.8333333rem !important;
  }
  ._def-mt-def {
    margin-top: 1.6666667rem !important;
  }
  ._def-mt-lg {
    margin-top: 2.5rem !important;
  }
  ._def-mt-xl {
    margin-top: 3.3333333rem !important;
  }
  ._def-mt-mg {
    margin-top: 4.1666667rem !important;
  }
  ._def-mt-none {
    margin-top: 0 !important;
  }
  ._def-mr-sm {
    margin-right: 0.4166667rem !important;
  }
  ._def-mr-md {
    margin-right: 0.8333333rem !important;
  }
  ._def-mr-def {
    margin-right: 1.6666667rem !important;
  }
  ._def-mr-lg {
    margin-right: 2.5rem !important;
  }
  ._def-mr-xl {
    margin-right: 3.3333333rem !important;
  }
  ._def-mr-mg {
    margin-right: 4.1666667rem !important;
  }
  ._def-mr-none {
    margin-right: 0 !important;
  }
  ._def-mb-sm {
    margin-bottom: 0.4166667rem !important;
  }
  ._def-mb-md {
    margin-bottom: 0.8333333rem !important;
  }
  ._def-mb-def {
    margin-bottom: 1.6666667rem !important;
  }
  ._def-mb-lg {
    margin-bottom: 2.5rem !important;
  }
  ._def-mb-xl {
    margin-bottom: 3.3333333rem !important;
  }
  ._def-mb-mg {
    margin-bottom: 4.1666667rem !important;
  }
  ._def-mb-none {
    margin-bottom: 0 !important;
  }
  ._def-ml-sm {
    margin-left: 0.4166667rem !important;
  }
  ._def-ml-md {
    margin-left: 0.8333333rem !important;
  }
  ._def-ml-def {
    margin-left: 1.6666667rem !important;
  }
  ._def-ml-lg {
    margin-left: 2.5rem !important;
  }
  ._def-ml-xl {
    margin-left: 3.3333333rem !important;
  }
  ._def-ml-mg {
    margin-left: 4.1666667rem !important;
  }
  ._def-ml-none {
    margin-left: 0 !important;
  }
  ._def-m-none {
    margin: 0 !important;
  }
  ._def-p-sm {
    padding: 0.4166667rem !important;
  }
  ._def-p-md {
    padding: 0.8333333rem !important;
  }
  ._def-p-def {
    padding: 1.6666667rem !important;
  }
  ._def-p-lg {
    padding: 2.5rem !important;
  }
  ._def-p-xl {
    padding: 3.3333333rem !important;
  }
  ._def-p-mg {
    padding: 4.1666667rem !important;
  }
  ._def-pt-sm {
    padding-top: 0.4166667rem !important;
  }
  ._def-pt-md {
    padding-top: 0.8333333rem !important;
  }
  ._def-pt-def {
    padding-top: 1.6666667rem !important;
  }
  ._def-pt-lg {
    padding-top: 2.5rem !important;
  }
  ._def-pt-xl {
    padding-top: 3.3333333rem !important;
  }
  ._def-pt-mg {
    padding-top: 4.1666667rem !important;
  }
  ._def-pt-none {
    padding-top: 0 !important;
  }
  ._def-pr-sm {
    padding-right: 0.4166667rem !important;
  }
  ._def-pr-md {
    padding-right: 0.8333333rem !important;
  }
  ._def-pr-def {
    padding-right: 1.6666667rem !important;
  }
  ._def-pr-lg {
    padding-right: 2.5rem !important;
  }
  ._def-pr-xl {
    padding-right: 3.3333333rem !important;
  }
  ._def-pr-mg {
    padding-right: 4.1666667rem !important;
  }
  ._def-pr-none {
    padding-right: 0 !important;
  }
  ._def-pb-sm {
    padding-bottom: 0.4166667rem !important;
  }
  ._def-pb-md {
    padding-bottom: 0.8333333rem !important;
  }
  ._def-pb-def {
    padding-bottom: 1.6666667rem !important;
  }
  ._def-pb-lg {
    padding-bottom: 2.5rem !important;
  }
  ._def-pb-xl {
    padding-bottom: 3.3333333rem !important;
  }
  ._def-pb-mg {
    padding-bottom: 4.1666667rem !important;
  }
  ._def-pb-none {
    padding-bottom: 0 !important;
  }
  ._def-pl-sm {
    padding-left: 0.4166667rem !important;
  }
  ._def-pl-md {
    padding-left: 0.8333333rem !important;
  }
  ._def-pl-def {
    padding-left: 1.6666667rem !important;
  }
  ._def-pl-lg {
    padding-left: 2.5rem !important;
  }
  ._def-pl-xl {
    padding-left: 3.3333333rem !important;
  }
  ._def-pl-mg {
    padding-left: 4.1666667rem !important;
  }
  ._def-pl-none {
    padding-left: 0 !important;
  }
  ._def-p-none {
    padding: 0 !important;
  }
  ._def-m-auto {
    margin: auto !important;
  }
  ._def-ml-auto {
    margin-left: auto !important;
  }
  ._def-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-m-sm {
    margin: 0.4166667rem !important;
  }
  ._lg-m-md {
    margin: 0.8333333rem !important;
  }
  ._lg-m-def {
    margin: 1.6666667rem !important;
  }
  ._lg-m-lg {
    margin: 2.5rem !important;
  }
  ._lg-m-xl {
    margin: 3.3333333rem !important;
  }
  ._lg-m-mg {
    margin: 4.1666667rem !important;
  }
  ._lg-mt-sm {
    margin-top: 0.4166667rem !important;
  }
  ._lg-mt-md {
    margin-top: 0.8333333rem !important;
  }
  ._lg-mt-def {
    margin-top: 1.6666667rem !important;
  }
  ._lg-mt-lg {
    margin-top: 2.5rem !important;
  }
  ._lg-mt-xl {
    margin-top: 3.3333333rem !important;
  }
  ._lg-mt-mg {
    margin-top: 4.1666667rem !important;
  }
  ._lg-mt-none {
    margin-top: 0 !important;
  }
  ._lg-mr-sm {
    margin-right: 0.4166667rem !important;
  }
  ._lg-mr-md {
    margin-right: 0.8333333rem !important;
  }
  ._lg-mr-def {
    margin-right: 1.6666667rem !important;
  }
  ._lg-mr-lg {
    margin-right: 2.5rem !important;
  }
  ._lg-mr-xl {
    margin-right: 3.3333333rem !important;
  }
  ._lg-mr-mg {
    margin-right: 4.1666667rem !important;
  }
  ._lg-mr-none {
    margin-right: 0 !important;
  }
  ._lg-mb-sm {
    margin-bottom: 0.4166667rem !important;
  }
  ._lg-mb-md {
    margin-bottom: 0.8333333rem !important;
  }
  ._lg-mb-def {
    margin-bottom: 1.6666667rem !important;
  }
  ._lg-mb-lg {
    margin-bottom: 2.5rem !important;
  }
  ._lg-mb-xl {
    margin-bottom: 3.3333333rem !important;
  }
  ._lg-mb-mg {
    margin-bottom: 4.1666667rem !important;
  }
  ._lg-mb-none {
    margin-bottom: 0 !important;
  }
  ._lg-ml-sm {
    margin-left: 0.4166667rem !important;
  }
  ._lg-ml-md {
    margin-left: 0.8333333rem !important;
  }
  ._lg-ml-def {
    margin-left: 1.6666667rem !important;
  }
  ._lg-ml-lg {
    margin-left: 2.5rem !important;
  }
  ._lg-ml-xl {
    margin-left: 3.3333333rem !important;
  }
  ._lg-ml-mg {
    margin-left: 4.1666667rem !important;
  }
  ._lg-ml-none {
    margin-left: 0 !important;
  }
  ._lg-m-none {
    margin: 0 !important;
  }
  ._lg-p-sm {
    padding: 0.4166667rem !important;
  }
  ._lg-p-md {
    padding: 0.8333333rem !important;
  }
  ._lg-p-def {
    padding: 1.6666667rem !important;
  }
  ._lg-p-lg {
    padding: 2.5rem !important;
  }
  ._lg-p-xl {
    padding: 3.3333333rem !important;
  }
  ._lg-p-mg {
    padding: 4.1666667rem !important;
  }
  ._lg-pt-sm {
    padding-top: 0.4166667rem !important;
  }
  ._lg-pt-md {
    padding-top: 0.8333333rem !important;
  }
  ._lg-pt-def {
    padding-top: 1.6666667rem !important;
  }
  ._lg-pt-lg {
    padding-top: 2.5rem !important;
  }
  ._lg-pt-xl {
    padding-top: 3.3333333rem !important;
  }
  ._lg-pt-mg {
    padding-top: 4.1666667rem !important;
  }
  ._lg-pt-none {
    padding-top: 0 !important;
  }
  ._lg-pr-sm {
    padding-right: 0.4166667rem !important;
  }
  ._lg-pr-md {
    padding-right: 0.8333333rem !important;
  }
  ._lg-pr-def {
    padding-right: 1.6666667rem !important;
  }
  ._lg-pr-lg {
    padding-right: 2.5rem !important;
  }
  ._lg-pr-xl {
    padding-right: 3.3333333rem !important;
  }
  ._lg-pr-mg {
    padding-right: 4.1666667rem !important;
  }
  ._lg-pr-none {
    padding-right: 0 !important;
  }
  ._lg-pb-sm {
    padding-bottom: 0.4166667rem !important;
  }
  ._lg-pb-md {
    padding-bottom: 0.8333333rem !important;
  }
  ._lg-pb-def {
    padding-bottom: 1.6666667rem !important;
  }
  ._lg-pb-lg {
    padding-bottom: 2.5rem !important;
  }
  ._lg-pb-xl {
    padding-bottom: 3.3333333rem !important;
  }
  ._lg-pb-mg {
    padding-bottom: 4.1666667rem !important;
  }
  ._lg-pb-none {
    padding-bottom: 0 !important;
  }
  ._lg-pl-sm {
    padding-left: 0.4166667rem !important;
  }
  ._lg-pl-md {
    padding-left: 0.8333333rem !important;
  }
  ._lg-pl-def {
    padding-left: 1.6666667rem !important;
  }
  ._lg-pl-lg {
    padding-left: 2.5rem !important;
  }
  ._lg-pl-xl {
    padding-left: 3.3333333rem !important;
  }
  ._lg-pl-mg {
    padding-left: 4.1666667rem !important;
  }
  ._lg-pl-none {
    padding-left: 0 !important;
  }
  ._lg-p-none {
    padding: 0 !important;
  }
  ._lg-m-auto {
    margin: auto !important;
  }
  ._lg-ml-auto {
    margin-left: auto !important;
  }
  ._lg-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1440px) {
  ._mg-m-sm {
    margin: 0.4166667rem !important;
  }
  ._mg-m-md {
    margin: 0.8333333rem !important;
  }
  ._mg-m-def {
    margin: 1.6666667rem !important;
  }
  ._mg-m-lg {
    margin: 2.5rem !important;
  }
  ._mg-m-xl {
    margin: 3.3333333rem !important;
  }
  ._mg-m-mg {
    margin: 4.1666667rem !important;
  }
  ._mg-mt-sm {
    margin-top: 0.4166667rem !important;
  }
  ._mg-mt-md {
    margin-top: 0.8333333rem !important;
  }
  ._mg-mt-def {
    margin-top: 1.6666667rem !important;
  }
  ._mg-mt-lg {
    margin-top: 2.5rem !important;
  }
  ._mg-mt-xl {
    margin-top: 3.3333333rem !important;
  }
  ._mg-mt-mg {
    margin-top: 4.1666667rem !important;
  }
  ._mg-mt-none {
    margin-top: 0 !important;
  }
  ._mg-mr-sm {
    margin-right: 0.4166667rem !important;
  }
  ._mg-mr-md {
    margin-right: 0.8333333rem !important;
  }
  ._mg-mr-def {
    margin-right: 1.6666667rem !important;
  }
  ._mg-mr-lg {
    margin-right: 2.5rem !important;
  }
  ._mg-mr-xl {
    margin-right: 3.3333333rem !important;
  }
  ._mg-mr-mg {
    margin-right: 4.1666667rem !important;
  }
  ._mg-mr-none {
    margin-right: 0 !important;
  }
  ._mg-mb-sm {
    margin-bottom: 0.4166667rem !important;
  }
  ._mg-mb-md {
    margin-bottom: 0.8333333rem !important;
  }
  ._mg-mb-def {
    margin-bottom: 1.6666667rem !important;
  }
  ._mg-mb-lg {
    margin-bottom: 2.5rem !important;
  }
  ._mg-mb-xl {
    margin-bottom: 3.3333333rem !important;
  }
  ._mg-mb-mg {
    margin-bottom: 4.1666667rem !important;
  }
  ._mg-mb-none {
    margin-bottom: 0 !important;
  }
  ._mg-ml-sm {
    margin-left: 0.4166667rem !important;
  }
  ._mg-ml-md {
    margin-left: 0.8333333rem !important;
  }
  ._mg-ml-def {
    margin-left: 1.6666667rem !important;
  }
  ._mg-ml-lg {
    margin-left: 2.5rem !important;
  }
  ._mg-ml-xl {
    margin-left: 3.3333333rem !important;
  }
  ._mg-ml-mg {
    margin-left: 4.1666667rem !important;
  }
  ._mg-ml-none {
    margin-left: 0 !important;
  }
  ._mg-m-none {
    margin: 0 !important;
  }
  ._mg-p-sm {
    padding: 0.4166667rem !important;
  }
  ._mg-p-md {
    padding: 0.8333333rem !important;
  }
  ._mg-p-def {
    padding: 1.6666667rem !important;
  }
  ._mg-p-lg {
    padding: 2.5rem !important;
  }
  ._mg-p-xl {
    padding: 3.3333333rem !important;
  }
  ._mg-p-mg {
    padding: 4.1666667rem !important;
  }
  ._mg-pt-sm {
    padding-top: 0.4166667rem !important;
  }
  ._mg-pt-md {
    padding-top: 0.8333333rem !important;
  }
  ._mg-pt-def {
    padding-top: 1.6666667rem !important;
  }
  ._mg-pt-lg {
    padding-top: 2.5rem !important;
  }
  ._mg-pt-xl {
    padding-top: 3.3333333rem !important;
  }
  ._mg-pt-mg {
    padding-top: 4.1666667rem !important;
  }
  ._mg-pt-none {
    padding-top: 0 !important;
  }
  ._mg-pr-sm {
    padding-right: 0.4166667rem !important;
  }
  ._mg-pr-md {
    padding-right: 0.8333333rem !important;
  }
  ._mg-pr-def {
    padding-right: 1.6666667rem !important;
  }
  ._mg-pr-lg {
    padding-right: 2.5rem !important;
  }
  ._mg-pr-xl {
    padding-right: 3.3333333rem !important;
  }
  ._mg-pr-mg {
    padding-right: 4.1666667rem !important;
  }
  ._mg-pr-none {
    padding-right: 0 !important;
  }
  ._mg-pb-sm {
    padding-bottom: 0.4166667rem !important;
  }
  ._mg-pb-md {
    padding-bottom: 0.8333333rem !important;
  }
  ._mg-pb-def {
    padding-bottom: 1.6666667rem !important;
  }
  ._mg-pb-lg {
    padding-bottom: 2.5rem !important;
  }
  ._mg-pb-xl {
    padding-bottom: 3.3333333rem !important;
  }
  ._mg-pb-mg {
    padding-bottom: 4.1666667rem !important;
  }
  ._mg-pb-none {
    padding-bottom: 0 !important;
  }
  ._mg-pl-sm {
    padding-left: 0.4166667rem !important;
  }
  ._mg-pl-md {
    padding-left: 0.8333333rem !important;
  }
  ._mg-pl-def {
    padding-left: 1.6666667rem !important;
  }
  ._mg-pl-lg {
    padding-left: 2.5rem !important;
  }
  ._mg-pl-xl {
    padding-left: 3.3333333rem !important;
  }
  ._mg-pl-mg {
    padding-left: 4.1666667rem !important;
  }
  ._mg-pl-none {
    padding-left: 0 !important;
  }
  ._mg-p-none {
    padding: 0 !important;
  }
  ._mg-m-auto {
    margin: auto !important;
  }
  ._mg-ml-auto {
    margin-left: auto !important;
  }
  ._mg-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1560px) {
  ._xxl-m-sm {
    margin: 0.4166667rem !important;
  }
  ._xxl-m-md {
    margin: 0.8333333rem !important;
  }
  ._xxl-m-def {
    margin: 1.6666667rem !important;
  }
  ._xxl-m-lg {
    margin: 2.5rem !important;
  }
  ._xxl-m-xl {
    margin: 3.3333333rem !important;
  }
  ._xxl-m-mg {
    margin: 4.1666667rem !important;
  }
  ._xxl-mt-sm {
    margin-top: 0.4166667rem !important;
  }
  ._xxl-mt-md {
    margin-top: 0.8333333rem !important;
  }
  ._xxl-mt-def {
    margin-top: 1.6666667rem !important;
  }
  ._xxl-mt-lg {
    margin-top: 2.5rem !important;
  }
  ._xxl-mt-xl {
    margin-top: 3.3333333rem !important;
  }
  ._xxl-mt-mg {
    margin-top: 4.1666667rem !important;
  }
  ._xxl-mt-none {
    margin-top: 0 !important;
  }
  ._xxl-mr-sm {
    margin-right: 0.4166667rem !important;
  }
  ._xxl-mr-md {
    margin-right: 0.8333333rem !important;
  }
  ._xxl-mr-def {
    margin-right: 1.6666667rem !important;
  }
  ._xxl-mr-lg {
    margin-right: 2.5rem !important;
  }
  ._xxl-mr-xl {
    margin-right: 3.3333333rem !important;
  }
  ._xxl-mr-mg {
    margin-right: 4.1666667rem !important;
  }
  ._xxl-mr-none {
    margin-right: 0 !important;
  }
  ._xxl-mb-sm {
    margin-bottom: 0.4166667rem !important;
  }
  ._xxl-mb-md {
    margin-bottom: 0.8333333rem !important;
  }
  ._xxl-mb-def {
    margin-bottom: 1.6666667rem !important;
  }
  ._xxl-mb-lg {
    margin-bottom: 2.5rem !important;
  }
  ._xxl-mb-xl {
    margin-bottom: 3.3333333rem !important;
  }
  ._xxl-mb-mg {
    margin-bottom: 4.1666667rem !important;
  }
  ._xxl-mb-none {
    margin-bottom: 0 !important;
  }
  ._xxl-ml-sm {
    margin-left: 0.4166667rem !important;
  }
  ._xxl-ml-md {
    margin-left: 0.8333333rem !important;
  }
  ._xxl-ml-def {
    margin-left: 1.6666667rem !important;
  }
  ._xxl-ml-lg {
    margin-left: 2.5rem !important;
  }
  ._xxl-ml-xl {
    margin-left: 3.3333333rem !important;
  }
  ._xxl-ml-mg {
    margin-left: 4.1666667rem !important;
  }
  ._xxl-ml-none {
    margin-left: 0 !important;
  }
  ._xxl-m-none {
    margin: 0 !important;
  }
  ._xxl-p-sm {
    padding: 0.4166667rem !important;
  }
  ._xxl-p-md {
    padding: 0.8333333rem !important;
  }
  ._xxl-p-def {
    padding: 1.6666667rem !important;
  }
  ._xxl-p-lg {
    padding: 2.5rem !important;
  }
  ._xxl-p-xl {
    padding: 3.3333333rem !important;
  }
  ._xxl-p-mg {
    padding: 4.1666667rem !important;
  }
  ._xxl-pt-sm {
    padding-top: 0.4166667rem !important;
  }
  ._xxl-pt-md {
    padding-top: 0.8333333rem !important;
  }
  ._xxl-pt-def {
    padding-top: 1.6666667rem !important;
  }
  ._xxl-pt-lg {
    padding-top: 2.5rem !important;
  }
  ._xxl-pt-xl {
    padding-top: 3.3333333rem !important;
  }
  ._xxl-pt-mg {
    padding-top: 4.1666667rem !important;
  }
  ._xxl-pt-none {
    padding-top: 0 !important;
  }
  ._xxl-pr-sm {
    padding-right: 0.4166667rem !important;
  }
  ._xxl-pr-md {
    padding-right: 0.8333333rem !important;
  }
  ._xxl-pr-def {
    padding-right: 1.6666667rem !important;
  }
  ._xxl-pr-lg {
    padding-right: 2.5rem !important;
  }
  ._xxl-pr-xl {
    padding-right: 3.3333333rem !important;
  }
  ._xxl-pr-mg {
    padding-right: 4.1666667rem !important;
  }
  ._xxl-pr-none {
    padding-right: 0 !important;
  }
  ._xxl-pb-sm {
    padding-bottom: 0.4166667rem !important;
  }
  ._xxl-pb-md {
    padding-bottom: 0.8333333rem !important;
  }
  ._xxl-pb-def {
    padding-bottom: 1.6666667rem !important;
  }
  ._xxl-pb-lg {
    padding-bottom: 2.5rem !important;
  }
  ._xxl-pb-xl {
    padding-bottom: 3.3333333rem !important;
  }
  ._xxl-pb-mg {
    padding-bottom: 4.1666667rem !important;
  }
  ._xxl-pb-none {
    padding-bottom: 0 !important;
  }
  ._xxl-pl-sm {
    padding-left: 0.4166667rem !important;
  }
  ._xxl-pl-md {
    padding-left: 0.8333333rem !important;
  }
  ._xxl-pl-def {
    padding-left: 1.6666667rem !important;
  }
  ._xxl-pl-lg {
    padding-left: 2.5rem !important;
  }
  ._xxl-pl-xl {
    padding-left: 3.3333333rem !important;
  }
  ._xxl-pl-mg {
    padding-left: 4.1666667rem !important;
  }
  ._xxl-pl-none {
    padding-left: 0 !important;
  }
  ._xxl-p-none {
    padding: 0 !important;
  }
  ._xxl-m-auto {
    margin: auto !important;
  }
  ._xxl-ml-auto {
    margin-left: auto !important;
  }
  ._xxl-mr-auto {
    margin-right: auto !important;
  }
}

.catalog {
  display: flex;
}

.catalog-grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0;
  margin-bottom: 60px;
  justify-content: center;
}

@media only screen and (min-width: 1025px) {
  .catalog-grid {
    justify-content: flex-start;
    width: calc(100% - 280px);
    padding-left: 30px;
  }
}

.js-acordeon-button {
  cursor: pointer;
  user-select: none;
}

.one-click-buy {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 18px;
  color: #33363e;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  margin-top: 30px;
  transition: color 0.3s ease;
}

.one-click-buy span {
  border-bottom: 1px dotted #33363e;
}

.one-click-buy:hover {
  color: #fe5400;
}

.bg_decor {
  font-family: 'Playfair Display';
  color: #bde0f1;
  position: absolute;
  font-size: 100px;
  opacity: 0.3;
  font-style: italic;
  right: 38px;
  bottom: 2px;
}

.js-filter-handler {
  display: flex;
}

@media only screen and (min-width: 1025px) {
  .js-filter-handler {
    display: none;
  }
}

.search-form {
  opacity: 0;
  visibility: hidden;
  transform: translateX(300px);
  position: fixed;
  top: 60px;
  left: 100%;
  transition: opacity 0.3s 0s ease, transform 0.3s 0.1s ease-out, visibility 0s 0.6s ease;
}

@media only screen and (min-width: 1025px) {
  .search-form {
    left: calc(220px + 5%);
    top: 10px;
    position: absolute;
  }
}

.search-form .form__group {
  margin: 0;
}

.search-form .control-holder--text {
  margin-bottom: 0;
}

.search-form input {
  padding-left: 42px;
}

.search-form__btn {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: transparent;
  border: none;
  outline: none;
  right: 64px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.search-form__btn:hover {
  color: #fe5400;
}

.search-form__decor {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 2px;
  bottom: 0;
  margin: auto 0;
  left: 14px;
  z-index: 5;
}

.search-form__decor svg {
  width: 24px;
  height: 24px;
  fill: #445568;
}

.search-form__close-btn {
  position: absolute;
  right: 14px;
  top: 2px;
  bottom: 0;
  margin: auto 0;
  width: 26px;
  height: 26px;
  z-index: 5;
  cursor: pointer;
}

.search-form__close-btn svg {
  width: 22px;
  height: 22px;
  fill: #445568;
  transition: fill 0.3s ease;
}

.search-form__close-btn:hover svg {
  fill: #fe5400;
}

.search-form.is-active {
  visibility: visible;
  opacity: 1;
  left: 0;
  transform: translateX(0);
  transition: opacity 0.8s 0.2s ease, transform 0.3s 0.1s ease-out, visibility 0s 0s ease;
}

@media only screen and (min-width: 1025px) {
  .search-form.is-active {
    left: calc(220px + 5%);
  }
}

.header-search-button {
  width: 36px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  margin-left: auto;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0s 0.3s ease;
}

@media only screen and (min-width: 1025px) {
  .header-search-button {
    width: 38px;
    height: 38px;
  }
}

.header-search-button.is-active {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0.1s ease, visibility 0s 0s ease;
}

.header-search-button svg {
  width: 23px;
  height: 24px;
  fill: #445568;
}

@media only screen and (min-width: 1025px) {
  .header-search-button svg {
    width: 25px;
    height: 26px;
  }
}

.header-search-button:hover svg {
  fill: #fe5400;
}

.main-nav {
  margin: 0 0 0 5%;
  padding: 0;
  width: calc(95% - 280px);
  list-style-type: none;
  list-style-image: url(data:0);
  display: none;
  align-items: center;
}

@media only screen and (min-width: 1025px) {
  .main-nav {
    display: flex;
  }
}

.main-nav__item {
  padding: 0 12px;
  height: 50px;
  display: flex;
  transition: opacity 0.3s, transform 0.3s ease;
  white-space: nowrap;
}

.main-nav__link {
  color: #445568;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  font-size: 14px;
  font-family: 'Museo Sans';
  font-weight: 600;
}

.main-nav__link:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #4da2cb;
  content: "";
  display: none;
}

.main-nav__link.is-active {
  pointer-events: none;
  color: #33363e;
}

.main-nav__link.is-active:after {
  display: block;
}

.main-nav.js-disabled .main-nav__item {
  opacity: 0;
  transition: opacity 0.6s, transform 0.5s ease;
  transform: rotateY(-45deg);
}

.is-disabled {
  display: none;
}

.nav-halper {
  display: none;
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.nav-halper__burger {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  transform: rotate(90deg);
  transition: background-color 0.3s ease;
}

.nav-halper__burger svg {
  width: 25px;
  height: 25px;
  fill: #33363e;
  transition: fill 0.3s ease;
}

.nav-halper__burger:hover svg {
  fill: #fe5400;
}

.nav-halper__burger.is-active {
  background-color: #ff7e2a;
}

.nav-halper__burger.is-active svg {
  fill: #fff;
}

.nav-halper__items {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #ff7e2a;
  transform-origin: 100% 0%;
  transform: scale(0.6);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.nav-halper__items.is-active {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.nav-halper .main-nav__link {
  color: #fff;
}

.nav-halper .main-nav__link.is-active:after {
  top: auto;
  bottom: 15px;
  background-color: #fff;
}

.nav-halper.is-active {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .nav-halper.is-active {
    display: block;
  }
}

.menu-button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  border: 2px solid #fe5400;
}

.menu-button .button__content {
  color: #000;
}

.header-widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin: 0 8px;
}

@media only screen and (min-width: 640px) {
  .header-widget {
    margin: 0 10px;
  }
}

@media only screen and (min-width: 1560px) {
  .header-widget {
    margin: 0 20px;
  }
}

.header-widget:nth-child(1) .header-widget__image svg {
  width: 26px;
  height: 32px;
}

.header-widget:nth-child(2) .header-widget {
  margin: 0px 3px;
}

@media only screen and (min-width: 640px) {
  .header-widget:nth-child(2) .header-widget {
    margin: 0 10px;
  }
}

.header-widget--cart {
  margin-right: 0;
}

.header-widget__image {
  margin-bottom: 0;
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.header-widget__image--human {
  text-align: center;
}

.header-widget__image--human svg {
  stroke: transparent !important;
  fill: #445568;
}

.header-widget__image--cart svg {
  width: 34px;
  height: 28px;
  margin-left: -2px;
}

@media only screen and (min-width: 1025px) {
  .header-widget__image--cart svg {
    margin-left: -10px;
  }
}

.header-widget__image--favorites svg {
  width: 32px;
  height: 34px;
  fill: transparent !important;
  stroke: #445568;
  margin-left: -4px;
  stroke-width: 3px;
}

@media only screen and (min-width: 1025px) {
  .header-widget__image--favorites svg {
    margin-left: -10px;
  }
}

@media only screen and (min-width: 1025px) {
  .header-widget__image {
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 1560px) {
  .header-widget__image {
    height: 34px;
    width: 34px;
    margin-bottom: 18px;
  }
}

.header-widget__image svg {
  fill: #445568;
}

.header-widget__num {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #a9acbd;
  font-size: 12px;
  font-family: 'Museo Sans';
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  top: -7px;
  right: -10px;
  color: #fff;
  transition: background-color 0.3s ease;
}

@media only screen and (min-width: 1025px) {
  .header-widget__num {
    width: 30px;
    height: 30px;
    font-size: 16px;
    right: -18px;
  }
}

.header-widget__text {
  color: #a9acbd;
  text-transform: lowercase;
  font-weight: 100;
  font-family: 'Museo Sans';
  font-size: 14px;
  display: none;
  transition: color 0.3s ease;
}

@media only screen and (min-width: 1025px) {
  .header-widget__text {
    display: block;
  }
}

.header-widget:hover .header-widget__text {
  color: #000;
}

.header-widget:hover .header-widget__num {
  background-color: #fe5400;
}

.phones-block {
  display: flex;
  position: relative;
}

.phones-block__resp-content {
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 260px;
  opacity: 0;
  visibility: hidden;
  transform-origin: top;
  transform: scale(0.87);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s 0.3s ease;
}

@media only screen and (min-width: 1560px) {
  .phones-block__resp-content {
    position: relative;
    top: 0;
    width: auto;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

.phones-block__resp-content.is-active {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: opacity 0.3s 0.1s ease, transform 0.3s 0.1s ease, visibility 0s ease;
}

.phones-block__resp-btn {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
}

@media only screen and (min-width: 1560px) {
  .phones-block__resp-btn {
    display: none;
  }
}

.phones-block__resp-btn svg {
  width: 14px;
  height: 14px;
  fill: #33363e;
}

.phones-block__resp-btn:hover svg {
  fill: #fe5400;
}

.phones-block__item {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
  color: #33363e;
  margin-right: 20px;
  position: relative;
  text-decoration: none;
  display: inline-block;
  font-family: 'Museo Sans';
  background-color: #fff;
}

.phones-block__item svg {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  fill: #ae3ddd;
}

.phones-block__item--viber {
  padding-left: 22px;
}

.addres-block {
  margin-bottom: 16px;
}

.addres-block__group {
  font-size: 14px;
  font-weight: 400;
  color: #33363e;
  font-family: 'Museo Sans';
}

.graph-work__group {
  color: #4da2cb;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Museo Sans';
  margin-right: 10px;
}

.mm-hasnavbar-top-2 .mm-panels, .mm-navbar-top-3 {
  top: 80px;
}

@media only screen and (min-width: 640px) {
  .mm-hasnavbar-top-2 .mm-panels, .mm-navbar-top-3 {
    top: 110px;
  }
}

.mm-navbar-top-1 {
  height: 80px;
}

@media only screen and (min-width: 640px) {
  .mm-navbar-top-1 {
    height: 110px;
  }
}

.menu_logo {
  padding: 0;
  height: 80px;
}

@media only screen and (min-width: 640px) {
  .menu_logo {
    height: 110px;
  }
}

.menu_logo img {
  height: 80px;
}

@media only screen and (min-width: 640px) {
  .menu_logo img {
    height: 110px;
  }
}

.web-service {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  min-width: 179px;
}

.web-service__text {
  font-weight: 100;
  font-size: 12px;
  letter-spacing: 0.17em;
}

.wezom {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 20px;
}

.wezom__logo-holder {
  width: 35px;
  height: 35px;
  background-color: #f22625;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-right: 12px;
  transition: border-radius 0.5s ease, margin-right 0.5s ease;
}

.wezom__logo-holder svg {
  width: 28px;
  height: 10px;
  fill: #fff;
}

.wezom__text {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  transition: color 0.3s ease;
}

.wezom:hover .wezom__logo-holder {
  border-radius: 100%;
  margin-right: 6px;
}

.wezom:hover .wezom__text {
  color: #f22625;
}

.footer-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-list__item {
  margin-bottom: 14px;
}

.footer-list__link {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  color: #445568;
  text-decoration: none;
}

@media only screen and (min-width: 1280px) {
  .footer-list__link {
    font-size: 12px;
  }
}

.footer-list--categorys {
  columns: 2;
}

.pays-block {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 1280px) {
  .pays-block {
    flex-direction: row;
  }
}

.pays-block__text {
  margin-right: 0;
  font-size: 16px;
  white-space: nowrap;
  color: #445568;
  font-weight: 700;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1025px) {
  .pays-block__text {
    font-size: 14px;
    margin-right: 15px;
  }
}

@media only screen and (min-width: 1280px) {
  .pays-block__text {
    font-size: 18px;
    margin-bottom: 0;
  }
}

.pays-block__items {
  display: flex;
  align-items: center;
}

.pays-block__item {
  margin: 0 15px;
}

.pays-block__item img {
  max-height: 40px;
  max-width: 106px;
}

.news-item {
  width: 100%;
  position: relative;
  height: auto;
  text-decoration: none;
}

@media only screen and (min-width: 640px) {
  .news-item {
    height: 245px;
  }
}

.news-item--main {
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 640px) {
  .news-item--main {
    height: 390px;
  }
}

@media only screen and (min-width: 1024px) {
  .news-item--main {
    width: 50%;
    height: 490px;
  }
}

.news-item--main .news-item__image {
  width: 100%;
  left: 0;
  height: 240px;
}

@media only screen and (min-width: 640px) {
  .news-item--main .news-item__image {
    height: 100%;
  }
}

.news-item--main .news-item__descr {
  width: 100%;
  padding: 10px;
  position: relative;
  left: 0;
  bottom: 0;
  height: auto;
  top: auto;
  background-color: #fff;
  height: auto;
  z-index: 3;
}

@media only screen and (min-width: 640px) {
  .news-item--main .news-item__descr {
    padding: 50px;
    background-color: transparent;
    position: absolute;
  }
}

.news-item--main .news-item__text {
  color: #000;
  font-size: 18px;
  margin-bottom: 18px;
  line-height: 100%;
}

@media only screen and (min-width: 640px) {
  .news-item--main .news-item__text {
    color: #fff;
    font-size: 30px;
  }
}

@media only screen and (min-width: 760px) {
  .news-item--main .news-item__text {
    font-size: 20px;
    margin-bottom: 38px;
  }
}

@media only screen and (min-width: 1280px) {
  .news-item--main .news-item__text {
    font-size: 36px;
  }
}

.news-item .button {
  display: none;
}

@media only screen and (min-width: 640px) {
  .news-item .button {
    display: inline-block;
  }
}

.news-item__descr {
  width: 100%;
  position: relative;
  top: 0;
  height: auto;
  padding: 10px;
}

@media only screen and (min-width: 640px) {
  .news-item__descr {
    position: absolute;
    height: 100%;
    width: 50%;
    padding: 40px 30px;
  }
}

.news-item__data {
  font-weight: 100;
  letter-spacing: 0.2em;
  color: #fe5400;
  font-size: 14px;
  margin-bottom: 12px;
}

.news-item__text {
  color: #33363e;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-decoration: none;
}

@media only screen and (min-width: 1280px) {
  .news-item__text {
    font-size: 20px;
    line-height: 24px;
  }
}

.news-item__text i {
  font-weight: normal;
  font-family: 'Playfair Display';
}

.news-item__image {
  position: relative;
  top: 0;
  width: 100%;
  overflow: hidden;
  height: 240px;
}

@media only screen and (min-width: 640px) {
  .news-item__image {
    width: 50%;
    height: 100%;
    position: absolute;
  }
}

.news-item__image:after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.news-item__image-holder {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  transition: transform 0.6s ease;
  transform: scale(1);
}

.news-item:hover .news-item__image:after {
  opacity: 0.2;
}

.news-item:hover .news-item__image-holder {
  transform: scale(1.1);
}

.news-item-group {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1024px) {
  .news-item-group {
    flex-direction: row;
  }
}

.news-item-double {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .news-item-double {
    width: 50%;
  }
}

.news-item-double .news-item:nth-child(1) .news-item__image {
  left: 0;
}

.news-item-double .news-item:nth-child(1) .news-item__descr {
  right: 0;
  background-color: #ffffff;
}

.news-item-double .news-item:nth-child(2) .news-item__image {
  right: 0;
}

.news-item-double .news-item:nth-child(2) .news-item__descr {
  left: 0;
  background-color: #eef7fb;
}

.inner-news__image {
  margin-bottom: 30px;
}

._mgb40 {
  margin-bottom: 40px;
}

._mgb50 {
  margin-bottom: 50px;
}

.map {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.contacts {
  margin-bottom: 70px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contacts textarea {
  min-height: 48px !important;
  height: 50px;
}

.contacts__title {
  padding-bottom: 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  color: #445568;
  padding-top: 4px;
  border-bottom: 1px solid #bde0f1;
  margin-bottom: 20px;
  height: 41px;
  width: 100%;
}

@media only screen and (min-width: 760px) {
  .contacts__title {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1025px) {
  .contacts__title {
    margin-bottom: 60px;
    font-size: 24px;
  }
}

.contacts__numbers-block {
  display: flex;
  justify-content: space-between;
  padding-right: 0;
  flex-direction: column;
}

@media only screen and (min-width: 640px) {
  .contacts__numbers-block {
    flex-direction: row;
  }
}

@media only screen and (min-width: 760px) {
  .contacts__numbers-block {
    padding-right: 18%;
  }
}

.contacts__numbers-group {
  margin-bottom: 20px;
}

@Include media($def) {
  .contacts__numbers-group {
    margin-bottom: 60px;
  }
}

.contacts__number {
  font-size: 18px;
  font-weight: 500;
  color: #33363e;
  margin-bottom: 8px;
  text-decoration: none;
  display: inline-block;
}

@media only screen and (min-width: 760px) {
  .contacts__number {
    font-size: 24px;
  }
}

.contacts__number svg {
  width: 20px;
  height: 22px;
  fill: #ae3ddd;
}

.contacts__numbers-title {
  font-size: 16px;
  color: #33363e;
  font-weight: 100;
  margin-bottom: 24px;
}

.contacts__times {
  color: #4da2cb;
  font-weight: 100;
  font-size: 16px;
  margin-bottom: 18px;
}

.contacts__mail {
  color: #33363e;
  font-size: 16px;
  font-weight: 100;
  margin-bottom: 56px;
}

.contacts__mail a {
  text-decoration: none;
  color: inherit;
}

.contacts__mail a:hover {
  color: #fe5400;
}

.contacts__addres {
  font-weight: 100;
  font-size: 16px;
  color: #33363e;
  margin-bottom: 16px;
}

.contacts__info {
  width: 100%;
  padding-right: 0;
  border-right: 0;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1280px) {
  .contacts__info {
    width: 50%;
    padding-right: 5.5%;
    margin-bottom: 0;
    border-right: 1px solid #bde0f1;
  }
}

.contacts__map {
  width: 100%;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}

@media only screen and (min-width: 1280px) {
  .contacts__map {
    width: 50%;
    padding-left: 5.5%;
  }
}

.contacts__form-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.contacts__form-group .form__group {
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .contacts__form-group .form__group {
    width: calc(50% - 10px);
  }
}

.contacts__map-wrapper {
  height: calc(100% - 101px);
  width: 100%;
  position: relative;
  min-height: 300px;
}

.contacts__text {
  font-weight: 100;
  font-size: 16px;
  display: block;
  color: #445568;
  margin-bottom: 20px;
}

.ninja {
  color: black;
  visibility: hidden;
}

.page404__top-row {
  padding-bottom: 30px;
  padding-top: 10px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 760px) {
  .page404__top-row {
    padding-bottom: 80px;
  }
}

.page404__text {
  max-width: 500px;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 100;
  font-size: 16px;
  line-height: 22px;
  color: #445568;
  text-align: center;
}

@media only screen and (min-width: 760px) {
  .page404__text {
    margin-bottom: 130px;
    font-size: 18px;
    line-height: 24px;
  }
}

.page404__subtext {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  color: #445568;
  text-align: center;
}

@media only screen and (min-width: 760px) {
  .page404__subtext {
    margin-bottom: 70px;
    font-size: 30px;
    line-height: 36px;
  }
}

.page404__error {
  font-weight: 700;
  font-size: 26px;
  color: #445568;
  text-align: center;
  margin-bottom: 26px;
}

@media only screen and (min-width: 760px) {
  .page404__error {
    font-size: 36px;
  }
}

.page404__bg {
  color: #ecf6fb;
  font-size: 250px;
  font-family: 'Playfair Display';
  font-style: italic;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  margin: 0 auto;
  text-align: center;
  z-index: -1;
}

@media only screen and (min-width: 760px) {
  .page404__bg {
    font-size: 500px;
    left: 60px;
  }
}

.prev-next-item {
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

@media only screen and (min-width: 640px) {
  .prev-next-item {
    height: 60px;
    width: auto;
  }
}

@media only screen and (min-width: 1025px) {
  .prev-next-item {
    height: 100px;
  }
}

.prev-next-item__link {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #445568;
  font-size: 12px;
}

@media only screen and (min-width: 1025px) {
  .prev-next-item__link {
    font-size: 14px;
  }
}

.prev-next-item__link svg {
  width: 14px;
  height: 22px;
  fill: #a9acbd;
}

.prev-next-item__link:hover svg {
  filL: #bde0f1;
}

.prev-next-item--center {
  padding: 0 25px;
  border-left: 1px solid #bde0f1;
  border-right: 1px solid #bde0f1;
  max-width: 280px;
  margin: 0 auto;
}

@media only screen and (min-width: 1025px) {
  .prev-next-item--center {
    padding: 0 50px;
  }
}

.prev-next-item--prev .prev-next-item__link svg {
  margin-right: 12px;
}

.prev-next-item--next .prev-next-item__link svg {
  margin-left: 12px;
}

.prev-next-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 640px) {
  .prev-next-block {
    flex-direction: row;
  }
}

.prev-next-block__column {
  width: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

@media only screen and (min-width: 640px) {
  .prev-next-block__column {
    width: 33%;
    flex-basis: 33%;
    max-width: 33%;
  }
}

.add-this-widget-wrapper {
  margin-bottom: 52px;
}

.page-subtitle {
  position: relative;
  z-index: 2;
  color: #445568;
  font-size: 22px;
  line-height: 100%;
  font-weight: 700;
  text-align: center;
}

@media only screen and (min-width: 480px) {
  .page-subtitle {
    font-size: 28px;
  }
}

@media only screen and (min-width: 1024px) {
  .page-subtitle {
    font-size: 36px;
  }
}

.page-subtitle i {
  display: inline-block;
  font-weight: normal;
  font-family: 'Playfair Display';
}

._mgb36 {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1024px) {
  ._mgb36 {
    margin-bottom: 36px;
  }
}

.seo-text {
  padding: 60px 0;
}

.more-btn {
  display: inline-flex;
  padding: 10px;
  text-decoration: none;
  position: relative;
  z-index: 15;
}

.more-btn__text {
  color: #445568;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  transition: color 0.3s ease;
}

.more-btn__icon {
  fill: #fff;
  width: 20px;
  height: 14px;
  margin-left: 12px;
  transition: margin-left 0.3s ease;
}

.more-btn:hover .more-btn__text {
  color: #fe5400;
}

.more-btn:hover .more-btn__icon {
  margin-left: 20px;
}

.index-categorys {
  margin-bottom: 50px;
  position: relative;
}

.index-categorys__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.index-categorys__item {
  width: 190px;
  height: 140px;
  position: relative;
  margin: 5px;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.index-categorys__item:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.2;
  background-color: #000;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.index-categorys__item:hover:after {
  opacity: 0.3;
}

.index-categorys__item:hover .index-categorys__item-text {
  color: #fe5400;
}

.index-categorys__item-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 190px;
  height: 140px;
}

.index-categorys__item-text {
  position: absolute;
  padding: 20px;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 20px;
  transition: color 0.3s ease;
  text-transform: uppercase;
  z-index: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.advantages {
  margin-bottom: 24px;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .advantages {
    margin-bottom: 54px;
  }
}

.advantages:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 63%;
  background-color: #ecf6fb;
  content: "";
}

.advantages-grid {
  display: flex;
  position: relative;
  z-index: 10;
  flex-wrap: wrap;
}

.advantages-item {
  width: 100%;
  padding-bottom: 14px;
}

@media only screen and (min-width: 640px) {
  .advantages-item {
    width: 50%;
  }
}

@media only screen and (min-width: 760px) {
  .advantages-item {
    width: 33.33%;
  }
}

@media only screen and (min-width: 1440px) {
  .advantages-item {
    width: 16.66%;
  }
}

.advantages-item__image {
  display: flex;
  background-color: #83d7da;
  width: 130px;
  height: 94px;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media only screen and (min-width: 1025px) {
  .advantages-item__image {
    margin-bottom: 36px;
  }
}

.advantages-item__image svg {
  width: 60px;
  height: 58px;
  fill: #fff;
}

.advantages-item__image:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 34px -4px rgba(0, 0, 0, 0.4);
  content: "";
  border-radius: 22px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.advantages-item__title {
  color: #445568;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 12px;
  border-bottom: 1px solid #d4ebf6;
  text-align: center;
  padding: 0 30px 10px 30px;
  position: relative;
}

@media only screen and (min-width: 1025px) {
  .advantages-item__title {
    padding: 0 30px 20px 30px;
    margin-bottom: 28px;
  }
}

.advantages-item__title i {
  display: block;
  font-weight: normal;
  font-family: 'Playfair Display';
}

.advantages-item__title:after {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 2px;
  background-color: #bde0f1;
  content: "";
  transform: rotateX(90deg);
  transition: transform 0.3s ease;
}

.advantages-item__text {
  text-align: center;
  padding: 0 30px;
  font-weight: 100;
  font-size: 14px;
  line-height: 18px;
  color: #4da2cb;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.advantages-item:hover .advantages-item__image:after {
  opacity: 1;
}

.advantages-item:hover .advantages-item__title:after {
  transform: scale(1);
}

.advantages-item:hover .advantages-item__text {
  color: #000;
}

.items-slider .swiper-container {
  width: calc(100% + 20px);
  padding-left: 10px;
  margin-left: -10px;
}

.items-slider .item {
  margin-top: 10px;
}

.items-slider__item {
  width: 320px;
}

.items-slider-block {
  padding-top: 20px;
  padding-bottom: 22px;
}

@media only screen and (min-width: 1024px) {
  .items-slider-block {
    padding-top: 40px;
    padding-bottom: 42px;
  }
}

.items-slider-block__content {
  position: relative;
}

.items-slider-block .more-btn {
  position: relative;
  right: 0;
  top: 0;
  padding-right: 0 !important;
}

@media only screen and (min-width: 1024px) {
  .items-slider-block .more-btn {
    position: absolute;
  }
}

.items-slider-block .more-btn svg {
  fill: #bde0f1;
}

._mgb40 {
  margin-bottom: 40px;
}

.search-sort .ui-selectmenu-button {
  width: 280px;
}

@media only screen and (min-width: 760px) {
  .search-sort .ui-selectmenu-button {
    width: 340px;
  }
}

.search-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
  align-items: flex-start;
}

.search-grid .item {
  margin-bottom: 20px;
}

._mgb60 {
  margin-bottom: 30px;
}

@media only screen and (min-width: 760px) {
  ._mgb60 {
    margin-bottom: 60px;
  }
}

.order .items-slider-tabs__button {
  font-size: 24px;
}

.favorites-item {
  position: relative;
}

.favorites-item__remove {
  width: 52px;
  height: 52px;
  background-color: rgba(241, 250, 251, 0.1);
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  z-index: 26;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.favorites-item__remove svg {
  width: 22px;
  height: 22px;
  fill: #bde0f1;
  transition: fill 0.3s ease;
}

.favorites-item__remove:hover svg {
  fill: #fe5400;
}

.lk__nav {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.lk__nav-item {
  display: flex;
  align-items: center;
  height: 46px;
  border-bottom: 1px solid #bde0f1;
  text-transform: uppercase;
  text-decoration: none;
  color: #445568;
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
  position: relative;
  transition: color 0.3s ease, border 0.3s ease, margin 0.3s ease;
}

.lk__nav-item.is-active {
  color: #000;
  pointer-events: none;
}

.lk__nav-item.is-active:after {
  transform: scaleX(1);
}

.lk__nav-item:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  height: 2px;
  width: 100%;
  background-color: #4da2cb;
  transform-origin: left;
  transition: transform 0.3s ease;
  transform: scaleX(0);
}

.lk__nav-item:hover {
  color: #000;
}

.lk__nav-item:hover:after {
  transform: scaleX(1);
}

.lk-red-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lk-red-form__group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

@media only screen and (min-width: 640px) {
  .lk-red-form__group {
    flex-direction: row;
  }
}

.lk-red-form__group .form__group {
  width: 100%;
  max-width: 100%;
}

@media only screen and (min-width: 640px) {
  .lk-red-form__group .form__group {
    width: calc(50% - 10px);
    max-width: 350px;
  }
}

.favorites-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.lk {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;
  background-color: #fff;
  position: relative;
}

.lk__header {
  height: 80px;
  background-color: #ecf6fb;
  padding: 16px 10px 8px;
}

@media only screen and (min-width: 640px) {
  .lk__header {
    padding: 26px 20px 20px;
  }
}

.lk__title {
  color: #445568;
  font-size: 24px;
  font-weight: 500;
  line-height: 100%;
  padding-bottom: 14px;
  border-bottom: 1px solid #bde0f1;
}

.lk__block {
  padding: 10px 20px 20px;
}

@media only screen and (min-width: 640px) {
  .lk__block {
    padding: 20px 40px 30px;
  }
}

.lk__info-text {
  color: #000;
  font-weight: 100;
  font-size: 16px;
  line-height: 18px;
  width: auto;
  text-align: center;
}

@media only screen and (min-width: 640px) {
  .lk__info-text {
    width: 230px;
    text-align: left;
  }
}

.lk__nav {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .lk__nav {
    display: block;
  }
}

.lk__block-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 18px;
}

@media only screen and (min-width: 640px) {
  .lk__block-group {
    align-items: flex-start;
    flex-direction: row;
  }
}

.lk__info-cotext {
  color: #445568;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

@media only screen and (min-width: 640px) {
  .lk__info-cotext {
    text-align: left;
  }
}

.lk__content {
  width: 100%;
  margin-left: 0;
  box-shadow: 2px 5px 33px -5px rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 1025px) {
  .lk__content {
    width: calc(100% - 320px);
    margin-left: 40px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 14px;
  width: 97%;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
  height: 548px;
}

@media only screen and (min-width: 480px) {
  .item {
    width: 290px;
  }
}

@media only screen and (min-width: 760px) {
  .item {
    padding: 20px;
    width: 320px;
  }
}

.item:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.item--page-tovar {
  padding: 0 !important;
  background-color: transparent;
  z-index: 25;
}

.item--page-tovar:hover {
  background-color: transparent !important;
}

.item--page-tovar:hover:after {
  display: none !important;
}

.item__labels {
  position: absolute;
  top: 36px;
  right: 0;
  z-index: 3;
}

.item__not-available {
  font-size: 18px;
}

.item__label {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  text-transform: lowercase;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  margin-bottom: 4px;
  line-height: 12px;
}

.item__label--stock {
  background-color: #e94a4a;
}

.item__label--new {
  background-color: #53c3af;
}

.item__label--spec {
  background-color: #d5c06b;
}

.item__favorites {
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item__favorites svg {
  width: 28px;
  height: 26px;
  fill: transparent;
  stroke: #a9acbd;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.item__favorites svg, .item__favorites:focus svg {
  fill: transparent;
  stroke: #a9acbd;
}

.item__favorites:hover svg, .item__favorites:active svg {
  fill: #fe5400;
  stroke: #fe5400;
}

.item__favorites.is-active svg {
  fill: #fe5400;
  stroke: #fe5400;
}

.item__descr-bottom-row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 30px 0 0 0;
  position: relative;
}

@media only screen and (min-width: 640px) {
  .item__descr-bottom-row {
    justify-content: space-between;
  }
}

.item__descr-bottom-row:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  height: 2px;
  width: 100%;
  background-color: #bde0f1;
}

.item__price-row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 28px;
}

.item__price {
  color: #fe5400;
  font-size: 24px;
  margin-right: 20px;
  line-height: 100%;
  font-weight: 500;
}

.item__old-price {
  font-weight: 400;
  font-size: 16px;
  color: #445568;
  text-decoration: line-through;
}

.item__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  color: #000;
  margin-bottom: 24px;
  display: inline-block;
  height: 48px;
  overflow: hidden;
}

.item__image-wrapper {
  position: relative;
  margin-bottom: 24px;
  text-align: center;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item:hover {
  z-index: 25;
  background-color: #fff;
}

.item:hover:after {
  opacity: 1;
}

.item-descr .item {
  height: auto;
}

.index-news {
  padding: 60px 0 40px 0;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.item-page__top-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  flex-direction: column;
}

@media only screen and (min-width: 760px) {
  .item-page__top-wrapper {
    flex-direction: row;
  }
}

@media only screen and (min-width: 1280px) {
  .item-page__top-wrapper {
    margin-bottom: 40px;
  }
}

.item-page__review-title {
  font-size: 28px;
  line-height: 100%;
  font-weight: 700;
  padding: 10px 0;
  min-height: 52px;
  margin-bottom: 14px;
  border-bottom: 1px solid #bde0f1;
}

@media only screen and (min-width: 1025px) {
  .item-page__review-title {
    font-size: 30px;
    margin-bottom: 24px;
  }
}

.item-page__bottom-row {
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .item-page__bottom-row {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.item-page__review {
  width: 100%;
  padding-bottom: 40px;
}

@media only screen and (min-width: 1024px) {
  .item-page__review {
    width: 400px;
  }
}

.item-page__info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.callback-form {
  max-width: 740px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.callback-form__group {
  margin-bottom: 34px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.callback-form__group .form__group {
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .callback-form__group .form__group {
    width: calc(50% - 20px);
  }
}

.hidden__title {
  background-color: #ecf6fb;
  margin-bottom: 18px;
  min-height: 82px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  border-radius: 4px 4px 0 0;
  position: relative;
}

.hidden__title span {
  display: inline-block;
  border-bottom: 1px solid #bde0f1;
  padding-bottom: 12px;
}

.hidden__title--callback span {
  padding: 0 10px 20px;
}

@media only screen and (min-width: 760px) {
  .hidden__title--callback span {
    padding: 0 70px 20px;
  }
}

.custom-cross {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 5px;
  border-radius: 100%;
  background-color: #dff2f7;
  cursor: pointer;
  z-index: 15;
}

@media only screen and (min-width: 760px) {
  .custom-cross {
    width: 52px;
    height: 52px;
    top: 12px;
    right: 12px;
  }
}

.custom-cross svg {
  fill: #fe5400;
  width: 18px;
  height: 18px;
}

@media only screen and (min-width: 760px) {
  .custom-cross svg {
    width: 24px;
    height: 24px;
  }
}

.mfp-close {
  display: none !important;
}

.one-click-buy-form {
  max-width: 340px;
  width: 100%;
  margin: 0 auto;
}

.one-click-buy-form .control-holder--text {
  margin-bottom: 28px;
}

.one-click-buy-form .form__caption {
  margin-bottom: 14px;
  text-align: center;
}

.hidden-wrapper__content {
  padding: 14px 20px 80px;
  position: relative;
  z-index: 5;
}

.items-slider-tabs {
  padding: 20px 0;
  background-size: cover;
}

.items-slider-tabs .items-slider-block {
  padding: 0;
}

.items-slider-tabs__buttons {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
}

.items-slider-tabs__title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 100%;
  font-weight: 500;
  font-family: 'Museo Sans';
  color: #445568;
}

@media only screen and (min-width: 1025px) {
  .items-slider-tabs__title {
    margin-bottom: 32px;
    font-size: 24px;
  }
}

.items-slider-tabs__title span {
  color: #000;
}

.items-slider-tabs__button {
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 100%;
  font-weight: 700;
  font-family: 'Museo Sans';
  color: #445568;
  position: relative;
  transition: color 0.5s ease;
  margin-right: 20px;
  border-bottom: 1px solid #4da2cb;
}

@media only screen and (min-width: 760px) {
  .items-slider-tabs__button {
    border-bottom: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .items-slider-tabs__button {
    margin-right: 46px;
    font-size: 30px;
  }
}

.items-slider-tabs__button:after {
  position: absolute;
  content: "";
  top: 100%;
  height: 2px;
  width: 100%;
  background-color: #fe5400;
  left: 0;
  opacity: 0;
}

.items-slider-tabs__button.is-active {
  color: #000;
}

.items-slider-tabs__button.is-active:after {
  opacity: 1;
}

.item-info-list {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #bde0f1;
}

.item-info-list__item {
  min-height: 92px;
  padding: 20px 0;
  display: flex;
  border-bottom: 1px solid #bde0f1;
  align-items: center;
  position: relative;
  flex-direction: column;
}

@media only screen and (min-width: 760px) {
  .item-info-list__item {
    flex-direction: row;
  }
}

.item-info-list__item:hover .info-more-btn {
  opacity: 1;
  transform: scale(1);
}

.item-info-list__item .info-more-btn {
  display: inline-block;
}

@media only screen and (min-width: 760px) {
  .item-info-list__item .info-more-btn {
    display: inline-flex;
  }
}

.item-info-list__descr {
  display: flex;
  flex-direction: column;
  padding-right: 0;
  text-align: center;
}

@media only screen and (min-width: 760px) {
  .item-info-list__descr {
    padding-right: 135px;
    text-align: left;
  }
}

.item-info-list__title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #33363e;
  text-align: center;
}

@media only screen and (min-width: 760px) {
  .item-info-list__title {
    text-align: left;
  }
}

.item-info-list__text {
  font-size: 16px;
  font-weight: 100;
  color: #445568;
  margin-bottom: 20px;
}

@media only screen and (min-width: 760px) {
  .item-info-list__text {
    margin-bottom: 0;
  }
}

.item-info-list__image {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-right: 0;
}

@media only screen and (min-width: 760px) {
  .item-info-list__image {
    margin-right: 30px;
  }
}

.item-info-list__image svg {
  width: 42px;
  height: 42px;
  fill: #53c3af;
}

.info-more-btn {
  position: relative;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  letter-spacing: 0.15em;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #33363e;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: transform 0.3s ease, opacity 0.5s ease;
  transform: scale(1);
  opacity: 1;
}

@media only screen and (min-width: 760px) {
  .info-more-btn {
    transform: translateX(45px) scale(0.9);
    opacity: 0;
    position: absolute;
  }
}

.info-more-btn svg {
  width: 20px;
  height: 12px;
  fill: #bde0f1;
  margin-left: 8px;
}

.rating {
  display: flex;
}

.rating__star {
  display: flex;
  margin-right: 10px;
}

.rating__star svg {
  fill: #eee6d0;
}

.rating__star:last-of-type {
  margin-right: 0;
}

.rating__star.is-active svg {
  fill: #fe5400;
}

.rating--item-main .rating__star {
  width: 34px;
  height: 34px;
}

.rating--item-main .rating__star svg {
  width: 34px;
  height: 34px;
}

.rating--item-list {
  justify-content: center;
}

.rating--item-list .rating__star {
  width: 22px;
  height: 22px;
}

.rating--item-list .rating__star svg {
  width: 22px;
  height: 22px;
}

.feed-rating {
  display: block;
  margin-bottom: 30px;
}

.feed-rating__count {
  color: #00a2ff;
  font-size: 12px;
  line-height: 33px;
}

.item-info__rating {
  margin-bottom: 30px;
}

.feed-enreg {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.1em;
  text-decoration: none;
  margin-bottom: 20px;
  display: inline-block;
  outline: none;
}

.feed-enreg span {
  margin-right: 6px;
}

.feed-enreg svg {
  width: 18px;
  height: 12px;
  fill: #bde0f1;
}

.feed-enreg:hover {
  color: #fe5400;
}

.feed-form__text {
  font-size: 14px;
  color: #445568;
  font-weight: 100;
  margin-bottom: 8px;
  margin-top: 20px;
}

.feed-rating__stars {
  display: inline-block;
  vertical-align: middle;
}

.feed-rating__stars:not(:checked) > input {
  position: absolute;
  top: -9999px;
  clip: rect(0, 0, 0, 0);
  visibility: hidden;
}

.feed-rating__stars:not(:checked) > label {
  float: right;
  width: 34px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  height: 34px;
  margin-right: 12px;
}

.feed-rating__stars:not(:checked) > label:before {
  content: '';
  display: inline-block;
  width: 32px;
  height: 32px;
  background-image: url(pic/rnon.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  margin-left: 0;
  margin-right: 12px;
}

.feed-rating__stars > input:checked ~ label:before {
  background-image: url(pic/rok.svg);
}

.feed-rating__stars:not(:checked) > label:hover,
.feed-rating__stars:not(:checked) > label:hover ~ label {
  opacity: 0.7;
}

.feed-rating__stars:not([data-feed-rating="1"]) > label:active {
  position: relative;
  top: 1px;
}

.feed-rating__stars > label:active {
  position: relative;
  top: 1px;
}

.feed-rating__stars[data-feed-rating="1"] label:nth-last-of-type(1):hover,
.feed-rating__stars[data-feed-rating="2"] label:nth-last-of-type(-n+2):hover,
.feed-rating__stars[data-feed-rating="3"] label:nth-last-of-type(-n+3):hover,
.feed-rating__stars[data-feed-rating="4"] label:nth-last-of-type(-n+4):hover,
.feed-rating__stars[data-feed-rating="5"] label:nth-last-of-type(-n+5):hover {
  cursor: default;
  opacity: 1;
}

.feed-rating__stars[data-feed-rating="1"] label:nth-last-of-type(1):before,
.feed-rating__stars[data-feed-rating="2"] label:nth-last-of-type(-n+2):before,
.feed-rating__stars[data-feed-rating="3"] label:nth-last-of-type(-n+3):before,
.feed-rating__stars[data-feed-rating="4"] label:nth-last-of-type(-n+4):before,
.feed-rating__stars[data-feed-rating="5"] label:nth-last-of-type(-n+5):before {
  background-image: url(pic/feed-rating-active.svg);
}

.feed-rating__stars[data-feed-rating="1"] label:hover ~ label,
.feed-rating__stars[data-feed-rating="2"] label:hover ~ label,
.feed-rating__stars[data-feed-rating="3"] label:hover ~ label,
.feed-rating__stars[data-feed-rating="4"] label:hover ~ label,
.feed-rating__stars[data-feed-rating="5"] label:hover ~ label {
  opacity: 1;
  cursor: default;
}

.feed-rating__stars[data-feed-rating="1"] label:hover,
.feed-rating__stars[data-feed-rating="2"] label:hover,
.feed-rating__stars[data-feed-rating="3"] label:hover,
.feed-rating__stars[data-feed-rating="4"] label:hover,
.feed-rating__stars[data-feed-rating="5"] label:hover,
.feed-rating__stars[data-feed-rating="1"] label,
.feed-rating__stars[data-feed-rating="2"] label,
.feed-rating__stars[data-feed-rating="3"] label,
.feed-rating__stars[data-feed-rating="4"] label,
.feed-rating__stars[data-feed-rating="5"] label {
  opacity: 1;
  cursor: default;
}

.feed-rating__stars[data-feed-rating="1"] label:active,
.feed-rating__stars[data-feed-rating="2"] label:active,
.feed-rating__stars[data-feed-rating="3"] label:active,
.feed-rating__stars[data-feed-rating="4"] label:active,
.feed-rating__stars[data-feed-rating="5"] label:active {
  top: 0;
}

.item-resp-row {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1280px) {
  .item-resp-row {
    display: none;
  }
}

.item-normal-row {
  display: none;
}

@media only screen and (min-width: 1280px) {
  .item-normal-row {
    display: block;
  }
}

.item-descr {
  width: 100%;
  padding-left: 5px;
  flex-shrink: auto;
}

@media only screen and (min-width: 760px) {
  .item-descr {
    width: 32%;
  }
}

@media only screen and (min-width: 1025px) {
  .item-descr {
    width: calc(100% - 460px);
    padding-left: 26px;
  }
}

@media only screen and (min-width: 1024px) {
  .item-descr {
    padding-left: 2%;
  }
}

@media only screen and (min-width: 1280px) {
  .item-descr {
    width: 50%;
    flex-shrink: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .item-descr {
    padding-left: 6%;
  }
}

.item-descr__rating {
  display: flex;
}

.item-descr__spinner {
  padding-top: 30px;
  margin: 0 36px 0 60px;
}

.item-descr__price-block {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  text-align: center;
}

@media only screen and (min-width: 1025px) {
  .item-descr__price-block {
    padding-top: 30px;
  }
}

@media only screen and (min-width: 1280px) {
  .item-descr__price-block {
    text-align: left;
  }
}

.item-descr__price {
  font-size: 36px;
  color: #fe5400;
  margin-bottom: 10px;
  font-weight: 500;
  text-align: center;
}

@media only screen and (min-width: 1280px) {
  .item-descr__price {
    text-align: left;
  }
}

.item-descr__bottom-row {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1024px) {
  .item-descr__bottom-row {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 1440px) {
  .item-descr__bottom-row {
    justify-content: space-between;
  }
}

.item-descr__old-price {
  font-weight: 100;
  font-size: 18px;
  text-decoration: line-through;
}

.item-descr__status {
  color: #53c3af;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1280px) {
  .item-descr__status {
    margin-bottom: 0;
  }
}

.item-descr__top-row {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 760px) {
  .item-descr__top-row {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 1280px) {
  .item-descr__top-row {
    flex-direction: row;
  }
}

.item-descr__group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-descr__feeds-count {
  font-weight: 100;
  cursor: pointer;
  font-size: 16px;
}

.item-descr__feeds-count span {
  font-weight: 500;
  text-decoration: underline;
}

.item-descr__buy-block .item__descr-bottom-row {
  align-items: flex-start;
}

.item-descr__buy-block .item__descr-bottom-row:before {
  display: none;
}

.item-descr__buy-block .item__favorites {
  margin: 4px 0 0 14px;
}

@media only screen and (min-width: 1025px) {
  .item-descr__buy-block .item__favorites {
    margin: 8px 0 0 28px;
  }
}

.rating-star {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  margin: 0 2px;
  height: 24px;
}

@media only screen and (min-width: 1024px) {
  .rating-star {
    margin: 0 10px;
  }
}

.rating-star svg {
  width: 20px;
  height: 20px;
  fill: #445568;
}

.rating-star.is-active svg {
  fill: #fe9a1a;
}

.cart-item__opener .cart-button {
  height: 52px;
}

.blue-decor {
  position: absolute;
  font-size: 100px;
  line-height: 100%;
  color: #bde0f1;
  opacity: 0.3;
  display: none;
  font-family: 'Playfair Display';
  font-style: italic;
}

@media only screen and (min-width: 1025px) {
  .blue-decor {
    display: block;
  }
}

.blue-decor--top {
  top: -20px;
  bottom: auto;
}

.blue-decor--bottom {
  bottom: 6px;
  top: auto;
}

.blue-decor--right {
  right: 10px;
  left: auto;
}

.blue-decor--left {
  left: 30px;
  right: auto;
}

._tac {
  text-align: center;
}

.inner-page {
  padding-bottom: 30px;
}

.inner-page__content {
  z-index: 5;
  position: relative;
}

.inner-page--search p {
  font-size: 16px;
  font-family: 'Museo Sans';
  font-weight: 600;
}

._mgb70 {
  margin-bottom: 70px;
}

.pagination {
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.pagination__btn {
  min-width: 30px;
  height: 30px;
  color: #445568;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 100%;
  margin: 0 6px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (min-width: 760px) {
  .pagination__btn {
    min-width: 38px;
    height: 38px;
    font-size: 18px;
    margin: 0 14px;
  }
}

.pagination__btn:hover {
  background-color: #fe5400;
  color: #fff;
}

.pagination__btn.is-active {
  background-color: #a9acbd;
  pointer-events: none;
  color: #fff;
}

.pagination__arrow {
  text-decoration: none;
  display: none;
}

@media only screen and (min-width: 640px) {
  .pagination__arrow {
    display: block;
  }
}

.pagination__arrow svg {
  width: 24px;
  height: 14px;
  fill: #a9acbd;
}

.pagination__arrow--left {
  margin-right: 40px;
}

.pagination__arrow--right {
  margin-left: 40px;
}

.pagination__arrow:hover svg {
  fill: #fe5400;
}

.main-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  max-height: 490px;
}

@media only screen and (min-width: 1024px) {
  .main-slider {
    width: 62.5%;
  }
}

.main-slider__item {
  height: 300px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
}

@media only screen and (min-width: 640px) {
  .main-slider__item {
    height: 340px;
  }
}

@media only screen and (min-width: 1280px) {
  .main-slider__item {
    height: 490px;
  }
}

.main-slider__descr {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  z-index: 15;
  max-width: 540px;
}

@media only screen and (min-width: 760px) {
  .main-slider__descr {
    padding: 50px 40px;
  }
}

.main-slider__text {
  color: #fff;
  font-size: 26px;
  line-height: 100%;
  font-weight: 700;
  margin-bottom: 24px;
}

@media only screen and (min-width: 760px) {
  .main-slider__text {
    font-size: 34px;
  }
}

@media only screen and (min-width: 1280px) {
  .main-slider__text {
    font-size: 48px;
  }
}

.main-slider__text i {
  font-weight: normal;
  font-family: 'Playfair Display';
}

.arrows-group {
  position: absolute;
  display: none;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 15;
  height: 100px;
}

@media only screen and (min-width: 760px) {
  .arrows-group {
    display: flex;
  }
}

.main-slider-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  position: relative;
}

.main-slider-block:after {
  position: absolute;
  width: 628px;
  height: 935px;
  right: -154px;
  bottom: -470px;
  z-index: -1;
  background-image: url(../images/circle_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  content: "";
}

@media only screen and (min-width: 1024px) {
  .main-slider-block {
    flex-direction: row;
  }
}

.main-slider-banner {
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  height: 300px;
}

.main-slider-banner:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 640px) {
  .main-slider-banner {
    height: 340px;
  }
}

@media only screen and (min-width: 1024px) {
  .main-slider-banner {
    width: 37.6%;
  }
}

@media only screen and (min-width: 1280px) {
  .main-slider-banner {
    height: 490px;
  }
}

.slider-custom-arrow {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #e4ebee;
  cursor: pointer;
  transition: background-color 0.3s ease;
  user-select: none;
  margin: 0 auto;
}

.slider-custom-arrow svg {
  width: 24px;
  height: 16px;
  fill: #0d1017;
  transition: fill 0.3s ease;
}

.slider-custom-arrow:hover {
  background-color: #0d1017;
}

.slider-custom-arrow:hover svg {
  fill: #e4ebee;
}

.partners {
  height: 180px;
  padding: 30px 0;
  border-top: 1px solid #bde0f1;
  cursor: grab;
  border-bottom: 1px solid #bde0f1;
  background-color: #fff;
}

.partners__item {
  max-width: 230px;
  max-height: 110px;
  display: flex;
}

.partners__item img {
  margin: 0 auto;
}

.soc-main-item {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 8px;
  margin: 0 12px 12px 0;
  position: relative;
  overflow: hidden;
  border: 2px solid #fe5400;
  transition: background-color 0.3s ease;
}

.soc-main-item__content {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transition: top 0.5s ease;
}

.soc-main-item__content svg {
  fill: #445568;
}

.soc-main-item__content--copy {
  position: absolute;
  top: 300%;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  transition: top 0.5s ease;
}

.soc-main-item__content--copy svg {
  fill: #fff;
}

.soc-main-item svg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 26px;
  height: 26px;
}

.soc-main-item:hover {
  background-color: #fe5400;
}

.soc-main-item:hover .soc-main-item__content {
  top: -300%;
}

.soc-main-item:hover .soc-main-item__content--copy {
  position: absolute;
  top: 0;
}

._fc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.js-nav-item-copy {
  display: none;
}

.js-nav-item-copy.is-active {
  display: flex;
}

.js-main-slider {
  opacity: 0;
  visibility: hidden;
}

.js-main-slider.swiper-container {
  opacity: 1;
  visibility: visible;
}

.wrapper-sliders {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin: 0 auto;
  width: 100%;
}

@media only screen and (min-width: 760px) {
  .wrapper-sliders {
    width: 68%;
  }
}

@media only screen and (min-width: 1280px) {
  .wrapper-sliders {
    width: 50%;
  }
}

@media only screen and (min-width: 1280px) {
  .wrapper-sliders {
    margin: 0;
  }
}

.sub-items {
  max-width: 128px;
  flex-basis: 128px;
  width: 128px;
  display: none;
}

@media only screen and (min-width: 640px) {
  .sub-items {
    display: inline-block;
  }
}

@media only screen and (min-width: 1025px) {
  .sub-items {
    max-width: 150px;
    flex-basis: 150px;
    width: 150px;
  }
}

.sub-items__list {
  position: relative;
  max-height: 608px;
}

.sub-items__item {
  margin: 10px 14px;
  max-height: 630px;
  position: relative;
  height: 100px !important;
  width: 100px !important;
}

@media only screen and (min-width: 1025px) {
  .sub-items__item {
    height: 120px !important;
    width: 120px !important;
  }
}

.sub-items__item:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: .6s ease;
}

.sub-items__item:hover:before {
  box-shadow: rgba(0, 0, 0, 0.4) 0 4px 34px -2px;
}

.sub-items__item.slick-current:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.4) 0 4px 34px -2px;
  z-index: -1;
}

.sub-items__item a {
  display: block;
  height: 100%;
  position: relative;
  z-index: 1;
}

.sub-items__item img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  max-height: 100%;
}

.sub-items .slider-custom-arrow {
  position: absolute;
  z-index: 20;
}

.sub-items .slider-custom-arrow--left {
  top: -32px;
  left: 50%;
  transform: translateX(-50%);
}

.sub-items .slider-custom-arrow--right {
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
}

.item-sliders {
  position: relative;
  max-width: 100%;
  flex-basis: 100%;
  width: 100%;
  max-height: 610px;
  overflow: hidden;
  padding: 0 10px;
}

@media only screen and (min-width: 640px) {
  .item-sliders {
    max-width: calc(100% - 120px);
    flex-basis: calc(100% - 120px);
    width: calc(100% - 120px);
  }
}

@media only screen and (min-width: 1025px) {
  .item-sliders {
    padding: 0;
    max-width: calc(100% - 150px);
    flex-basis: calc(100% - 150px);
    width: calc(100% - 150px);
  }
}

.item-sliders__list {
  margin-top: -20px;
}

.item-sliders__item {
  padding: 20px;
  height: 480px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
}

@media only screen and (min-width: 640px) {
  .item-sliders__item {
    height: 600px;
  }
}

.item-sliders__item img {
  box-shadow: 0px 9px 33px 0px rgba(0, 0, 0, 0.4);
  height: auto;
}

.item-label {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 2;
  min-width: 160px;
}

.contacts__title._mgb10 {
  margin-bottom: 10px;
}

._mt10 {
  margin-top: 10px;
}

.mfp-image-holder .mfp-close {
  display: block !important;
}

.widget--filter-tovar {
  padding: 0;
}

@media only screen and (min-width: 1025px) {
  .widget--filter-tovar {
    padding: 0 20px;
  }
}

.view-grid-else {
  padding: 0;
  width: 100%;
}

.catalog--page {
  margin: 0;
}

@media only screen and (min-width: 1025px) {
  .catalog--page {
    margin: 0 -20px;
  }
}

.sub-items__list {
  opacity: 0;
  visibility: hidden;
}

.sub-items__list.slick-initialized {
  opacity: 1;
  visibility: visible;
}

.item-sliders__list {
  opacity: 0;
  visibility: hidden;
}

.item-sliders__list.slick-initialized {
  opacity: 1;
  visibility: visible;
}

.selectize-control {
  width: 100%;
}

.selectize-input {
  outline: none;
  background-color: #fff;
  border: 2px solid #bde0f1;
  border-radius: 8px;
  height: 44px;
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: flex;
  padding-right: 30px;
  padding-left: 14px;
  max-width: auto;
}

.selectize-input .item {
  height: auto !important;
  padding: 0;
  display: inline-block !important;
  box-sizing: inherit !important;
  font-weight: 100;
  font-family: 'Museo Sans';
  font-size: 14px;
  color: #445568;
  transition: color 0.5s ease;
  line-height: 24px !important;
  width: auto !important;
}

.selectize-input .item:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
  display: none !important;
}

.selectize-control.single .selectize-input:after {
  background: url(../images/arrow_bottom.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 14px;
  height: 6px;
  position: absolute;
  right: 14px;
  top: 2px;
  bottom: 0;
  margin: auto 0;
  content: "";
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
  border: none !important;
}

.selectize-control.single .selectize-input.input-active, .selectize-input {
  border-radius: 8px !important;
}

.selectize-control.single .selectize-input.dropdown-active:after {
  margin-top: auto !important;
  border: none !important;
}

.selectize-dropdown {
  border: 1px solid #bde0f1 !important;
  border-radius: 3px !important;
}

.selectize-dropdown [data-selectable] {
  min-height: 44px;
  display: flex;
  align-items: center;
  padding-right: 30px;
  padding-left: 14px;
  background-color: #fff;
  border-bottom: 1px solid #bde0f1;
  height: 44px;
  font-weight: 100;
  font-family: 'Museo Sans';
  font-size: 14px;
  color: #445568;
  transition: color 0.5s ease;
}

.selectize-control {
  height: 44px !important;
}

.notate-order {
  color: #445568;
  margin: 0;
  font-size: 12px;
  font-style: italic;
}

@media only screen and (min-width: 760px) {
  .notate-order {
    font-size: 14px;
  }
}

.partners {
  opacity: 0;
  visibility: hidden;
}

.partners.swiper-container {
  opacity: 1;
  visibility: visible;
}

.item__descr-bottom-row {
  flex-wrap: wrap;
}

.item__spec--row {
  width: 100%;
  margin-bottom: 20px;
}

.item__spec {
  margin-bottom: 8px;
  font-weight: bold;
}

.item__spec b {
  font-weight: normal;
}

.item {
  height: auto;
}

._bottom-mega-hr-line {
  position: relative;
  width: 100%;
  border-top: 1px solid #bde0f1;
}

.is-costil .item-descr__bottom-row {
  flex-direction: column;
  padding: 0;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #ecf6fb;
  display: none;
  padding: 14px;
  max-height: 100vh;
  overflow: auto;
}

.search-results.is-open {
  display: block;
}

.search-results__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 14px;
  border-top: 1px solid #fe5400;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid #fe5400;
}

.search-results__title {
  padding: 4px 0;
  display: block;
  font-weight: 100;
  font-size: 16px;
  color: #000;
  transition: color 0.1s ease;
  text-decoration: none;
}

.search-results__title:hover {
  color: #fe5400;
}

.search-results__allresult {
  color: #000;
  font-size: 14px;
  display: block;
  font-weight: 300;
  text-decoration: none;
}

.search-results__allresult:hover {
  color: #fe5400;
}

.result-item {
  text-decoration: none;
  display: flex;
  padding: 9px 4px 9px 0;
}

.result-item__descr-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.result-item__descr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 80px);
  padding-left: 4px;
}

.result-item__image {
  border: 1px solid grey;
  max-width: 75px;
  max-height: 75px;
  margin: auto;
  transition: border-color 0.1s ease;
}

.result-item__status {
  color: #4da2cb;
}

.result-item__status--in-stock {
  color: light-green;
}

.result-item__status--on-stock {
  color: light-orange;
}

.result-item__status--not-available {
  color: tomato;
}

.result-item__price {
  font-size: 18px;
  font-weight: 500;
  color: black;
}

@media only screen and (min-width: 1025px) {
  .result-item__price {
    font-size: 15px;
  }
}

.result-item__title {
  font-size: 14px;
  font-weight: 300;
  max-height: 56px;
  color: #445568;
  overflow: hidden;
  transition: color 0.1s ease;
}

.result-item:hover .result-item__image {
  border-color: black;
}

.result-item:hover .result-item__title {
  color: #fe5400;
}
