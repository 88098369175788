////
/// @group _partials/pagination.scss
////

// scss
// ================================

.pagination {
	text-align: center;
}
