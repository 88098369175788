
.button{
	border: none;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	outline: none;
	background: transparent;
	text-decoration: none;
	&--big-news{
		margin-top: 40px;
	}
	&--main{
		.button__content{
			color: $c-dark;
			letter-spacing: 0.1em;
			font-size: 14px;
			text-transform: uppercase;
			line-height: 100%;
			font-weight: 700;
			font-family: 'Museo Sans';
			border-radius: 8px;
			border: 2px solid $c-orange;
			background-color: transparent;
			transition: transform 0.3s, background-color 0.2s 0.2s ease;
			transform-style: preserve-3d;
			overflow: hidden;
			position: relative;

			&:after{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: $c-orange;
				transform: scale(0.2);
				opacity: 0;
				content: "";
				transition: opacity 0.3s 0.2s ease, transform 0.5s 0.2s ease;
				border-radius: 8px;
				z-index: 1;
			}
			&:before{
				content: attr(data-hover-text);
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				color: $c-white;
				z-index: 2;
				opacity: 0;
				transform: scale(0.8);
				transition: opacity 0.3s ease, transform 0.3s ease;
			}

			span{
				position: relative;
				z-index: 3;
				opacity: 1;
				transform: scale(1);
				transition: opacity 0.3s 0.3s ease, transform 0.3s 0.3s ease;
			}
		}
		&:hover{
			.button__content{
				background-color: $c-orange;
				span{
					opacity: 0;
					transform: scale(0.7);
					transition: opacity 0.3s ease, transform 0.3s ease;
				}
				&:after{
					transform: scale(1.3);
					opacity: 1;
					transition: opacity 0.3s ease, transform 0.5s ease;
				}
				&:before{
					transform: scale(1);
					opacity: 1;
					transition: opacity 0.3s 0.2s ease, transform 0.3s 0.2s ease;
				}
			}
		}
	}

	&__icon{
		width: 12px;
		height: 12px;
		fill: blue;
		transition: fill 0.3s ease;
		margin-left: 12px;
	}

	&--buy{
		.button__content{
			background-color: transparent;
			color: $c-black;
			letter-spacing: 0.1em;
			font-size: 14px;
			text-transform: uppercase;
			line-height: 100%;
			font-weight: 700;
			font-family: 'Museo Sans';
			border-radius: 8px;
			transition: transform 0.3s, background-color 0.2s 0.2s ease;
			transform-style: preserve-3d;
			overflow: hidden;
			position: relative;
			border: 2px solid $c-orange;


			&:after{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: $c-orange;
				transform: scale(0.2);
				opacity: 0;
				content: "";
				transition: opacity 0.3s 0.2s ease, transform 0.5s 0.2s ease;
				border-radius: 8px;
				z-index: 1;
			}
			&:before{
				content: attr(data-hover-text);
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				color: $c-white;
				z-index: 2;
				opacity: 0;
				transform: scale(0.8);
				transition: opacity 0.3s ease, transform 0.3s ease;
			}

			span{
				position: relative;
				z-index: 3;
				opacity: 1;
				transform: scale(1);
				transition: opacity 0.3s 0.3s ease, transform 0.3s 0.3s ease;
			}
		}
		&:hover{
			.button__content{
				background-color: $c-orange;
				span{
					opacity: 0;
					transform: scale(0.7);
					transition: opacity 0.3s ease, transform 0.3s ease;
				}
				&:after{
					transform: scale(1.3);
					opacity: 1;
					transition: opacity 0.3s ease, transform 0.5s ease;
				}
				&:before{
					transform: scale(1);
					opacity: 1;
					transition: opacity 0.3s 0.2s ease, transform 0.3s 0.2s ease;
				}
			}
		}


		//.button__content{
		//	background-color: transparent;
		//	color: $c-white;
		//	letter-spacing: 0.1em;
		//	font-size: 14px;
		//	text-transform: uppercase;
		//	line-height: 100%;
		//	font-weight: 700;
		//	font-family: 'Museo Sans';
		//	transition: background-color 0.5s ease, color 0.5s ease;
		//	border-radius: 8px;
		//	background-color: $c-orange;
		//}
		//&:hover{
		//	.button__content{
		//		background-color: lighten($c-orange, 15%);
		//		color: white;
		//	}
		//	.button__icon{
		//		fill: white;
		//	}
		//}
	}
	
	&__content{
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		padding: 0 10px;
	}

	&--not-available{
		.button__content{
			background-color: transparent;
			color: grey;
			border: 1px solid grey;
			letter-spacing: 0.05em;
			font-size: 10px;
			text-transform: uppercase;
			line-height: 100%;
			font-weight: 700;
			font-family: 'Museo Sans';
			transition: background-color 0.5s ease;
			text-align: center;
		}
		&:hover{
			.button__content{
				background-color: grey;
			}
		}
	}

	&--disabled{
		pointer-events: none;
	}
	
	&--w100{
		width: 100%;
	}
	&:hover{
		
	}
	&:active{
		.button__content{
			background-color: lighten($c-orange, 5%);
			transition: background-color 0s ease;
		}
	}

	&--sub{
		.button__content{
			background-color: grey;
			color: white;
			letter-spacing: 0.05em;
			font-size: 10px;
			text-transform: uppercase;
			line-height: 100%;
			font-weight: 700;
			font-family: 'Museo Sans';
			transition: background-color 0.5s ease;
			border-radius: 8px;
		}
		&:hover{
			.button__content{
				background-color: blue;
			}
		}
	}

	&--sub2{
		.button__content{
			color: blue;
			border: 1px solid blue;
			transition: color 0.5s ease, border-color 0.5s ease, transform 0.5s ease;
		}
		&:hover{
			.button__content{
				color: blue;
				border-color: blue;
			}
		}
		&:active{
			.button__content{
				transform: scale(0.95);
				color: lighten(blue, 5%);
				border-color: lighten(blue, 5%);
				background-color: transparent;
				transition: color 0.5s ease, border-color 0.5s ease, transform 0s ease;
			}
		}
	}

	&--mgsm{
		min-width: 30px;
		height: 30px;
	}

	&--sm{
		height: 30px;
		display: inline-block;
		.button__content{
			height: 30px;
			min-width: 120px;
		}
	}

	&--mgs{
		height: 30px;
		.button__content{
			height: 30px;
			width: 40px;
		}
	}

	&--sm-f{
		height: 30px;
		display: inline-block;
		.button__content{
			height: 30px;
			width: 120px;
		}
	}

	&--md{
		height: 44px;
		display: inline-block;
		.button__content{
			height: 48px;
			min-width: 230px;
		}
	}

	&--mg{
		height: 48px;
		display: inline-block;
		.button__content{
			height: 48px;
			min-width: 280px;
			@include media($md){
				min-width: 340px;
			}
		}
	}

	&--omgmd{
		height: 40px;
		display: inline-block;

		@include media($defp){
			height: 50px;
		}
		.button__content{
			height: 40px;
			min-width: 190px;
			@include media($defp){
				height: 50px;
				min-width: 210px;
			}
		}
	}

	&--omgmd2{
		height: 50px;
		display: inline-block;
		.button__content{
			height: 50px;
			min-width: 230px;
		}
	}

	&--mdl{
		height: 50px;
		display: inline-block;
		max-width: 240px;
		width: 100%;
		box-sizing: border-box;
		@include media($ms){
			max-width: 250px;
		}
		.button__content{
			height: 46px;
			width: 100%;
		}
	}

	&--lgmd{
		height: 40px;
		display: inline-block;
		.button__content{
			height: 40px;
			min-width: 260px;
			@include media($lg){
				min-width: 330px;
			}
		}
	}

	&--white{
		.button__content{
			color: $c-white;
		}
		.button__icon{
			fill: $c-lblue;
			height: 12px;
			width: 20px;
			position: relative;
			transition: transform 0.5s 0.3s ease;
			z-index: 15;
		}
		&:hover{
			.button__icon{
				transition: transform 0.5s ease;
				transform: translateX(50px) scale(0);
			}
		}
	}

	&--lg{
		height: 50px;
		display: inline-block;
		.button__content{
			height: 50px;
			min-width: 210px;
			@include media($ms){
				min-width: 250px;
			}
		}
	}
	
	&--w100{
		width: 100%;
		.button__content{
			width: 100%;
		}
	}
}

.show-all-button{
	text-transform: uppercase;
	color: blue;
	font-weight: 500;
	transition: color 0.5s ease;
	font-size: 10px;
	font-family: 'Museo Sans';
	letter-spacing: 0.1em;
	&:hover{
		color: blue;
	}
}

.show-more-button{
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: blue;
	text-decoration: underline;
	cursor: pointer;
	transition: color 0.5s ease;
	font-size: 10px;
	letter-spacing: 0.1em;
	font-weight: 500;
	font-family: 'Museo Sans';
	svg{
		width: 14px;
		height: 10px;
		fill: blue;
		margin-left: 10px;
		transition: fill 0.5s ease;
	}
	&:hover{
		color: blue;
		svg{
			fill: blue;
		}
	}
}