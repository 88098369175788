.order{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding-bottom: 34px;
	z-index: 25;
	position: relative;
	.order-content .ui-selectmenu-button{
		max-width: 100%;
	}
}
.next-order{
	cursor: pointer;
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 0.1em;
	transition: color 0.3s ease;
	text-transform: uppercase;
	display: inline-block;
	margin-top: 30px;
	span{
		border-bottom: 1px dotted $c-black;
		margin-right: 8px;
	}
		svg{
			width: 20px;
			height: 12px;
			fill: $c-lblue;
		}
	&:hover{
		color: $c-orange;
	}
}
.order-content{
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 0 20px 0 !important;
	border-right: none;
	@include media($def){
		padding: 0 10px 20px 20px!important;
	}
	@include media($defp){
		width: 50%;
		padding: 0 40px 20px 90px!important;
		border-right: 1px solid $c-lblue;
	}

	&__map{
		position: relative;
		width: 100%;
		height: 248px;
		margin-left: 0;
		@include media($md){
			height: 358px;
		}
		@include media($lg){
			margin-left: 240px;
			width: calc(100% - 240px);
		}
	}
	.ui-selectmenu-button{
		width: 100%;
		max-width: 518px;
	}
	&__input-group{
		display: flex;
		margin-bottom: 6px;
		align-items: flex-start;
		flex-direction: column;
	}
	&__text{
		margin-bottom: 6px;
		color: $c-blue;
		font-weight: 100;
		font-size: 16px;
	}
	&__accordeon{
	}
	&__title{
		font-size: 24px;
		font-weight: 500;
		color: $c-dblue;
		margin-bottom: 20px;
		padding-bottom: 8px;
		display: flex;
		border-bottom: 1px solid $c-lblue;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		@include media($md){
			flex-direction: row;
		}
	}
	&__edit-data{
		color: $c-black;
		font-weight: 700;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		margin-top: 20px;
		border-bottom: 1px dotted $c-black;
		@include media($md){
			margin-top: 0;
		}
	}
	.control-holder--flag label:only-child, .control-holder--flag label.has-error{
		cursor: pointer;
	}
	&__accordeon-content{
		padding-bottom: 40px;
	}
	&__accordeon-button{
		width: 100%;
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: background-color 0.3s ease;
		margin-bottom: 16px;

		.has-error{
			display: none!important;
		}
		span{
			color: $c-dblue;
			font-size: 18px;
			font-weight: 500;
			margin-left: 10px;
		}
		&.is-active{
			background-color: #ebebf1;
			cursor: default;
		}
	}
	.form__group{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	&__button{
		margin-right: auto;
	}
	.form__caption{
		margin-bottom: 6px;
	}
	&__feed-btn{
		cursor: pointer;
		text-transform: uppercase;
		text-decoration: underline;
		font-size: 10px;
		font-weight: 500;
		transition: color 0.3s ease;
		margin: 30px 0 38px 0;
		@include media($lg){
			margin: 30px 0 38px 240px;
		}
		&:hover{
			color: $c-lblue;
		}
	}
	&__bucklets{
		margin-left: 0;
		@include media($lg){
			margin-left: 240px;
		}
		ins{
			width: 22px;
			height: 22px;
			background-color: $c-white;
			border-radius: 3px;
			box-shadow: none;
			position: relative;
			&:after{
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				width: 16px;
				height: 12px;
				background-size: cover;
				background-position: 50%;
				background-repeat: no-repeat;
				background-image: url(../images/okay_check.svg);
				opacity: 0;
				content: "";
				transition: opacity 0.3s ease;
			}
		}
		span{
			transition: color 0.3s ease;
		}
		label{
			&:hover{
				span{
				}
			}
		}
		input {
			&:checked {
				~ ins {
					background-color: $c-white;
					&:after{
						opacity: 1;
					}
				}
				span{
					text-decoration: underline;
				}
			}
		}
	}
	&__step{
		&--first{
			margin-bottom: 20px;
			position: relative;
			padding-left: 0;
			@include media($ms){
				padding-left: 46px;
			}
			@include media($def){
				padding-left:0;
			}
			&:before{
				position: absolute;
				content: "1";
				left: 0;
				top: 0;
				width: 30px;
				height: 30px;
				background-color: $c-orange;
				color: $c-white;
				font-weight: 500;
				font-size: 24px;
				display: none;
				justify-content: center;
				align-items: center;
				align-content: center;
				border-radius: 100%;
				@include media($ms){
					display: flex;
				}
				@include media($def){
					left: -50px;
				}
			}
		}
		&--second{
			position: relative;
			padding-left: 0;
			@include media($ms){
				padding-left: 46px;
			}
			@include media($def){
				padding-left:0;
			}
			&:before{
				position: absolute;
				content: "2";
				left: 0;
				top: 0;
				width: 30px;
				height: 30px;
				background-color: #a9acbd;
				color: $c-white;
				font-weight: 500;
				font-size: 24px;
				display: none;
				justify-content: center;
				align-items: center;
				align-content: center;
				border-radius: 100%;
				@include media($ms){
					display: flex;
				}
				@include media($def){
					left: -50px;
				}
			}

			&.is-active{
				&:before{
					background-color: $c-orange;
				}
			}
		}
		&--third{
			& > .form__group{
				margin-bottom: 5px;
			}
			.order-content__accordeon-button{
				margin-bottom: 0;
			}
		}
	}
	.control-holder{
		width: 100%;
	}
	&__sub-text{
		font-size: 16px;
		color: #0d1017;
		font-weight: 300;
		margin-left: 0;
		@include media($lg){
			margin-left: 240px;
		}
	}
}
.order-types{
	display: flex;
	margin-bottom: 44px;
	flex-wrap: wrap;

	.form__group{
		width: 100%;
		@include media($md){
			width: 50%;
		}
	}
}
.ui-selectmenu-menu .ui-menu{
	max-height: 50vh;
}