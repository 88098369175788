////
/// @group _partials/breadcrumbs.scss
////

// scss
// ================================

.breadcrumbs {
	width: 100%;
	display: block;
	line-height: 0;
	margin: 0;
	font-size: 14px;
	font-weight: 100;
	padding-top: 48px;
	padding-bottom: 50px;
	position: relative;

	&__content{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	&:after{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 150px;
		content: "";
		z-index: -1;
		background: linear-gradient(to bottom, rgba(231,238,247,1) 0%,rgba(255,255,255,0) 100%);
	}
	&:before{
		z-index: 1;
		width: 647px;
		height: 735px;
		position: absolute;
		right: 0;
		top: -290px;
		background-image: url(../images/Circle.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50%;
		content: "";
	}
}

.breadcrumbs a {
	color: $c-blue;
	text-decoration: none;

	&:hover{
		color: $c-orange;
	}
}

.breadcrumbs span {
	color: $c-dblue;
	display: inline-block;
	vertical-align: top;
	font-size: 14px;
	line-height: 1.4;
	margin-right: .5em;
	position: relative;
	z-index: 3;

	&:before {
		margin-right: .5em;
		content: '•';
		color: $c-blue;
		display: inline-block;
		vertical-align: top;
	}

	&:first-child:before {
		display: none;
	}
}

.breadcrumbs {
	@include media( $md, max ) {
		display: block;

		span {
			&, &:before {
				display: none;
			}

			&:nth-last-child(2) {
				display: block;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 100%;
				background: none;
				margin: 0;

				&:before {
					content: '<';
					margin: 0 5px 0 3px;
					display: inline-block;
				}
			}
		}
	}
}
