////
/// @group _partials/view-footer.scss
////


// scss
// ================================

.view-footer {
	padding-bottom: 36px;
	//position: fixed;
	//bottom: 0;
	//left: 0;
	width: 100%;
	z-index: 0;
	background-color: $c-white;
	padding-top: 50px;
	&.touch-fix{
		position: relative;
	}

	&__bottom-row{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
		@include media($def){
			flex-direction: row;
			align-items: flex-end;
			justify-content: space-between;
		}
	}
	&__logo{
		margin-bottom: 20px;
		@include media($def){
			margin-bottom: 0;
		}
	}
	&__addres{
		font-weight: 500;
		font-size: 18px;
		color: $c-dblue;
		margin-bottom: 10px;
	}
	&__contacts-group{
		display: none;
		@include media($def){
			display: block;
		}
	}
	&__title{
		margin-bottom: 32px;
		font-size: 18px;
		color: $c-dblue;
		font-weight: 700;
	}
	&__graph{
		font-weight: 500;
		font-size: 14px;
		color: $c-dblue;
		margin-bottom: 16px;
	}
	&__categorys{
		margin: 0 20px;
		display: none;
		@include media($def){
			display: block;
		}
	}
	&__nav{
		display: none;
		@include media($def){
			display: block;
		}
	}
	&__top-row{
		display: flex;
		justify-content: center;
		@include media($def){
			justify-content: space-between;
		}
	}
	&__copy{
		margin-bottom: 14px;
	}
	&__btns-row{
		display: flex;
		align-items: center;
		margin-bottom: 60px;

		.button{
			margin: 0 22px 12px 0;
		}
	}

	.phones-block{
		flex-wrap: wrap;
	}
}
