.item-slider{
	display: flex;
	width: 100%;
	max-width: 300px;
	margin: 0 auto;
	margin-bottom: 20px;
	flex-direction: row;
	justify-content: center;
	@include media($sm){
		max-width: 486px;
	}
	@include media($md){
		margin-bottom: 0;
	}
	@include media($lg){
		max-width: 800px;
	}
	@include media($def){
		width: 50%;
		margin: 0;
	}
	&__main-image{
		width: 100%;
		height: 234px;
		max-width: 600px;
		position: relative;
		margin-left: 0;
		order: 1;
		cursor: pointer;
		@include media($sm){
			height: 380px;
		}
		@include media($lg){
			height: 600px;
		}
		@include media($xs){
			//height: 600px;
		}
		@include media($def){
			order: 2;
			//width: 600px;
			//height: 600px;
		}

		img{
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			max-height: 100%;
			box-shadow: 0px 9px 33px 0px rgba(0, 0, 0, 0.4);
		}
	}
	&__thumbs-wrapper{
		position: relative;
		width:  66px;
		height: 234px;
		order: 1;
		flex-shrink: 0;
		margin: 0;
		padding: 0 8px;

		@include media($sm){
			width:  104px;
			height: 386px;
		}

		@include media($defp){
			margin: auto 0;
		}
		@include media($lg){
			height: 510px;
			width:  136px;
		}
		@include media($mg){
			padding: 0 38px;
			width:  196px;
		}
	}
	&__thumbs-slider-wrapper{
		// width: 460px;
		// height: 66px;
		// position: relative;
		// transform-origin: 0 100%;
		// transform: rotate(90deg);
		// @include media($def){
		// 	width: 460px;
		// 	height: 66px;
		// 	position: absolute;
		// 	left: 0;
		// 	top: 0;
		// 	transform: rotate(90deg) translateX(-68px);
		// }
	}
	&__thumb{
		transform: rotate(0);
		position: relative;
		width: 50px!important;
		height: 50px;
		display: inline-flex;
		justify-content: center;
		align-content: center;
		cursor: pointer;

		@include media($sm){
			width: 88px!important;
			height: 88px;
		}

		@include media($lg){
			width: 120px!important;
			height: 120px;
		}
		&:after{
			position: absolute;
			left:0;
			top: 0;
			width: 100%;
			height: 100%;
			box-shadow: 0px 4px 34px -2px rgba(0,0,0,0.4);
			content: "";
			border-radius: 22px;
			opacity: 0;
			transition: opacity 0.5s ease;
		}

		&:hover{
			&:after{
				opacity: 1;
			}
		}
	}
	&__thumb-decor{
		position: absolute;
		bottom: 6px;
		left: 0;
		right: 0;
		margin: 0 auto;
		z-index: 20;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
	}
	&__thumbs{
		height: 234px;
		width: 50px;
		position: relative;
		left: 0;
		top: 0;
		@include media($sm){
			width: 88px;
			height: 386px;
		}
		@include media($lg){
			width: 120px;
			height: 510px;
		}
	}
	.slider-custom-arrow{
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		z-index: 25;
		display: none;

		@include media($def){
		    display: flex;
		}

		&--left{
			bottom: 0;
		}
		&--right{
			top: 0;
		}
	}
}
.swiper-slider-wrapper-custom{
	display: flex;
	position: relative;
}