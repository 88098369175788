.hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  cursor: pointer;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  transition-property: opacity, -webkit-filter;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  padding: 10px 10px 10px 0 !important;
}

.hamburger:hover {
  opacity: .7
}

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px
}

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -2px
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  position: absolute;
  width: 40px;
  height: 4px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  transition-property: -webkit-transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  border-radius: 4px;
}

.hamburger-inner:after, .hamburger-inner:before {
  display: block;
  content: ''
}

.hamburger-inner:before {
  top: -7px;
  @include media($md) {
    top: -10px;
  }
}

.hamburger-inner:after {
  bottom: -7px;
  @include media($md) {
    bottom: -10px;
  }
}

.hamburger--3dx .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px
}

.hamburger--3dx .hamburger-inner {
  transition: background-color 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform .2s cubic-bezier(.645, .045, .355, 1);
  -webkit-transition: background-color 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform .2s cubic-bezier(.645, .045, .355, 1);
  transition: transform .2s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
  transition: transform .2s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform .2s cubic-bezier(.645, .045, .355, 1)
}

.hamburger--3dx .hamburger-inner:after, .hamburger--3dx .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dx.is-active .hamburger-inner {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background-color: transparent
}

.hamburger--3dx.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--3dx.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg)
}

.hamburger--3dx-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px
}

.hamburger--3dx-r .hamburger-inner {
  transition: background-color 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform .2s cubic-bezier(.645, .045, .355, 1);
  -webkit-transition: background-color 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform .2s cubic-bezier(.645, .045, .355, 1);
  transition: transform .2s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
  transition: transform .2s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform .2s cubic-bezier(.645, .045, .355, 1)
}

.hamburger--3dx-r .hamburger-inner:after, .hamburger--3dx-r .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dx-r.is-active .hamburger-inner {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  background-color: transparent
}

.hamburger--3dx-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--3dx-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg)
}

.hamburger--3dy .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px
}

.hamburger--3dy .hamburger-inner {
  transition: background-color 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform .2s cubic-bezier(.645, .045, .355, 1);
  -webkit-transition: background-color 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform .2s cubic-bezier(.645, .045, .355, 1);
  transition: transform .2s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
  transition: transform .2s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform .2s cubic-bezier(.645, .045, .355, 1)
}

.hamburger--3dy .hamburger-inner:after, .hamburger--3dy .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dy.is-active .hamburger-inner {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
  background-color: transparent
}

.hamburger--3dy.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--3dy.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg)
}

.hamburger--3dy-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px
}

.hamburger--3dy-r .hamburger-inner {
  transition: background-color 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform .2s cubic-bezier(.645, .045, .355, 1);
  -webkit-transition: background-color 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform .2s cubic-bezier(.645, .045, .355, 1);
  transition: transform .2s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
  transition: transform .2s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform .2s cubic-bezier(.645, .045, .355, 1)
}

.hamburger--3dy-r .hamburger-inner:after, .hamburger--3dy-r .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s, -webkit-transform 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dy-r.is-active .hamburger-inner {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  background-color: transparent
}

.hamburger--3dy-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--3dy-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg)
}

.hamburger--arrow.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(.7);
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(.7)
}

.hamburger--arrow.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(.7);
  transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(.7)
}

.hamburger--arrow-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(.7);
  transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(.7)
}

.hamburger--arrow-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(.7);
  transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(.7)
}

.hamburger--arrowalt .hamburger-inner:before {
  transition: top .1s ease .15s, -webkit-transform .15s cubic-bezier(.165, .84, .44, 1);
  -webkit-transition: top .1s ease .15s, -webkit-transform .15s cubic-bezier(.165, .84, .44, 1);
  transition: top .1s ease .15s, transform .15s cubic-bezier(.165, .84, .44, 1);
  transition: top .1s ease .15s, transform .15s cubic-bezier(.165, .84, .44, 1), -webkit-transform .15s cubic-bezier(.165, .84, .44, 1)
}

.hamburger--arrowalt .hamburger-inner:after {
  transition: bottom .1s ease .15s, -webkit-transform .15s cubic-bezier(.165, .84, .44, 1);
  -webkit-transition: bottom .1s ease .15s, -webkit-transform .15s cubic-bezier(.165, .84, .44, 1);
  transition: bottom .1s ease .15s, transform .15s cubic-bezier(.165, .84, .44, 1);
  transition: bottom .1s ease .15s, transform .15s cubic-bezier(.165, .84, .44, 1), -webkit-transform .15s cubic-bezier(.165, .84, .44, 1)
}

.hamburger--arrowalt.is-active .hamburger-inner:before {
  top: 0;
  transition: top .1s ease, -webkit-transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  -webkit-transition: top .1s ease, -webkit-transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  transition: top .1s ease, transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  transition: top .1s ease, transform .15s cubic-bezier(.895, .03, .685, .22) .1s, -webkit-transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  -webkit-transform: translate3d(-8px, -10px, 0) rotate(-45deg) scaleX(.7);
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scaleX(.7)
}

.hamburger--arrowalt.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom .1s ease, -webkit-transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  -webkit-transition: bottom .1s ease, -webkit-transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  transition: bottom .1s ease, transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  transition: bottom .1s ease, transform .15s cubic-bezier(.895, .03, .685, .22) .1s, -webkit-transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  -webkit-transform: translate3d(-8px, 10px, 0) rotate(45deg) scaleX(.7);
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scaleX(.7)
}

.hamburger--arrowalt-r .hamburger-inner:before {
  transition: top .1s ease .15s, -webkit-transform .15s cubic-bezier(.165, .84, .44, 1);
  -webkit-transition: top .1s ease .15s, -webkit-transform .15s cubic-bezier(.165, .84, .44, 1);
  transition: top .1s ease .15s, transform .15s cubic-bezier(.165, .84, .44, 1);
  transition: top .1s ease .15s, transform .15s cubic-bezier(.165, .84, .44, 1), -webkit-transform .15s cubic-bezier(.165, .84, .44, 1)
}

.hamburger--arrowalt-r .hamburger-inner:after {
  transition: bottom .1s ease .15s, -webkit-transform .15s cubic-bezier(.165, .84, .44, 1);
  -webkit-transition: bottom .1s ease .15s, -webkit-transform .15s cubic-bezier(.165, .84, .44, 1);
  transition: bottom .1s ease .15s, transform .15s cubic-bezier(.165, .84, .44, 1);
  transition: bottom .1s ease .15s, transform .15s cubic-bezier(.165, .84, .44, 1), -webkit-transform .15s cubic-bezier(.165, .84, .44, 1)
}

.hamburger--arrowalt-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top .1s ease, -webkit-transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  -webkit-transition: top .1s ease, -webkit-transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  transition: top .1s ease, transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  transition: top .1s ease, transform .15s cubic-bezier(.895, .03, .685, .22) .1s, -webkit-transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  -webkit-transform: translate3d(8px, -10px, 0) rotate(45deg) scaleX(.7);
  transform: translate3d(8px, -10px, 0) rotate(45deg) scaleX(.7)
}

.hamburger--arrowalt-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom .1s ease, -webkit-transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  -webkit-transition: bottom .1s ease, -webkit-transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  transition: bottom .1s ease, transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  transition: bottom .1s ease, transform .15s cubic-bezier(.895, .03, .685, .22) .1s, -webkit-transform .15s cubic-bezier(.895, .03, .685, .22) .1s;
  -webkit-transform: translate3d(8px, 10px, 0) rotate(-45deg) scaleX(.7);
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scaleX(.7)
}

.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner:after, .hamburger--boring .hamburger-inner:before {
  -webkit-transition-property: none;
  transition-property: none
}

.hamburger--boring.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.hamburger--boring.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0
}

.hamburger--boring.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg)
}

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: .15s;
  transition-delay: .15s;
  -webkit-transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  -webkit-transition-duration: .15s;
  transition-duration: .15s
}

.hamburger--collapse .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top .3s cubic-bezier(.33333, .66667, .66667, 1) .3s, opacity .1s linear;
  transition: top .3s cubic-bezier(.33333, .66667, .66667, 1) .3s, opacity .1s linear
}

.hamburger--collapse .hamburger-inner:before {
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, -webkit-transform .15s cubic-bezier(.55, .055, .675, .19);
  -webkit-transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, -webkit-transform .15s cubic-bezier(.55, .055, .675, .19);
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, transform .15s cubic-bezier(.55, .055, .675, .19);
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, transform .15s cubic-bezier(.55, .055, .675, .19), -webkit-transform .15s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--collapse.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg)
}

.hamburger--collapse.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top .3s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .27s;
  transition: top .3s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .27s;
  opacity: 0
}

.hamburger--collapse.is-active .hamburger-inner:before {
  top: 0;
  transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .42s;
  -webkit-transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .42s;
  transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, transform .15s cubic-bezier(.215, .61, .355, 1) .42s;
  transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, transform .15s cubic-bezier(.215, .61, .355, 1) .42s, -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .42s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg)
}

.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: .15s;
  transition-delay: .15s;
  -webkit-transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  -webkit-transition-duration: .15s;
  transition-duration: .15s
}

.hamburger--collapse-r .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top .3s cubic-bezier(.33333, .66667, .66667, 1) .3s, opacity .1s linear;
  transition: top .3s cubic-bezier(.33333, .66667, .66667, 1) .3s, opacity .1s linear
}

.hamburger--collapse-r .hamburger-inner:before {
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, -webkit-transform .15s cubic-bezier(.55, .055, .675, .19);
  -webkit-transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, -webkit-transform .15s cubic-bezier(.55, .055, .675, .19);
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, transform .15s cubic-bezier(.55, .055, .675, .19);
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, transform .15s cubic-bezier(.55, .055, .675, .19), -webkit-transform .15s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--collapse-r.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(45deg);
  transform: translate3d(0, -10px, 0) rotate(45deg)
}

.hamburger--collapse-r.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top .3s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .27s;
  transition: top .3s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .27s;
  opacity: 0
}

.hamburger--collapse-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .42s;
  -webkit-transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .42s;
  transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, transform .15s cubic-bezier(.215, .61, .355, 1) .42s;
  transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, transform .15s cubic-bezier(.215, .61, .355, 1) .42s, -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .42s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.hamburger--elastic .hamburger-inner {
  top: 2px;
  -webkit-transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  -webkit-transition-duration: .4s;
  transition-duration: .4s
}

.hamburger--elastic .hamburger-inner:before {
  top: 10px;
  -webkit-transition: opacity .15s ease .4s;
  transition: opacity .15s ease .4s
}

.hamburger--elastic .hamburger-inner:after {
  top: 20px;
  transition: -webkit-transform .4s cubic-bezier(.68, -.55, .265, 1.55);
  -webkit-transition: -webkit-transform .4s cubic-bezier(.68, -.55, .265, 1.55);
  transition: transform .4s cubic-bezier(.68, -.55, .265, 1.55);
  transition: transform .4s cubic-bezier(.68, -.55, .265, 1.55), -webkit-transform .4s cubic-bezier(.68, -.55, .265, 1.55)
}

.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
  transform: translate3d(0, 10px, 0) rotate(135deg)
}

.hamburger--elastic.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0
}

.hamburger--elastic.is-active .hamburger-inner:after {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
  transform: translate3d(0, -20px, 0) rotate(-270deg)
}

.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  -webkit-transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  -webkit-transition-duration: .4s;
  transition-duration: .4s
}

.hamburger--elastic-r .hamburger-inner:before {
  top: 10px;
  -webkit-transition: opacity .15s ease .4s;
  transition: opacity .15s ease .4s
}

.hamburger--elastic-r .hamburger-inner:after {
  top: 20px;
  transition: -webkit-transform .4s cubic-bezier(.68, -.55, .265, 1.55);
  -webkit-transition: -webkit-transform .4s cubic-bezier(.68, -.55, .265, 1.55);
  transition: transform .4s cubic-bezier(.68, -.55, .265, 1.55);
  transition: transform .4s cubic-bezier(.68, -.55, .265, 1.55), -webkit-transform .4s cubic-bezier(.68, -.55, .265, 1.55)
}

.hamburger--elastic-r.is-active .hamburger-inner {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
  transform: translate3d(0, 10px, 0) rotate(-135deg)
}

.hamburger--elastic-r.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0
}

.hamburger--elastic-r.is-active .hamburger-inner:after {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
  transform: translate3d(0, -20px, 0) rotate(270deg)
}

.hamburger--emphatic {
  overflow: hidden
}

.hamburger--emphatic .hamburger-inner {
  -webkit-transition: background-color .2s ease-in .25s;
  transition: background-color .2s ease-in .25s
}

.hamburger--emphatic .hamburger-inner:before {
  left: 0;
  transition: top .05s linear .2s, left .2s ease-in .25s, -webkit-transform .2s cubic-bezier(.6, .04, .98, .335);
  -webkit-transition: top .05s linear .2s, left .2s ease-in .25s, -webkit-transform .2s cubic-bezier(.6, .04, .98, .335);
  transition: transform .2s cubic-bezier(.6, .04, .98, .335), top .05s linear .2s, left .2s ease-in .25s;
  transition: transform .2s cubic-bezier(.6, .04, .98, .335), top .05s linear .2s, left .2s ease-in .25s, -webkit-transform .2s cubic-bezier(.6, .04, .98, .335)
}

.hamburger--emphatic .hamburger-inner:after {
  top: 10px;
  right: 0;
  transition: top .05s linear .2s, right .2s ease-in .25s, -webkit-transform .2s cubic-bezier(.6, .04, .98, .335);
  -webkit-transition: top .05s linear .2s, right .2s ease-in .25s, -webkit-transform .2s cubic-bezier(.6, .04, .98, .335);
  transition: transform .2s cubic-bezier(.6, .04, .98, .335), top .05s linear .2s, right .2s ease-in .25s;
  transition: transform .2s cubic-bezier(.6, .04, .98, .335), top .05s linear .2s, right .2s ease-in .25s, -webkit-transform .2s cubic-bezier(.6, .04, .98, .335)
}

.hamburger--emphatic.is-active .hamburger-inner {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: transparent
}

.hamburger--emphatic.is-active .hamburger-inner:before {
  top: -80px;
  left: -80px;
  transition: left .2s ease-out, top .05s linear .2s, -webkit-transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  -webkit-transition: left .2s ease-out, top .05s linear .2s, -webkit-transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  transition: left .2s ease-out, top .05s linear .2s, transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  transition: left .2s ease-out, top .05s linear .2s, transform .2s cubic-bezier(.075, .82, .165, 1) .25s, -webkit-transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  -webkit-transform: translate3d(80px, 80px, 0) rotate(45deg);
  transform: translate3d(80px, 80px, 0) rotate(45deg)
}

.hamburger--emphatic.is-active .hamburger-inner:after {
  top: -80px;
  right: -80px;
  transition: right .2s ease-out, top .05s linear .2s, -webkit-transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  -webkit-transition: right .2s ease-out, top .05s linear .2s, -webkit-transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  transition: right .2s ease-out, top .05s linear .2s, transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  transition: right .2s ease-out, top .05s linear .2s, transform .2s cubic-bezier(.075, .82, .165, 1) .25s, -webkit-transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  -webkit-transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transform: translate3d(-80px, 80px, 0) rotate(-45deg)
}

.hamburger--emphatic-r {
  overflow: hidden
}

.hamburger--emphatic-r .hamburger-inner {
  -webkit-transition: background-color .2s ease-in .25s;
  transition: background-color .2s ease-in .25s
}

.hamburger--emphatic-r .hamburger-inner:before {
  left: 0;
  transition: top .05s linear .2s, left .2s ease-in .25s, -webkit-transform .2s cubic-bezier(.6, .04, .98, .335);
  -webkit-transition: top .05s linear .2s, left .2s ease-in .25s, -webkit-transform .2s cubic-bezier(.6, .04, .98, .335);
  transition: transform .2s cubic-bezier(.6, .04, .98, .335), top .05s linear .2s, left .2s ease-in .25s;
  transition: transform .2s cubic-bezier(.6, .04, .98, .335), top .05s linear .2s, left .2s ease-in .25s, -webkit-transform .2s cubic-bezier(.6, .04, .98, .335)
}

.hamburger--emphatic-r .hamburger-inner:after {
  top: 10px;
  right: 0;
  transition: top .05s linear .2s, right .2s ease-in .25s, -webkit-transform .2s cubic-bezier(.6, .04, .98, .335);
  -webkit-transition: top .05s linear .2s, right .2s ease-in .25s, -webkit-transform .2s cubic-bezier(.6, .04, .98, .335);
  transition: transform .2s cubic-bezier(.6, .04, .98, .335), top .05s linear .2s, right .2s ease-in .25s;
  transition: transform .2s cubic-bezier(.6, .04, .98, .335), top .05s linear .2s, right .2s ease-in .25s, -webkit-transform .2s cubic-bezier(.6, .04, .98, .335)
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: transparent
}

.hamburger--emphatic-r.is-active .hamburger-inner:before {
  top: 80px;
  left: -80px;
  transition: left .2s ease-out, top .05s linear .2s, -webkit-transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  -webkit-transition: left .2s ease-out, top .05s linear .2s, -webkit-transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  transition: left .2s ease-out, top .05s linear .2s, transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  transition: left .2s ease-out, top .05s linear .2s, transform .2s cubic-bezier(.075, .82, .165, 1) .25s, -webkit-transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  -webkit-transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transform: translate3d(80px, -80px, 0) rotate(-45deg)
}

.hamburger--emphatic-r.is-active .hamburger-inner:after {
  top: 80px;
  right: -80px;
  transition: right .2s ease-out, top .05s linear .2s, -webkit-transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  -webkit-transition: right .2s ease-out, top .05s linear .2s, -webkit-transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  transition: right .2s ease-out, top .05s linear .2s, transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  transition: right .2s ease-out, top .05s linear .2s, transform .2s cubic-bezier(.075, .82, .165, 1) .25s, -webkit-transform .2s cubic-bezier(.075, .82, .165, 1) .25s;
  -webkit-transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transform: translate3d(-80px, -80px, 0) rotate(45deg)
}

.hamburger--slider .hamburger-inner {
  top: 2px
}

.hamburger--slider .hamburger-inner:before {
  top: 10px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  transition-property: opacity, -webkit-transform;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform
}

.hamburger--slider .hamburger-inner:after {
  top: 20px
}

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--slider.is-active .hamburger-inner:before {
  -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0
}

.hamburger--slider.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
  transform: translate3d(0, -20px, 0) rotate(-90deg)
}

.hamburger--slider-r .hamburger-inner {
  top: 2px
}

.hamburger--slider-r .hamburger-inner:before {
  top: 10px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  transition-property: opacity, -webkit-transform;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform
}

.hamburger--slider-r .hamburger-inner:after {
  top: 20px
}

.hamburger--slider-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
  transform: translate3d(0, 10px, 0) rotate(-45deg)
}

.hamburger--slider-r.is-active .hamburger-inner:before {
  -webkit-transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0
}

.hamburger--slider-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(90deg);
  transform: translate3d(0, -20px, 0) rotate(90deg)
}

.hamburger--spring .hamburger-inner {
  top: 2px;
  -webkit-transition: background-color 0s linear .15s;
  transition: background-color 0s linear .15s
}

.hamburger--spring .hamburger-inner:before {
  top: 10px;
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, -webkit-transform .15s cubic-bezier(.55, .055, .675, .19);
  -webkit-transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, -webkit-transform .15s cubic-bezier(.55, .055, .675, .19);
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, transform .15s cubic-bezier(.55, .055, .675, .19);
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, transform .15s cubic-bezier(.55, .055, .675, .19), -webkit-transform .15s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spring .hamburger-inner:after {
  top: 20px;
  transition: top .3s cubic-bezier(.33333, .66667, .66667, 1) .3s, -webkit-transform .15s cubic-bezier(.55, .055, .675, .19);
  -webkit-transition: top .3s cubic-bezier(.33333, .66667, .66667, 1) .3s, -webkit-transform .15s cubic-bezier(.55, .055, .675, .19);
  transition: top .3s cubic-bezier(.33333, .66667, .66667, 1) .3s, transform .15s cubic-bezier(.55, .055, .675, .19);
  transition: top .3s cubic-bezier(.33333, .66667, .66667, 1) .3s, transform .15s cubic-bezier(.55, .055, .675, .19), -webkit-transform .15s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spring.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  background-color: transparent
}

.hamburger--spring.is-active .hamburger-inner:before {
  top: 0;
  transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .32s;
  -webkit-transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .32s;
  transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, transform .15s cubic-bezier(.215, .61, .355, 1) .32s;
  transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, transform .15s cubic-bezier(.215, .61, .355, 1) .32s, -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .32s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--spring.is-active .hamburger-inner:after {
  top: 0;
  transition: top .3s cubic-bezier(.33333, 0, .66667, .33333), -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .32s;
  -webkit-transition: top .3s cubic-bezier(.33333, 0, .66667, .33333), -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .32s;
  transition: top .3s cubic-bezier(.33333, 0, .66667, .33333), transform .15s cubic-bezier(.215, .61, .355, 1) .32s;
  transition: top .3s cubic-bezier(.33333, 0, .66667, .33333), transform .15s cubic-bezier(.215, .61, .355, 1) .32s, -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .32s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
  transform: translate3d(0, 10px, 0) rotate(-45deg)
}

.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  -webkit-transition-duration: .15s;
  transition-duration: .15s
}

.hamburger--spring-r .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top .3s cubic-bezier(.33333, .66667, .66667, 1) .3s, opacity 0s linear;
  transition: top .3s cubic-bezier(.33333, .66667, .66667, 1) .3s, opacity 0s linear
}

.hamburger--spring-r .hamburger-inner:before {
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, -webkit-transform .15s cubic-bezier(.55, .055, .675, .19);
  -webkit-transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, -webkit-transform .15s cubic-bezier(.55, .055, .675, .19);
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, transform .15s cubic-bezier(.55, .055, .675, .19);
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .3s, transform .15s cubic-bezier(.55, .055, .675, .19), -webkit-transform .15s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spring-r.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg)
}

.hamburger--spring-r.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top .3s cubic-bezier(.33333, 0, .66667, .33333), opacity 0s linear .32s;
  transition: top .3s cubic-bezier(.33333, 0, .66667, .33333), opacity 0s linear .32s;
  opacity: 0
}

.hamburger--spring-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .32s;
  -webkit-transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .32s;
  transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, transform .15s cubic-bezier(.215, .61, .355, 1) .32s;
  transition: top .12s cubic-bezier(.33333, 0, .66667, .33333) .18s, transform .15s cubic-bezier(.215, .61, .355, 1) .32s, -webkit-transform .15s cubic-bezier(.215, .61, .355, 1) .32s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.hamburger--stand .hamburger-inner {
  transition: background-color 0s linear .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) .22s;
  -webkit-transition: background-color 0s linear .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) .22s;
  transition: transform .1s cubic-bezier(.55, .055, .675, .19) .22s, background-color 0s linear .1s;
  transition: transform .1s cubic-bezier(.55, .055, .675, .19) .22s, background-color 0s linear .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) .22s
}

.hamburger--stand .hamburger-inner:before {
  transition: top .1s ease-in .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) 0s;
  -webkit-transition: top .1s ease-in .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) 0s;
  transition: top .1s ease-in .1s, transform .1s cubic-bezier(.55, .055, .675, .19) 0s;
  transition: top .1s ease-in .1s, transform .1s cubic-bezier(.55, .055, .675, .19) 0s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) 0s
}

.hamburger--stand .hamburger-inner:after {
  transition: bottom .1s ease-in .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) 0s;
  -webkit-transition: bottom .1s ease-in .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) 0s;
  transition: bottom .1s ease-in .1s, transform .1s cubic-bezier(.55, .055, .675, .19) 0s;
  transition: bottom .1s ease-in .1s, transform .1s cubic-bezier(.55, .055, .675, .19) 0s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) 0s
}

.hamburger--stand.is-active .hamburger-inner {
  transition: background-color 0s linear .22s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) 0s;
  -webkit-transition: background-color 0s linear .22s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) 0s;
  transition: transform .1s cubic-bezier(.215, .61, .355, 1) 0s, background-color 0s linear .22s;
  transition: transform .1s cubic-bezier(.215, .61, .355, 1) 0s, background-color 0s linear .22s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) 0s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: transparent
}

.hamburger--stand.is-active .hamburger-inner:before {
  top: 0;
  transition: top .1s ease-out .12s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  -webkit-transition: top .1s ease-out .12s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  transition: top .1s ease-out .12s, transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  transition: top .1s ease-out .12s, transform .1s cubic-bezier(.215, .61, .355, 1) .22s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.hamburger--stand.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom .1s ease-out .12s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  -webkit-transition: bottom .1s ease-out .12s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  transition: bottom .1s ease-out .12s, transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  transition: bottom .1s ease-out .12s, transform .1s cubic-bezier(.215, .61, .355, 1) .22s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.hamburger--stand-r .hamburger-inner {
  transition: background-color 0s linear .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) .22s;
  -webkit-transition: background-color 0s linear .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) .22s;
  transition: transform .1s cubic-bezier(.55, .055, .675, .19) .22s, background-color 0s linear .1s;
  transition: transform .1s cubic-bezier(.55, .055, .675, .19) .22s, background-color 0s linear .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) .22s
}

.hamburger--stand-r .hamburger-inner:before {
  transition: top .1s ease-in .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) 0s;
  -webkit-transition: top .1s ease-in .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) 0s;
  transition: top .1s ease-in .1s, transform .1s cubic-bezier(.55, .055, .675, .19) 0s;
  transition: top .1s ease-in .1s, transform .1s cubic-bezier(.55, .055, .675, .19) 0s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) 0s
}

.hamburger--stand-r .hamburger-inner:after {
  transition: bottom .1s ease-in .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) 0s;
  -webkit-transition: bottom .1s ease-in .1s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) 0s;
  transition: bottom .1s ease-in .1s, transform .1s cubic-bezier(.55, .055, .675, .19) 0s;
  transition: bottom .1s ease-in .1s, transform .1s cubic-bezier(.55, .055, .675, .19) 0s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19) 0s
}

.hamburger--stand-r.is-active .hamburger-inner {
  transition: background-color 0s linear .22s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) 0s;
  -webkit-transition: background-color 0s linear .22s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) 0s;
  transition: transform .1s cubic-bezier(.215, .61, .355, 1) 0s, background-color 0s linear .22s;
  transition: transform .1s cubic-bezier(.215, .61, .355, 1) 0s, background-color 0s linear .22s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) 0s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  background-color: transparent
}

.hamburger--stand-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top .1s ease-out .12s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  -webkit-transition: top .1s ease-out .12s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  transition: top .1s ease-out .12s, transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  transition: top .1s ease-out .12s, transform .1s cubic-bezier(.215, .61, .355, 1) .22s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.hamburger--stand-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom .1s ease-out .12s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  -webkit-transition: bottom .1s ease-out .12s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  transition: bottom .1s ease-out .12s, transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  transition: bottom .1s ease-out .12s, transform .1s cubic-bezier(.215, .61, .355, 1) .22s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .22s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.hamburger--spin .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  -webkit-transition-duration: .3s;
  transition-duration: .3s
}

.hamburger--spin .hamburger-inner:before {
  -webkit-transition: top .1s ease-in .34s, opacity .1s ease-in;
  transition: top .1s ease-in .34s, opacity .1s ease-in
}

.hamburger--spin .hamburger-inner:after {
  transition: bottom .1s ease-in .34s, -webkit-transform .3s cubic-bezier(.55, .055, .675, .19);
  -webkit-transition: bottom .1s ease-in .34s, -webkit-transform .3s cubic-bezier(.55, .055, .675, .19);
  transition: bottom .1s ease-in .34s, transform .3s cubic-bezier(.55, .055, .675, .19);
  transition: bottom .1s ease-in .34s, transform .3s cubic-bezier(.55, .055, .675, .19), -webkit-transform .3s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transition-delay: .14s;
  transition-delay: .14s;
  -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg)
}

.hamburger--spin.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease-out, opacity .1s ease-out .14s;
  transition: top .1s ease-out, opacity .1s ease-out .14s;
  opacity: 0
}

.hamburger--spin.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom .1s ease-out, -webkit-transform .3s cubic-bezier(.215, .61, .355, 1) .14s;
  -webkit-transition: bottom .1s ease-out, -webkit-transform .3s cubic-bezier(.215, .61, .355, 1) .14s;
  transition: bottom .1s ease-out, transform .3s cubic-bezier(.215, .61, .355, 1) .14s;
  transition: bottom .1s ease-out, transform .3s cubic-bezier(.215, .61, .355, 1) .14s, -webkit-transform .3s cubic-bezier(.215, .61, .355, 1) .14s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg)
}

.hamburger--spin-r .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  -webkit-transition-duration: .3s;
  transition-duration: .3s
}

.hamburger--spin-r .hamburger-inner:before {
  -webkit-transition: top .1s ease-in .34s, opacity .1s ease-in;
  transition: top .1s ease-in .34s, opacity .1s ease-in
}

.hamburger--spin-r .hamburger-inner:after {
  transition: bottom .1s ease-in .34s, -webkit-transform .3s cubic-bezier(.55, .055, .675, .19);
  -webkit-transition: bottom .1s ease-in .34s, -webkit-transform .3s cubic-bezier(.55, .055, .675, .19);
  transition: bottom .1s ease-in .34s, transform .3s cubic-bezier(.55, .055, .675, .19);
  transition: bottom .1s ease-in .34s, transform .3s cubic-bezier(.55, .055, .675, .19), -webkit-transform .3s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spin-r.is-active .hamburger-inner {
  -webkit-transition-delay: .14s;
  transition-delay: .14s;
  -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg)
}

.hamburger--spin-r.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease-out, opacity .1s ease-out .14s;
  transition: top .1s ease-out, opacity .1s ease-out .14s;
  opacity: 0
}

.hamburger--spin-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom .1s ease-out, -webkit-transform .3s cubic-bezier(.215, .61, .355, 1) .14s;
  -webkit-transition: bottom .1s ease-out, -webkit-transform .3s cubic-bezier(.215, .61, .355, 1) .14s;
  transition: bottom .1s ease-out, transform .3s cubic-bezier(.215, .61, .355, 1) .14s;
  transition: bottom .1s ease-out, transform .3s cubic-bezier(.215, .61, .355, 1) .14s, -webkit-transform .3s cubic-bezier(.215, .61, .355, 1) .14s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg)
}

.hamburger--squeeze .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  -webkit-transition-duration: .1s;
  transition-duration: .1s
}

.hamburger--squeeze .hamburger-inner:before {
  -webkit-transition: top .1s ease .14s, opacity .1s ease;
  transition: top .1s ease .14s, opacity .1s ease
}

.hamburger--squeeze .hamburger-inner:after {
  transition: bottom .1s ease .14s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19);
  -webkit-transition: bottom .1s ease .14s, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19);
  transition: bottom .1s ease .14s, transform .1s cubic-bezier(.55, .055, .675, .19);
  transition: bottom .1s ease .14s, transform .1s cubic-bezier(.55, .055, .675, .19), -webkit-transform .1s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transition-delay: .14s;
  transition-delay: .14s;
  -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.hamburger--squeeze.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease, opacity .1s ease .14s;
  transition: top .1s ease, opacity .1s ease .14s;
  opacity: 0
}

.hamburger--squeeze.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom .1s ease, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .14s;
  -webkit-transition: bottom .1s ease, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .14s;
  transition: bottom .1s ease, transform .1s cubic-bezier(.215, .61, .355, 1) .14s;
  transition: bottom .1s ease, transform .1s cubic-bezier(.215, .61, .355, 1) .14s, -webkit-transform .1s cubic-bezier(.215, .61, .355, 1) .14s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg)
}

.hamburger--vortex .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  -webkit-transition-duration: .3s;
  transition-duration: .3s
}

.hamburger--vortex .hamburger-inner:after, .hamburger--vortex .hamburger-inner:before {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0s;
  transition-duration: 0s
}

.hamburger--vortex .hamburger-inner:before {
  -webkit-transition-property: top, opacity;
  transition-property: top, opacity
}

.hamburger--vortex .hamburger-inner:after {
  transition-property: bottom, -webkit-transform;
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
  transition-property: bottom, transform, -webkit-transform
}

.hamburger--vortex.is-active .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  -webkit-transform: rotate(765deg);
  transform: rotate(765deg)
}

.hamburger--vortex.is-active .hamburger-inner:after, .hamburger--vortex.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s
}

.hamburger--vortex.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0
}

.hamburger--vortex.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.hamburger--vortex-r .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  -webkit-transition-duration: .3s;
  transition-duration: .3s
}

.hamburger--vortex-r .hamburger-inner:after, .hamburger--vortex-r .hamburger-inner:before {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0s;
  transition-duration: 0s
}

.hamburger--vortex-r .hamburger-inner:before {
  -webkit-transition-property: top, opacity;
  transition-property: top, opacity
}

.hamburger--vortex-r .hamburger-inner:after {
  transition-property: bottom, -webkit-transform;
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
  transition-property: bottom, transform, -webkit-transform
}

.hamburger--vortex-r.is-active .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  -webkit-transform: rotate(-765deg);
  transform: rotate(-765deg)
}

.hamburger--vortex-r.is-active .hamburger-inner:after, .hamburger--vortex-r.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s
}

.hamburger--vortex-r.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0
}

.hamburger--vortex-r.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg)
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  background-color: $c-dblue;
}

.hamburger {
  padding: 10px;
  align-items: center;
  display: flex;
  z-index: 25;
  @include media($def) {
    display: none;
  }
}

@media (max-width: 375px) {
  .hamburger {
    padding: 4px;
  }
  .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before, .hamburger-box {
    width: 24px;
    @include media($md) {
      width: 30px;
    }
  }
}