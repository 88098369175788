.reviews-grid{
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
}
.review-item{
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 20px;
	position: relative;
	&:after{
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		background-color: $c-lblue;
		height: 1px;
		content: "";
	}
	&__top-row{
		margin-bottom: 10px;
		min-height: 52px;
		padding: 10px 0;
		border-top: 3px solid $c-lblue;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		border-bottom: 1px solid $c-lblue;
		@include media($md){
			flex-direction: row;
			align-items: center;
		}
	}
	&__title{
		font-size: 18px;
		line-height: 110%;
		color: $c-dblue;
		font-weight: 500;
		font-family: 'Museo Sans';
		margin-right: 34px;
	} 
	&--admin{
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 20px;
		&:after{
			display: none;
		}
	}
	&__text{
		font-size: 16px;
		line-height: 110%;
		font-weight: 300;
		font-family: 'Museo Sans';
	}
	&__date{
		color: $c-orange;
		font-weight: 100;
		letter-spacing: 0.2em;
		font-family: 'Museo Sans';
		font-size: 14px;
	}
}