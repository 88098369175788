.video-review{

	&__grid{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	&__item{
		width: 100%;
		height: 238px;
		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat;
		display: block;
		margin-bottom: 10px;
		position: relative;
		@include media(md){
			width: calc(50% - 5px);
		}
	}
	&__item-decor{
		width: 70px;
		height: 50px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		svg{
			width: 70px;
			height: 50px;
			fill: red;
			position: relative;
			z-index: 1;
		}
		&:after{
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			content: "";
			width: 30px;
			height: 30px;
			background-color: $c-white;
		}
	}
}