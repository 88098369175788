.item-tabs{
	width: 100%;
	padding-right: 0;
	margin-bottom: 20px;
	@include media($defp){
		width: calc(100% - 344px);
		margin-bottom: 0;
		padding-right: 3%;
	}
	@include media($lg){
		padding-right: 14.3%;
	}
	&__buttons{
		display: none;
		flex-wrap: wrap;
		border-bottom: 1px solid $c-blue;
		margin-bottom: 38px;
		@include media($md){
			display: flex;
			margin-bottom: 60px;
		}
	}
	&__title{
		margin-bottom: 20px;
		font-size: 20px;
		line-height: 100%;
		font-weight: 500;
		font-family: 'Museo Sans';
		color: $c-dblue;
		@include media($def){
			margin-bottom: 32px;
			font-size: 24px;
		}
		span{
			color: $c-black;
		}
	}
	&__button{
		min-height: 52px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		line-height: 100%;
		font-weight: 700;
		font-family: 'Museo Sans';
		color: $c-dblue;
		position: relative;
		transition: color 0.5s ease;
		margin-right: 0;
		border-bottom: 1px solid $c-blue;
		@include media($md){
			margin-right: 20px;
			border-bottom: 0;
		}
		@include media($lg){
			margin-right: 46px;
			font-size: 30px;
		}
		&:after{
			position: absolute;
			content: "";
			top: 100%;
			height: 2px;
			width: 100%;
			background-color: $c-orange;
			left: 0;
			opacity: 0;
		}
		&.is-active{
			color: $c-black;
			&:after{
				opacity: 1;
			}
		}
	}
}