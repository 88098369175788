.filter{
	// width: 40%;
	// min-width: 240px;
	width: 280px;
	position: fixed;
	flex-shrink: 0;
    background-color: $c-white;
    z-index: 25;
    transform: translateX(-400%) scale(0.9);
    opacity: 0.5;
    top: 60px;
    left: 0;
    padding: 10px;
    transition: transform 0.3s ease, z-index 0s 0.3s, opacity 0.3s;
    height: calc(100% - 70px);
    overflow: overlay;

	&__list{
		padding: 0;
		margin: 0;
		list-style-type: none;
	}



	@include media($def) {
		top: 0;
		position: relative;
		transform: translate(0);
    	height: auto;
		opacity: 1;
		padding: 0;
	}
	&__items-list{
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
	&.is-active{
		transform: translateY(0);
		z-index: 25;
		opacity: 1;
		transition: transform 0.3s ease, z-index 0s 0s, opacity 0.3s;
	}
	&__item-label{
		&.disabled{
			text-decoration: none;
			display: flex;
			align-items: center;
		}
	}
	&__show-more{
		display: flex;
		align-items: center;
		cursor: pointer;
		color: $c-black;
		font-family: 'Museo Sans';
		font-weight: 100;
		transition: color 0.3s ease;

		&:hover{
			color: $c-orange;
		}
	}
	
	&__show-more-icon{
		width: 20px;
		height: 20px;
		margin-right: 18px;
		font-size: 30px;
		font-weight: 100;
		line-height: 58%;
		padding-left: 2px;
		color: $c-lblue;
	}



	&__item-link{
		text-decoration: none;
		display: flex;
		align-items: center;
		&:hover{
			ins{
				border-color: $c-dblue;
			}
			.filter__item-text{
				color: $c-dblue;
			}
		}
	}
	&__item-input{
		display: none;
	}
	&__item-text{
		color: $c-black;
		transition: color 0.3s ease;
		text-decoration: none;


		font-size: 16px;
		font-family: 'Museo Sans';
		font-weight: 100;
		line-height: 110%;
	}
	&__item-ins{
		width: 20px;
		height: 20px;
		min-width: 20px;
		min-height:20px;
		border-radius: 4px;
		border: 1px solid $c-lblue;
		margin-right: 18px;
		display: block;
		position: relative;
		transition: border-color 0.3s ease;
	}
	&__group{
		margin-bottom: 32px;
		padding:0px 10px;
		@include media($def){
			padding:0;
		}

		&.is-active{
			.filter__title{
				svg{
					transform: scale(1);
				}
			}
		}
	}
	&__title{
		padding: 18px 0;
		color: $c-black;
		margin-bottom: 12px;
		border-bottom: 2px solid $c-lblue;
		border-top: 2px solid $c-lblue;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: color 0.3s ease;


		font-weight: 700;
		font-family: 'Museo Sans';
		text-transform: uppercase;
		font-size: 14px;
		line-height: 110%;

		svg{
			width: 16px;
			height: 12px;
			fill: $c-lblue;
			margin-right: 2px;
			transform: scale(-1);
			transition: transform 0.3s ease;
		}

		&:hover{
			color: $c-orange;
		}
	}
	&__reset-button{
		text-decoration: none;
		position: relative;

		svg{
			width: 28px;
			height: 28px;
			margin-right: 10px;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto 0;
			right: 2px;
		}
		span{
			text-decoration: none;
		}
		&:hover{
			svg{
				fill: $c-lblue;
			}
		}
		&.js-loading{
			svg{
				animation: spin 5s infinite;
			}
		}
	}
	&__item{
		margin-bottom: 10px;
		&.is-active{
			.filter__item-text{
				text-decoration: underline;
			}
			.filter__item-ins{
		    	border: 2px solid $c-lblue;
				
				&:after{
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					margin: auto;
					width: 12px;
					height: 12px;
					background-color: $c-dblue;
					border-radius: 3px;
					content: "";
				}
			}

		}
		&:hover{
			.filter__item-text{
				color: $c-orange;
			}
			.filter__item-ins{
		    	border-color: $c-lblue;
			}
			.filter__item-label.disabled{
				opacity:0.6;
			}
		}
		.filter__item-label.disabled{
			opacity:0.6;
			.filter__item-text{
				color: #000;
			}
		}
	}
}

@keyframes spin {
  0%   { transform: rotate(0); }
  100% { transform: rotate(720deg); }
}