@font-face {
	font-family: 'Museo Sans';
	src: url('Media/css/fonts/subset-MuseoSansCyrl-100.woff2') format('woff2'),
		url('Media/css/fonts/subset-MuseoSansCyrl-100.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans';
	src: url('Media/css/fonts/subset-MuseoSansCyrl-900.woff2') format('woff2'),
		url('Media/css/fonts/subset-MuseoSansCyrl-900.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans';
	src: url('Media/css/fonts/subset-MuseoSansCyrl-300.woff2') format('woff2'),
		url('Media/css/fonts/subset-MuseoSansCyrl-300.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans';
	src: url('Media/css/fonts/subset-MuseoSansCyrl-700.woff2') format('woff2'),
		url('Media/css/fonts/subset-MuseoSansCyrl-700.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans';
	src: url('Media/css/fonts/subset-MuseoSansCyrl-500.woff2') format('woff2'),
		url('Media/css/fonts/subset-MuseoSansCyrl-500.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Playfair Display';
	src: url('Media/css/fonts/subset-PlayfairDisplay-Regular.woff2') format('woff2'),
	url('Media/css/fonts/subset-PlayfairDisplay-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Playfair Display';
	src: url('Media/css/fonts/subset-PlayfairDisplay-Italic.woff2') format('woff2'),
	url('Media/css/fonts/subset-PlayfairDisplay-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}