

// style
.range-slider{
	width: 100%;
    padding-top: 30px;
	&__slider-wrapper{
		margin-bottom: 28px;
        width: calc(100% - 30px);
        margin-left: 15px;
	}
	&__slider{
		width: 100%;
	}
}
.range-slider-sort{
	display: flex;
	align-items: center;
    justify-content: space-between;
	&__type{
		outline: none;
		width: 82px;
        text-align: center;
		font-size: 18px;
		display: inline-block;
		font-weight: 500;
		font-family: 'Museo Sans';
		color: $c-black;
        border: 1px solid $c-lblue;
        border-radius: 10px;
        height: 50px;
        transition: color 0.3s ease;
        @include media($def){
          width: 96px;
        }
            &:focus{
                color: $c-orange;
            }
	}
    &__range-button{
        .button__content{
            width: 62px;
            height: 50px;
        }
    }
	&__text,
	&__del{
		color: grey;
		font-weight: 500;
		font-family: 'Museo Sans';
		font-size: 16px;
	}
	&__del{
		margin: 0 6px;
	}
}
.noUi-handle{
	cursor: pointer;
	width: 30px;
	height: 30px;
	background-color: $c-lgrey;
	top: -15px;
	left: -15px;
	border-radius: 100%;
	outline: none;
	transition: background-color 0.5s ease;
	&:hover{
		background-color: $c-orange;
	}
}
.noUi-connect{
	background-color: $c-orange;
	height: 1px;
}
.noUi-horizontal,
.noUi-vertical{
  height: 1px;
}
.noUi-target {
    background: $c-dblue;
    border: 1px solid $c-dblue;
}












// basic
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  /* Fix 401 */
}
.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-origin {
  position: absolute;
  height: 0;
  width: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Styling;
 */
.noUi-target {
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}
.noUi-connect {
  -webkit-transition: background 450ms;
  transition: background 450ms;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-active {
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}
.noUi-marker-sub {
  background: #AAA;
}
.noUi-marker-large {
  background: #AAA;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate3d(-50%, 50%, 0);
  transform: translate3d(-50%, 50%, 0);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  padding-left: 25px;
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
