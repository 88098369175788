.to-top {
    position: fixed;
    bottom: 10%;
    right: 5%;
    z-index: 255;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s cubic-bezier(1,.15,1,.01), opacity 0.3s ease;
    border-radius: 100%;
    display: none;
    transform: scale(0);
    transform-origin: center;
    backface-visibility: hidden;
    opacity: 0;

    @include media($md){
        display: flex;
    }
    
    &.js-active {
        transform: scale(1);
        opacity: 1;
        transition: transform 0.5s cubic-bezier(0.78, 0.77, 0.74, 1.15), opacity 0.5s ease;
    }
    .to-top__wrapper{
        background-color: $c-orange;
        border-radius: 100%;
        transition: background-color 0.5s ease,transform 0.2s ease;
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
        width: 50px;
        height: 50px;
        transform-origin: center;
        transform: rotate(-90deg);

        &:hover{
            transform: scale(0.9) rotate(-90deg);
            background-color: lighten($c-orange, 10%);
        }
    }
    &__image{
        fill: $c-white;
        stroke: $c-white;
        max-width: 60%;
    }
}
.to-top__border {
    border: 2px solid $c-orange;
    background-color: transparent;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    transition: transform 0.5s ease;
    &:hover{
        animation: arrow-to-top 1s linear infinite;
    }
}
@keyframes arrow-to-top {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    50% {
        opacity: 1;
        transform: scale(1.15);
    }

    100% {
        opacity: 0;
        transform: scale(1.3);
    }
}



.show-more{
    position: absolute;
    top: -60px;
    right: 32px;
    z-index: 15;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.5s cubic-bezier(0.78, 0.77, 0.74, 1.15), opacity 0.5s ease;
    border-radius: 100%;
    display: flex;
    transform: scale(1);
    transform-origin: center;
    backface-visibility: hidden;
    opacity: 1;
    outline: none;
    border: none;
    background: none;

    .show-more__wrapper{
        background-color: $c-blue;
        border-radius: 100%;
        transition: background-color 0.5s ease,transform 0.2s ease;
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
        width: 30px;
        height: 30px;
        transform-origin: center;
        @include media($sm){
            width: 50px;
            height: 50px;
        }

        &:hover{
            transform: scale(0.9);
            background-color: lighten($c-blue, 10%);
        }
    }
    &__image{
        fill: $color-white;
        max-width: 80%;
    }
}
.show-more__border {
    border: 2px solid $c-blue;
    background-color: transparent;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    transition: transform 0.5s ease;
    &:hover{
        animation: arrow-to-top 1s linear infinite;
    }
}



