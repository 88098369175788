////
/// @group _partials/view-size.scss
////


// scss
// ================================

.view-size {
	position: relative;
	max-width: 1600px;
	max-width: calc(1600px + 3rem);
	padding: 0 1.5rem;
	margin: 0 auto;
}

// отображение
.view-size {
	&--no-gap {
		max-width: 1600px;
	}
	&--md{
		max-width: 1320px;
		max-width: calc(1320px + 3rem);
	}

	&--sm{
		max-width: 1020px;
		max-width: calc(1020px + 3rem);
	}
	&--no-pd{
		padding: 0;
	}
}
