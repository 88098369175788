////
/// @group style.scss
////


// Config
// ================================
	@import 'config';


// Фикс mqpacker
// ================================ 
	@include mqPacker;


// Normalize
// ================================

	@include normalize__document;
	@include normalize__sections;
	@include normalize__grouping-content;
	@include normalize__text-level-semantics;
	@include normalize__embedded-content;
	@include normalize__forms;
	@include normalize__interactive;
	@include normalize__scripting;
	@include normalize__hidden;

	@include normalize__text-level-semantics__psevdo-classes;
	@include normalize__forms__psevdo-classes;
	@include normalize__addons;


// Import Partials
// ================================
	// основа
	@import '_partials/base';

	// структура
	@import '_partials/view-wrapper';
	@import '_partials/view-size';
	@import '_partials/view-section';
	@import '_partials/view-header';
	@import '_partials/view-footer';

	// hidden
	@import '_partials/mfp-zoom-in';
	@import '_partials/hidden-wrapper';

	// контент
	@import '_partials/view-text';
	@import '_partials/table-wrapper';
	@import '_partials/content-image';
	@import '_partials/title';

	// ui
	@import '_partials/breadcrumbs'; 
	@import '_partials/sitemap';  
	//@import '_partials/buttons';
	@import '_partials/buttons-default'; 
	@import '_partials/pagination.scss';
	@import '_partials/form';
	@import '_partials/fPopUp'; 
    //custom
	@import '_partials/_buttons';
	@import '_partials/_fonts';
	@import '_partials/_catalog-nav';
	@import '_partials/_hamburger';
	@import '_partials/_jq-select';
	@import '_partials/_filter'; 
	@import '_partials/_range-slider';
	// item-page
	@import '_partials/item-page/_item-slider';
	@import '_partials/item-page/_item-tabs';
	@import '_partials/item-page/_reviews';
	@import '_partials/item-page/_video-review';
	@import '_partials/_cart';
	@import '_partials/_resp-tabs';
	@import '_partials/_simple-tabs';
	@import '_partials/_enter-reg';
	@import '_partials/_order';
	@import '_partials/_to-top-arrow';
	@import '_partials/lk-orders';

// Grid
// ================================
//	@include grid-full;


// Include Utils
// ================================
	@include util-colors;
	@include util-bgcolors;
	@include util-fills;

	@include util-floats;
	@include util-floats-mq;

	@include util-texts;
	@include util-texts-mq;

	@include util-visibility;
	@include util-visibility-mq;

	@include util-gutters;
	@include util-gutters-mq;


// Custom css
// ================================

.catalog{
	display: flex;
}
.catalog-grid{
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding-left: 0;
	margin-bottom: 60px;
	justify-content: center;
	@include media($def){
		justify-content: flex-start;
		width: calc(100% - 280px);
		padding-left: 30px;
	}
}
.js-acordeon-button{
	cursor: pointer;
	user-select: none;
}
.one-click-buy{
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 0.1em;
	line-height: 18px;
	color: $c-dark;
	cursor: pointer;
	text-transform: uppercase;
	text-align: center;
	margin-top: 30px;
	transition: color 0.3s ease;

	span{
		border-bottom: 1px dotted $c-dark;
	}
	&:hover{
		color: $c-orange;
	}
}
.bg_decor{
	font-family: 'Playfair Display';
	color: $c-lblue;
	position: absolute;
	font-size: 100px;
	opacity: 0.3;
	font-style: italic;
	right: 38px;
	bottom: 2px;
}
.js-filter-handler{
	display: flex;

	@include media(def){
		display: none;
	}
}
.search-form{
	opacity: 0;
	visibility: hidden;
	transform: translateX(300px);
	position: fixed;
	top: 60px;
	left: 100%;
	transition: opacity 0.3s 0s ease, transform 0.3s 0.1s ease-out, visibility 0s 0.6s ease;
	@include media($def){
		left: calc(220px + 5%);
		top: 10px;
		position: absolute;
	}

	.form__group{
		margin:0;
	}
	.control-holder--text{
		margin-bottom: 0;
	}
	input{
		padding-left: 42px;
	}
	&__btn{
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto 0;
		background-color: transparent;
		border: none;
		outline: none;
		right: 64px;
		cursor: pointer;
		transition: color 0.3s ease;

		&:hover{
			color: $c-orange;
		}
	}
	&__decor{
		width: 24px;
		height: 24px;
		position: absolute;
		top: 2px;
		bottom: 0;
		margin: auto 0;
		left: 14px;
		z-index: 5;

		svg{
			width: 24px;
			height: 24px;
			fill: $c-dblue;
		}
	}
	&__close-btn{
		position: absolute;
		right: 14px;
		top: 2px;
		bottom: 0;
		margin: auto 0;
		width: 26px;
		height: 26px;
		z-index: 5;
		cursor: pointer;

		svg{
			width: 22px;
			height: 22px;
			fill: $c-dblue;
			transition: fill 0.3s ease;
		}
		&:hover{
			svg{
				fill: $c-orange;
			}
		}
	}
	&.is-active{
		visibility: visible;
		opacity: 1;
		left: 0;
		transform: translateX(0);
		transition: opacity 0.8s 0.2s ease, transform 0.3s 0.1s ease-out, visibility 0s 0s ease;

		@include media($def){
			left: calc(220px + 5%);
		}
	}
}
.header-search-button{
	width: 36px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	cursor: pointer;
	margin-left: auto;
	opacity: 1;
	visibility: visible;
	transition: opacity 0.3s ease, visibility 0s 0.3s ease;
	@include media($def){
		width: 38px;
		height: 38px;
	}
	&.is-active{
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.3s 0.1s ease, visibility 0s 0s ease;
	}
	svg{
		width: 23px;
		height: 24px;
		fill: $c-dblue;
		@include media($def){
			width: 25px;
			height: 26px;
		}
	}
	&:hover{
		svg{
			fill: $c-orange;
		}
	}
}
.js-search-button{
}

.main-nav{
	margin: 0 0 0 5%;
	padding: 0;
	width: calc(95% - 280px);
	list-style-type: none;
	list-style-image: url(data:0);
	display: none;
	align-items: center;
	@include media($def){
		display: flex;
	}
	&__item{
		padding: 0 12px;
		height: 50px;
		display: flex;
		transition: opacity 0.3s, transform 0.3s ease;
		white-space: nowrap;
	}
	&__link{
		//color: $c-dblue;
		color:#445568;
		text-transform: uppercase;
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		position: relative;
		font:{
			size: 14px;
			family:'Museo Sans';
			weight:600;
		}
		
		&:after{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: $c-blue;
			content: "";
			display: none;
		}
		
		&.is-active{
			pointer-events: none;
			color: $c-dark;
			
			&:after{
				display: block;
			}
		}
	}
	&.js-disabled{
		.main-nav{
			&__item{
				opacity: 0;
				transition: opacity 0.6s, transform 0.5s ease;
				transform: rotateY(-45deg);
			}
		}
	}
}

.is-disabled{
	display: none;
}

.nav-halper{
	display: none;
	position: relative;
	width: 40px;
	height: 40px;
	margin-right: 20px;

	&__burger{
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		cursor: pointer;
		transform: rotate(90deg);
		transition: background-color 0.3s ease;

		svg{
			width: 25px;
			height: 25px;
			fill: $c-dark;
			transition: fill 0.3s ease;
		}

		&:hover{
			svg{
				fill: $c-orange;
			}
		}
		&.is-active{
			background-color: #ff7e2a;
			svg{
				fill: $c-white;
			}
		}
	}
	&__items{
		position: absolute;
		right: 0;
		top: 100%;
		background-color: #ff7e2a;
		transform-origin: 100% 0%;
		transform: scale(0.6);
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;

		&.is-active{
			transform: scale(1);
			opacity: 1;
			visibility: visible;
			transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
		}
	}

	.main-nav__link{
		color: $c-white;
		&.is-active{
			&:after{
				top:auto;
				bottom:15px;
				background-color: #fff;
			}
		}
	}

	&.is-active{
		display: none;
		@include media($def){
			display: block;
		}
	}
}

.menu-button{
	width: 100%;
	height: 100%;
	background-color: transparent;
	border: none;
	outline: none;
	border: 2px solid $c-orange;
	.button__content{
		color: $c-black;
	}
}

.header-widget{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	margin: 0 8px;

	@include media($ms){
		margin: 0 10px;
	}

	@include media($xxl){
		margin: 0 20px;
	}
	&:nth-child(1){
		.header-widget{
			&__image{
				svg{
					width: 26px;
					height: 32px;
				}
			}
		}
	}
	&:nth-child(2){
		.header-widget{
			margin:0px 3px;
			@include media($ms){
				margin: 0 10px;
			}
		}
	}
	&--cart{
		margin-right: 0;
	}
	&__image{
		margin-bottom: 0;
		height: 34px;
		width: 34px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		&--human{
			text-align:center;
			svg{
				stroke:transparent !important;
				fill:#445568;
			}
		}
		&--cart{
			svg{
				width: 34px;
				height: 28px;
				margin-left: -2px;
				@include media($def){
					margin-left: -10px;
				}
			}
		}
		&--favorites{
			svg{
				width: 32px;
				height: 34px;
				fill:transparent !important;
				stroke:#445568;
				margin-left: -4px;
				stroke-width: 3px;
				@include media($def){
					margin-left: -10px;
				}
			}
		}
		@include media($def){
			margin-bottom: 8px;
		}
		@include media($xxl){
			height: 34px;
			width: 34px;
			margin-bottom: 18px;
		}
		svg{
			fill: $c-dblue;
		}
	}
	&__num{
		position: absolute;
		width: 24px;
		height: 24px;
		border-radius: 100%;
		background-color: $c-lgrey;
		font-size: 12px;
		font-family: 'Museo Sans';
		font-weight: 700;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		top: -7px;
		right: -10px;
		color: $c-white;
		transition: background-color 0.3s ease;
		@include media($def){
			width: 30px;
			height: 30px;
			font-size: 16px;
			right: -18px;
		}
	}
	&__text{
		color: $c-lgrey;
		text-transform: lowercase;
		font-weight: 100;
		font-family: 'Museo Sans';
		font-size: 14px;
		display: none;
		transition: color 0.3s ease;
		@include media($def){
			display: block;
		}
	}

	&:hover{
		.header-widget{
			&__text{
				color: #000;
			}
			&__num{
				background-color: $c-orange;
			}
		}
	}
}

.phones-block{
	display: flex;
	position: relative;

	&__resp-content{
		position: absolute;
		left: 0;
		top: 100%;
		min-width: 260px;
		opacity: 0;
		visibility: hidden;
		transform-origin: top;
		transform: scale(0.87);
		transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s 0.3s ease;
		
		@include media($xxl){
		    position: relative;
			top: 0;
			width: auto;
			opacity: 1;
			visibility: visible;
			transform: scale(1);
		}

		&.is-active{
			opacity: 1;
			visibility: visible;
			transform: scale(1);
			transition: opacity 0.3s 0.1s ease, transform 0.3s 0.1s ease, visibility 0s ease;
		}
	}
	&__resp-btn{
		display: flex;
		width: 20px;
		height: 20px;
		justify-content: center;
		align-items: center;
		align-content: center;
		cursor: pointer;

		@include media($xxl){
			display: none;
		}
		svg{
			width: 14px;
			height: 14px;
			fill: $c-dark;
		}
		&:hover{
			svg{
				fill: $c-orange;
			}
		}
	}
	&__item {
		margin-bottom: 10px;
		font-weight: 500;
		font-size: 18px;
		color: $c-dark;
		margin-right: 20px;
		position: relative;
		text-decoration: none;
		display: inline-block;
		font-family: 'Museo Sans';
		background-color: $c-white;

		svg{
			width: 20px;
			height: 20px;
			position: absolute;
			left: 0;
			top: 0;
			fill: $c-purple;
		}
		&--viber{
			padding-left: 22px;
		}
	}
}

.addres-block{
	margin-bottom: 16px;

	&__group{
		font-size: 14px;
		font-weight: 400;
		color: $c-dark;
		font-family: 'Museo Sans';
	}
}

.graph-work{
	&__group{
		color: $c-blue;
		font-size: 14px;
		font-weight: 400;
		font-family: 'Museo Sans';
		margin-right: 10px;
	}
}
.mm-hasnavbar-top-2 .mm-panels, .mm-navbar-top-3{
	top: 80px;
	@include media($ms){
		top: 110px;
	}
}
.mm-navbar-top-1{
	height: 80px;
	@include media($ms){
		height: 110px;
	}
}
.menu_logo{
	padding: 0;
	height: 80px;
	@include media($ms){
		height: 110px;
	}
	img{
		height: 80px;
		@include media($ms){
			height: 110px;
		}
	}
}





.web-service{
	display: flex;
	flex-direction: column;
	padding-bottom: 20px;
	min-width: 179px;
	&__text{
		font-weight: 100;
		font-size: 12px;
		letter-spacing: 0.17em;
	}
}
.wezom{
	display: flex;
	align-items: center;
	text-decoration: none;
	margin-bottom: 20px;

	&__logo-holder{
		width: 35px;
		height: 35px;
		background-color: #f22625;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		margin-right: 12px;
		transition: border-radius 0.5s ease, margin-right 0.5s ease;

		svg{
			width: 28px;
			height: 10px;
			fill: $c-white;
		}
	}
	&__text{
		font-size: 18px;
		color: $c-black;
		font-weight: 700;
		transition: color 0.3s ease;
	}
	&:hover{
		.wezom{
			&__logo-holder{
				border-radius: 100%;
				margin-right: 6px;
			}
			&__text{
				color: #f22625;
			}
		}
	}
}
.footer-list{
	list-style-type: none;
	padding: 0;
	margin: 0;
	&__item{
		margin-bottom: 14px;
	}
	&__link{
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		font-weight: 700;
		color: $c-dblue;
		text-decoration: none;
		@include media($lg){
			font-size: 12px;
		}
	}
	&--categorys{
		columns: 2;
	}
}
.pays-block{
	display: flex;
	align-items: center;
	flex-direction: column;
	@include media($lg){
		flex-direction: row;
	}
	
	&__text{
		margin-right: 0;
		font-size: 16px;
		white-space: nowrap;
		color: $c-dblue;
		font-weight: 700;
		margin-bottom: 10px;
		@include media($def){
			font-size: 14px;
			margin-right: 15px;
		}
		@include media($lg){
			font-size: 18px;
			margin-bottom: 0;
		}
	}
	&__items{
		display: flex;
		align-items: center;
	}
	&__item{
		margin: 0 15px;
		img{
			max-height: 40px;
			max-width: 106px;
		}
	}
}
.news-item{
	width: 100%;
	position: relative;
	height: auto;
	text-decoration: none;
	@include media($ms){
		height: 245px;
	}

	&--main{
		width: 100%;
		height: auto;
		@include media($ms){
			height: 390px;
		}
		@include media($defp){
			width: 50%;
			height: 490px;
		}

		.news-item{
			&__image{
				width: 100%;
				left: 0;
				height: 240px;
				@include media($ms){
					height: 100%;
				}
			}
			&__descr{
				width: 100%;
				padding: 10px;
				position: relative;
				left: 0;
				bottom: 0;
				height: auto;
				top: auto;
			    //display: flex;
			    //align-items: flex-start;
			    //flex-direction: column;
			    //justify-content: flex-end;
			    background-color: #fff;
			    height: auto;
				z-index:3;
				@include media($ms){
					padding: 50px;
					background-color: transparent;
					position: absolute;
				}
			}
			&__text{
				color: $c-black;
				font-size: 18px;
				margin-bottom: 18px;
				line-height: 100%;
				@include media($ms){
					color: $c-white;
					font-size: 30px;
				}
				@include media($md){
					font-size: 20px;
					margin-bottom: 38px;
				}
				@include media($lg){
					font-size: 36px;
				}
			}
		}
	}
	.button{
		display: none;
		@include media($ms){
			display: inline-block;
		}
	}
	&__descr{
		width: 100%;
		position: relative;
		top: 0;
		height: auto;
		padding: 10px;
		@include media($ms){
			position: absolute;
			height: 100%;
			width: 50%;
			padding: 40px 30px;
		}
	}
	&__data{
		font-weight: 100;
		letter-spacing: 0.2em;
		color: $c-orange;
		font-size: 14px;
		margin-bottom: 12px;
	}
	&__text{
		color: $c-dark;
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
		text-decoration: none;
		@include media($lg){
			font-size: 20px;
			line-height: 24px;
		}

		i{
			font-weight: normal;
			font-family: 'Playfair Display';
		}
	}
	&__image{
		position: relative;
		top: 0;
		width: 100%;
		overflow: hidden;
		height: 240px;
		@include media($ms){
			width: 50%;
			height: 100%;
			position: absolute;
		}

		&:after{
			position: absolute;
			left: 0;
			content: "";
			top: 0;
			width: 100%;
			height: 100%;
			background-color: #000;
			opacity: 0;
			transition: opacity 0.4s ease;
		}
	}
	&__image-holder{
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		position: absolute;
		left: 0;
		width: 100%;
		top: 0;
		height: 100%;
		transition: transform 0.6s ease;
		transform: scale(1);
	}
	&:hover{
		.news-item{
			&__image{
				&:after{
					opacity: 0.2;
				}
			}
			&__image-holder{
				transform: scale(1.1);
			}
		}
	}
}
.news-item-group{
	display: flex;
	flex-direction: column;
	@include media($defp){
		flex-direction: row;
	}
}
.news-item-double{
	display: flex;
	flex-direction: column;
	width: 100%;
	@include media($defp){
		width: 50%;
	}
	.news-item{
		&:nth-child(1){
			.news-item{
				&__image{
					left: 0;
				}
				&__descr{
					right: 0;
					background-color: #ffffff;
				}
			}
		}
		&:nth-child(2){
			.news-item{
				&__image{
					right: 0;
				}
				&__descr{
					left: 0;
					background-color: #eef7fb;
				}
			}
		}
	}
}
.inner-news{
	&__image{
		margin-bottom: 30px;
	}
}
._mgb40{
	margin-bottom: 40px;
}
._mgb50{
	margin-bottom: 50px;
}
.map{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
.contacts{
	margin-bottom: 70px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	textarea{
		min-height: 48px !important;
		height: 50px;
	}
	
	&__title{
		padding-bottom: 12px;
		font-size: 18px;
		font-weight: 500;
		line-height: 100%;
		color: $c-dblue;
		padding-top: 4px;
		border-bottom: 1px solid $c-lblue;
		margin-bottom: 20px;
		height: 41px;
		width: 100%;
		@include media($md){
			font-size: 22px;
		}
		@include media($def){
			margin-bottom: 60px;
			font-size: 24px;
		}
	}
	&__numbers-block{
		display: flex;
		justify-content: space-between;
		padding-right: 0;
		flex-direction: column;
		@include media($ms){
			flex-direction: row;
		}
		@include media($md){
			padding-right: 18%;
		}
	}
	&__numbers-group{
		margin-bottom: 20px;
		@Include media($def){
			margin-bottom: 60px;
		}
	}
	&__number{
		font-size: 18px;
		font-weight: 500;
		color: $c-dark;
			margin-bottom: 8px;
		text-decoration: none;
		display: inline-block;

		@include media($md){
			font-size: 24px;
		}
		svg{
		    width: 20px;
		    height: 22px;
		    fill: #ae3ddd;
		}
	}
	&__numbers-title{
		font-size: 16px;
		color: $c-dark;
		font-weight: 100;
		margin-bottom: 24px;
	}
	&__times{
		color: $c-blue;
		font-weight: 100;
		font-size: 16px;
		margin-bottom: 18px;
	}
	&__mail{
		color: $c-dark;
		font-size: 16px;
		font-weight: 100;
		margin-bottom: 56px;
		a{
			text-decoration: none;
			color: inherit;

			&:hover{
				color: $c-orange;
			}
		}
	}
	&__addres{
		font-weight: 100;
		font-size: 16px;
		color: $c-dark;
		margin-bottom: 16px;
	}
	&__info{
		width: 100%;
		padding-right: 0;
		border-right: 0;
		margin-bottom: 20px;
		@include media($lg){
			width: 50%;
			padding-right: 5.5%;
			margin-bottom: 0;
			border-right: 1px solid $c-lblue;
		}
	}
	&__map{
		width: 100%;
		padding-left: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: stretch;
		@include media($lg){
			width: 50%;
			padding-left: 5.5%;
		}
	}
	&__form-group{
		display: flex;
		justify-content: space-between;
		width: 100%;
		flex-wrap: wrap;
		.form__group{
			width: 100%;
			@include media($ms){
				width: calc(50% - 10px);
			}
		}
	}
	&__map-wrapper{
		height: calc(100% - 101px);
		width: 100%;
		position: relative;
		min-height: 300px;
	}
	&__text{
		font-weight: 100;
		font-size: 16px;
		display: block;
		color: $c-dblue;
		margin-bottom: 20px;
	}
}
.ninja{
	color: black;
	visibility: hidden;
}
.page404{

	&__top-row{
		padding-bottom: 30px;
		padding-top: 10px;
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include media($md){
			padding-bottom: 80px;
		}
	}
	&__text{
		max-width: 500px;
		text-align: center;
		margin-bottom: 30px;
		font-weight: 100;
		font-size: 16px;
		line-height: 22px;
		color: $c-dblue;
		text-align: center;
		@include media($md){
			margin-bottom: 130px;
			font-size: 18px;
			line-height: 24px;
		}
	}
	&__subtext{
		margin-bottom: 20px;
		font-size: 20px;
		line-height: 26px;
		font-weight: 500;
		color: $c-dblue;
		text-align: center;
		@include media($md){
			margin-bottom: 70px;
			font-size: 30px;
			line-height: 36px;
		}
	}
	&__error{
		font-weight: 700;
		font-size: 26px;
		color: $c-dblue;
		text-align: center;
		margin-bottom: 26px;
		@include media($md){
			font-size: 36px;
		}
	}
	&__bg{
		color: #ecf6fb;
		font-size: 250px;
		font-family: 'Playfair Display';
		font-style: italic;
		position: absolute;
		left: 0;
		right: 0;
		bottom: -20px;
		margin: 0 auto;
		text-align: center;
		z-index: -1;
		@include media($md){
			font-size: 500px;
			left: 60px;
		}
	}
}
.prev-next-item{
	height: 40px;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	@include media($ms){
		height: 60px;
		width: auto;
	}
	@include media($def){
		height: 100px;
	}

	&__link{
		display: flex;
		align-items: center;
		text-transform: uppercase;
		text-decoration: none;
		color: $c-dblue;
		font-size: 12px;
		@include media($def){
			font-size: 14px;
		}

		svg{
			width: 14px;
			height: 22px;
			fill: $c-lgrey;
		}
		&:hover{
			svg{
				filL: $c-lblue;
			}
		}
	}
	&--center{
		padding: 0 25px;
		border-left: 1px solid $c-lblue;
		border-right: 1px solid $c-lblue;
		max-width: 280px;
		margin:0 auto;
		@include media($def){
			padding: 0 50px;
		}
	}
	&--prev{
		.prev-next-item{
			&__link{
				svg{
					margin-right: 12px;
				}
			}
		}
	}
	&--next{
		.prev-next-item{
			&__link{
				svg{
					margin-left: 12px;
				}
			}
		}
	}
}
.prev-next-block{
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	@include media($ms){
		flex-direction: row;
	}
	&__column{
		width: 100%;
		flex-basis:100%;
		max-width:100%;
		@include media($ms){
			width: 33%;
			flex-basis:33%;
			max-width:33%;
		}
	}

}
.add-this-widget-wrapper{
	margin-bottom: 52px;
}
.page-subtitle{
	position: relative;
	z-index:2;
	color: $c-dblue;
	font-size: 22px;
	line-height: 100%;
	font-weight: 700;
	text-align: center;
	@include media($sm){
		font-size: 28px;
	}
	@include media($defp){
		font-size: 36px;
	}
	i{
		display: inline-block;
		font-weight: normal;
		font-family: 'Playfair Display';
	}
}
._mgb36{
	margin-bottom: 20px;
	@include media($defp){
		margin-bottom: 36px;
	}
}
.seo-text{
	padding: 60px 0;
}
.more-btn{
	display: inline-flex;
	padding: 10px;
	text-decoration: none;
	position: relative;
	z-index: 15;

	&__text{
		color: $c-dblue;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		font-weight: 700;
		transition: color 0.3s ease;
	}
	&__icon{
		fill: $c-white;
		width: 20px;
		height: 14px;
		margin-left: 12px;
		transition: margin-left 0.3s ease;
	}
	&:hover{
		.more-btn{
			&__text{
				color: $c-orange;
			}
			&__icon{
				margin-left: 20px;
			}
		}
	}
}
.index-categorys{
	margin-bottom: 50px;
	position: relative;

	&__grid{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__item{
		width: 190px;
		height: 140px;
		position: relative;
		margin: 5px;
		text-decoration: none;
		overflow: hidden;
		text-overflow: ellipsis;

		&:after{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: "";
			opacity: 0.2;
			background-color: #000;
			transition: opacity 0.3s ease;
			z-index: 2;
		}

		&:hover{
			&:after{
				opacity: 0.3;
			}
			.index-categorys{
				&__item-text{
					color: $c-orange;
				}
			}
		}
	}
	&__item-image{
		position: absolute;
		left: 0;
		top: 0;
		width: 190px;
		height: 140px;
	}
	&__item-text{
		position: absolute;
		padding: 20px;
		left: 0;
		bottom: 0;
		width: 100%;
		color: $c-white;
		font-size: 14px;
		letter-spacing: 0.1em;
		font-weight: 700;
		line-height: 20px;
		transition: color 0.3s ease;
		text-transform: uppercase;
		z-index: 3;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.advantages{
	margin-bottom: 24px;
	position: relative;
	@include media($defp){
		margin-bottom: 54px;
	}
	&:after{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 63%;
		background-color: #ecf6fb;
		content: "";
	}
}
.advantages-grid{
	display: flex;
	position: relative;
	z-index: 10;
	flex-wrap: wrap;
}
.advantages-item{
	width: 100%;
	padding-bottom: 14px;

	@include media($ms){
		width: 50%;
	}

	@include media($md){
		width: 33.33%;
	}

	@include media($mg){
		width: 16.66%;
	}

	&__image{
		display: flex;
		background-color: $c-lbblue;
		width: 130px;
		height: 94px;
		border-radius: 22px;
		justify-content: center;	
		align-items: center;
		padding-top: 16px;
		margin-bottom: 16px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		@include media($def){
			margin-bottom: 36px;
		}
		svg{
			width: 60px;
			height: 58px;
			fill: $c-white;
		}
		&:after{
			position: absolute;
			left:0;
			top: 0;
			width: 100%;
			height: 100%;
			box-shadow: 0px 0px 34px -4px rgba(0,0,0,0.4);
			content: "";
			border-radius: 22px;
			opacity: 0;
			transition: opacity 0.5s ease;
		}
	}
	&__title{
		color: $c-dblue;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 12px;
		border-bottom: 1px solid #d4ebf6;
		text-align: center;
		padding: 0 30px 10px 30px;
		position: relative;
		@include media($def){
			padding: 0 30px 20px 30px;
			margin-bottom: 28px;
		}

		i{
			display: block;
			font-weight: normal;
			font-family: 'Playfair Display';
		}
		&:after{
			position: absolute;
			left: 0;
			bottom: -1px;
			width: 100%;
			height: 2px;
			background-color: $c-lblue;
			content: "";
			transform: rotateX(90deg);
			transition: transform 0.3s ease;
		}
	}
	&__text{
		text-align: center;
		padding: 0 30px;
		font-weight: 100;
		font-size: 14px;
		line-height: 18px;
		color: $c-blue;
		margin-bottom: 20px;
		transition: color 0.3s ease;
	}

	&:hover{
		.advantages-item{
			&__image{
				&:after{
					opacity: 1;
				}
			}
			&__title{
				&:after{
					transform: scale(1);
				}
			}
			&__text{
				color: $c-black;
			}
		}
	}
}
.items-slider{

	.swiper-container{
	    width: calc(100% + 20px);
	    padding-left: 10px;
	    margin-left: -10px;
	}
	.item{
		margin-top: 10px;
	}
	&__item{
		width: 320px;
	}
}
.items-slider-block{
	padding-top: 20px;
	padding-bottom: 22px;
	@include media($defp){
		padding-top: 40px;
		padding-bottom: 42px;
	}
	&__content{
		position: relative;
	}

	.more-btn{
		position: relative;
		right: 0;
		top: 0;
		padding-right:0 !important;
		@include media($defp){
			position: absolute;
		}
		svg{
			fill: #bde0f1;
		}
	}
}


._mgb40{
	margin-bottom: 40px;
}
.search-sort{
	.ui-selectmenu-button{
		width: 280px;
		@include media($md){
		    width: 340px;
		}
	}
}
.search-grid{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 40px;
	align-items: flex-start;
	.item{
		margin-bottom: 20px;
	}
}
._mgb60{
	margin-bottom: 30px;
	@include media($md){
		margin-bottom: 60px;
	}
}
.order{
	.items-slider-tabs__button{
		font-size: 24px;
	}
}
.favorites-item{
	position: relative;
	&__remove{
		width: 52px;
		height: 52px;
		background-color: rgba(241, 250, 251, 0.1);
		border-radius: 100%;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		margin: 0 auto;
		z-index: 26;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		svg{
			width: 22px;
			height: 22px;
			fill: $c-lblue;
			transition: fill 0.3s ease;
		}

		&:hover{
			svg{
				fill: $c-orange;
			}
		}
	}
}
.lk{
	&__nav{
		display: flex;
		flex-direction: column;
		width: 280px;
	}
	&__nav-item{
		display: flex;
		align-items: center;
		height: 46px;
		border-bottom: 1px solid $c-lblue;
		text-transform: uppercase;
		text-decoration: none;
		color: $c-dblue;
		font-size: 14px;
		font-weight: 700;
		line-height: 100%;
		position: relative;
		transition: color 0.3s ease, border 0.3s ease, margin 0.3s ease;

		&.is-active{
			color: $c-black;
			pointer-events: none;

			&:after{
				transform: scaleX(1);
			}
		}

		&:after{
			position: absolute;
			bottom: 0;
			left: 0;
			content: "";
			height: 2px;
			width: 100%;
			background-color: $c-blue;
			transform-origin: left;
			transition: transform 0.3s ease;
			transform: scaleX(0);
		}

		&:hover{
			color: $c-black;

			&:after{
				transform: scaleX(1);
			}
		}
	}
}
.lk-red-form{
	display: flex;
	flex-direction: column;
	align-items: center;

	&__group{
		display: flex;
		justify-content: space-between;
		width: 100%;
		flex-direction: column;

		@include media($ms){
		    flex-direction: row;
		}

		.form__group{
			width: 100%;
			max-width: 100%;

			@include media($ms){
				width: calc(50% - 10px);
				max-width: 350px;
			}
		}
	}
}
.favorites-grid{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}
.lk{
	display: flex;
	max-width: 1200px;
	margin: 0 auto;
	z-index: 1;
	background-color: $c-white;
	position: relative;
	
	&__header{
		height: 80px;
		background-color: #ecf6fb;
		padding: 16px 10px 8px;
		@include media($ms){
		    padding: 26px 20px 20px;
		}
	}
	&__title{
		color: $c-dblue;
		font-size: 24px;
		font-weight: 500;
		line-height: 100%;
		padding-bottom: 14px;
		border-bottom: 1px solid $c-lblue;
	}
	&__block{
		padding: 10px 20px 20px;
		@include media($ms){
			padding: 20px 40px 30px;
		}
	}
	&__info-text{
		color: $c-black;
		font-weight: 100;
		font-size: 16px;
		line-height: 18px;
		width: auto;
		text-align: center;

		@include media($ms){
		    width: 230px;
			text-align: left;
		}
	}
	&__nav{
		display: none;
		@include media($def){
		    display: block;
		}
	}
	&__block-group{
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 18px;
		@include media($ms){
			align-items: flex-start;
		    flex-direction: row;
		}
	}
	&__info-cotext{
		color: $c-dblue;
		font-weight: 500;
		font-size: 16px;
		line-height: 18px;
		text-align: center;

		@include media($ms){
			text-align: left;
		}
	}
	&__content{
		width: 100%;
		margin-left: 0;
		box-shadow: 2px 5px 33px -5px rgba(0,0,0,0.5);

		@include media($def){
			width: calc(100% - 320px);
			margin-left: 40px;
		}
	}
}
.item{
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 14px;
	width: 97%;
	box-sizing: border-box;
	transition: background-color 0.3s ease;
	height: 548px;

	@include media($sm){
		width: 290px;
	}

	@include media($md){
		padding: 20px;
		width: 320px;
	}

	&:after{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: "";
		box-shadow: 0px 0px 18px -4px rgba(0,0,0,0.4);
		opacity: 0;
		transition: opacity 0.3s ease;
		z-index: -1;
	}

	&--page-tovar{
		padding:0 !important;
		background-color: transparent;
		z-index: 25;
		&:hover{
			background-color: transparent !important;
			&:after{
				display: none !important;
			}
		}
	}

	&__labels{
		position: absolute;
		top: 36px;
		right: 0;
		z-index: 3;
	}
	&__not-available{
		font-size: 18px;
	}
	&__label{
		width: 60px;
		height: 60px;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		text-align: center;
		text-transform: lowercase;
		color: $c-white;
		font-size: 12px;
		font-weight: 500;
		text-decoration: none;
		margin-bottom: 4px;
		line-height: 12px;

		&--stock{
			background-color: #e94a4a;
		}
		&--new{
			background-color: #53c3af;
		}
		&--spec{
			background-color: #d5c06b;
		}
	}
	&__favorites{
		width: 32px;
		height: 32px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		svg{
			width: 28px;
			height: 26px;
			fill: transparent;
			stroke: $c-lgrey;
			-webkit-user-select: none;
			-webkit-touch-callout: none;
		}
		&, &:focus{
			svg{
				fill: transparent;
				stroke: $c-lgrey;
			}
		}
		&:hover,&:active{
			svg{
				fill: $c-orange;
				stroke: $c-orange;
			}
		}

		&.is-active{
			svg{
				fill: $c-orange;
				stroke: $c-orange;
			}
		}
	}
	&__descr-bottom-row{
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: center;
		padding: 30px 0 0 0;
		position: relative;
		@include media($ms){
			justify-content: space-between;
		}

		&:before{
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			top: 0;
			height: 2px;
			width: 100%;
			background-color: $c-lblue;
		}
	}
	&__price-row{
		display: flex;
		align-items: flex-end;
		margin-bottom: 28px;
	}
	&__price{
		color: $c-orange;
		font-size: 24px;
	    margin-right: 20px;
		line-height: 100%;
		font-weight: 500;
	}
	&__old-price{
		font-weight: 400;
		font-size: 16px;
		color: $c-dblue;
		text-decoration: line-through;
	}
	&__title{
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		text-decoration: none;
		color: $c-black;
		margin-bottom: 24px;
		display: inline-block;
		height: 48px;
		overflow: hidden;
	}
	&__image-wrapper{
		position: relative;
		margin-bottom: 24px;
		text-align: center;
		height: 280px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&:hover{
		z-index: 25;
		background-color: $c-white; 
 
		&:after{
			opacity: 1;
		}
	}
}
.item-descr{
	.item{
		height:auto;
	}
}
.index-news{
	padding: 60px 0 40px 0;
		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat; 
}
.item-page{
	&__top-wrapper{
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 20px;
		flex-direction: column;
		@include media($md){ 
			flex-direction: row;
		}
		@include media($lg){
		    margin-bottom: 40px;
		}
	}
	&__review-title{
		font-size: 28px;
		line-height: 100%;
		font-weight: 700;
		padding: 10px 0;
		min-height: 52px;
		margin-bottom: 14px;
		border-bottom: 1px solid $c-lblue;

		@include media($def){
			font-size: 30px;
			margin-bottom: 24px;
		}
	}
	&__bottom-row{
		padding-bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
		@include media($defp){
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
		}
	}
	&__review{
		width: 100%;
		padding-bottom: 40px;
		@include media($defp){
			width: 400px;
		}
	}
	&__info{
		align-items: flex-start;
		display: flex;
		flex-direction: column;
	}
}
.callback-form{
	max-width: 740px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__group{
		margin-bottom: 34px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;

		.form__group{
			width: 100%;

			@include media($ms){
				width: calc(50% - 20px);
			}
		}
	}
}
.hidden__title{
	background-color: #ecf6fb;
	margin-bottom: 18px;
	min-height: 82px;
	padding: 20px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 24px;
	line-height: 100%;
	border-radius: 4px 4px 0 0;
	position: relative;
	span{
		display: inline-block;
		border-bottom: 1px solid #bde0f1;
		padding-bottom: 12px;
	}
	&--callback{
		span{
			padding:0 10px 20px;
			@include media($md){
				padding:0 70px 20px;
			}
		}

	}
}
.custom-cross{
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 6px;
	right: 5px;
	border-radius: 100%;
	background-color: #dff2f7;
	cursor: pointer;
	z-index: 15;
	@include media($md){
		width: 52px;
		height: 52px;
		top: 12px;
		right: 12px;
	}
	svg{
		fill: $c-orange;
		width: 18px;
		height: 18px;
		@include media($md){
			width: 24px;
			height: 24px;
		}
	}
}
.mfp-close{
	display: none!important;
}
.one-click-buy-form{
	max-width: 340px;
	width: 100%;
	margin: 0 auto;

	.control-holder--text{
		margin-bottom: 28px;
	}
	.form__caption{
		margin-bottom: 14px;
		text-align: center;
	}
}
.hidden-wrapper{
	&__content{
		padding: 14px 20px 80px;
		position: relative;
		z-index: 5;
	}
}
.items-slider-tabs{
	padding: 20px 0;
	background-size: cover;
	.items-slider-block{
		padding: 0;
	}
	&__buttons{
		flex-wrap: wrap;
		justify-content: center;
		display: flex;
		margin-bottom: 20px;
	}
	&__title{
		margin-bottom: 20px;
		font-size: 20px;
		line-height: 100%;
		font-weight: 500;
		font-family: 'Museo Sans';
		color: $c-dblue;
		@include media($def){
			margin-bottom: 32px;
			font-size: 24px;
		}
		span{
			color: $c-black;
		}
	}
	&__button{
		min-height: 52px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		line-height: 100%;
		font-weight: 700;
		font-family: 'Museo Sans';
		color: $c-dblue;
		position: relative;
		transition: color 0.5s ease;
		margin-right: 20px;
		border-bottom: 1px solid $c-blue;

		@include media($md){
			border-bottom: 0;
		}
		@include media($lg){
			margin-right: 46px;
			font-size: 30px;
		}
		&:after{
			position: absolute;
			content: "";
			top: 100%;
			height: 2px;
			width: 100%;
			background-color: $c-orange;
			left: 0;
			opacity: 0;
		}
		&.is-active{
			color: $c-black;
			&:after{
				opacity: 1;
			}
		}
	}
}
.item-info-list{
	display: flex;
	flex-direction: column;
	border-top: 1px solid $c-lblue;
	&__item{
		min-height: 92px;
		padding: 20px 0;
		display: flex;
		border-bottom: 1px solid $c-lblue;
		align-items: center;
		position: relative;
		flex-direction: column;
		@include media($md){
			flex-direction: row;
		}
		&:hover{
			.info-more-btn{
				opacity: 1;
				transform: scale(1);
			}
		}

		.info-more-btn{
			display: inline-block;
			@include media($md){
				display: inline-flex;
			}
		}
	}
	&__descr{
		display: flex;
		flex-direction: column;
		padding-right: 0;
		text-align: center;

		@include media($md){
			padding-right: 135px;
			text-align: left;
		}


	}
	&__title{
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 8px;
		color: $c-dark;
		text-align: center;

		@include media($md){
			text-align: left;
		}
	}
	&__text{
		font-size: 16px;
		font-weight: 100;
		color: $c-dblue;
		margin-bottom: 20px;

		@include media($md){
			margin-bottom: 0;
		}
	}
	&__image{
		display: flex;
		justify-content: center;
		align-content: center;
		margin-right: 0;

		@include media($md){
			margin-right: 30px;
		}
		
		svg{
			width: 42px;
			height: 42px;
			fill: $c-teal;
		}
	}
}
.info-more-btn{
	position: relative; 
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto 0;
	letter-spacing: 0.15em;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
	color: $c-dark;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	transition: transform 0.3s ease, opacity 0.5s ease;
	transform: scale(1);
	opacity: 1;
	@include media($md){
		transform: translateX(45px) scale(0.9);
		opacity: 0;
		position: absolute;
	}

	svg{
		width: 20px;
		height: 12px;
		fill: $c-lblue;
		margin-left: 8px;
	}
}
.rating{
	display: flex;
	&__star{
		display: flex;
		margin-right: 10px;
		svg{
			fill: #eee6d0;
		}
		&:last-of-type{
			margin-right: 0;
		}
		&.is-active{
			svg{
				fill: $c-orange;
			}
		}
	}
	&--item-main{
		.rating__star{
			width: 34px;
			height: 34px;
			svg{
				width: 34px;
				height: 34px;
			}
		}
	}
	&--item-list{
		justify-content: center;

		.rating__star{
			width: 22px;
			height: 22px;
			svg{
				width: 22px;
				height: 22px;
			}
		}
	}
}

.feed-rating{
	display: block;
	margin-bottom: 30px;
	&__count{
		color:#00a2ff;
		font-size:12px;
		line-height: 33px;
	}
}
.item-info__rating{
	margin-bottom: 30px;
}
.feed-enreg{
	font-weight: 700;
	font-size: 14px;
	text-transform: uppercase;
	color: $c-black;
	letter-spacing: 0.1em;
	text-decoration: none;
	margin-bottom: 20px;
	display: inline-block;
	outline: none;

	span{
		margin-right: 6px;
	}
	svg{
		width: 18px;
		height: 12px;
		fill: $c-lblue;
	}

	&:hover{
		color: $c-orange;
	}
}
.feed-form{
	&__text{
		font-size: 14px;
		color: $c-dblue;
		font-weight: 100;
		margin-bottom: 8px;
		margin-top: 20px;
	}
}
.feed-rating__stars {
	display: inline-block;
	vertical-align: middle;
}

.feed-rating__stars:not(:checked) > input {
	position:absolute;
	top:-9999px;
	clip:rect(0,0,0,0);
	visibility:hidden;
}

.feed-rating__stars:not(:checked) > label {
	float:right;
	width:34px;
	padding: 0;
	overflow:hidden;
	white-space:nowrap;
	cursor:pointer;
	height: 34px;
	margin-right: 12px;
}

.feed-rating__stars:not(:checked) > label:before {
	content: '';
	display: inline-block;
	width: 32px;
	height: 32px;
	background-image: url(pic/rnon.svg);
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	margin-left: 0;
	margin-right: 12px;
}

.feed-rating__stars > input:checked ~ label:before {
	background-image: url(pic/rok.svg);
}

.feed-rating__stars:not(:checked) > label:hover,
.feed-rating__stars:not(:checked) > label:hover ~ label {
	opacity:0.7;
}

.feed-rating__stars:not([data-feed-rating="1"]) > label:active {
	position:relative;
	top:1px;
}

.feed-rating__stars > label:active {
	position:relative;
	top:1px;
}

.feed-rating__stars{
	&[data-feed-rating="1"] label:nth-last-of-type(1),
	&[data-feed-rating="2"] label:nth-last-of-type(-n+2),
	&[data-feed-rating="3"] label:nth-last-of-type(-n+3),
	&[data-feed-rating="4"] label:nth-last-of-type(-n+4),
	&[data-feed-rating="5"] label:nth-last-of-type(-n+5) {
		&:hover{
			cursor:default;
			opacity:1;
		}
		&:before{
			background-image: url(pic/feed-rating-active.svg);
		}
	}
}

.feed-rating__stars{
	&[data-feed-rating="1"] label:hover ~ label,
	&[data-feed-rating="2"] label:hover ~ label,
	&[data-feed-rating="3"] label:hover ~ label,
	&[data-feed-rating="4"] label:hover ~ label,
	&[data-feed-rating="5"] label:hover ~ label{
		opacity:1;
		cursor:default;
	}
}
.feed-rating__stars{
	&[data-feed-rating="1"] label:hover,
	&[data-feed-rating="2"] label:hover,
	&[data-feed-rating="3"] label:hover,
	&[data-feed-rating="4"] label:hover,
	&[data-feed-rating="5"] label:hover,
	&[data-feed-rating="1"] label,
	&[data-feed-rating="2"] label,
	&[data-feed-rating="3"] label,
	&[data-feed-rating="4"] label,
	&[data-feed-rating="5"] label{
		opacity:1;
		cursor:default;
	}
}

.feed-rating__stars{
	&[data-feed-rating="1"] label:active,
	&[data-feed-rating="2"] label:active,
	&[data-feed-rating="3"] label:active,
	&[data-feed-rating="4"] label:active,
	&[data-feed-rating="5"] label:active{
		top:0;
	}
}
.item-resp-row{
	display: block;
	width: 100%;
	margin-bottom: 20px;
	@include media($lg){
		display: none;
	}
}
.item-normal-row{
	display: none;
	@include media($lg){
	    display: block;
	}
}
.item-descr{
	width: 100%;
	padding-left: 5px;
	flex-shrink: auto;
	@include media($md){
		width: 32%;
	}
	@include media($def){
		width: calc(100% - 460px);
		padding-left: 26px;
	}

	@include media($defp){
	    padding-left: 2%;
	}

	@include media($lg){
		width: 50%;
		flex-shrink: 0;
	}

	@include media($mg){
	    padding-left: 6%;
	}

	&__rating{
		display: flex;
	}
	&__spinner{
		padding-top: 30px;
		margin: 0 36px 0 60px;
	}
	&__price-block{
		display: flex;
		flex-direction: column;
		padding-top: 20px;
		text-align: center;
		@include media($md){

		}
		@include media($def){
			padding-top: 30px;
		}
		@include media($lg){
		    text-align: left;
		}
	}
	&__price{
		font-size: 36px;
		color: $c-orange;
		margin-bottom: 10px;
		font-weight: 500;
		text-align: center;
		@include media($lg){
		    text-align: left;
		}
	}
	&__bottom-row{
		display: flex;
		justify-content: center;
		align-content: flex-start;
		margin-bottom: 30px;
		flex-wrap: wrap;

		@include media($defp){
			justify-content: flex-start;
		}

		@include media($mg){
		    justify-content: space-between;
		}
	}
	&__old-price{
		font-weight: 100;
		font-size: 18px;
		text-decoration: line-through;
	}
	&__status{
		color: $c-teal;
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 10px;
		@include media($lg){
		    margin-bottom: 0; 
		}
	}
	&__top-row{
		margin-bottom: 0;
		display: flex;
		justify-content: space-between;
		align-items: center; 
		flex-direction: column; 
		@include media($md){
			margin-bottom: 50px;
		}
		@include media($lg){
		    flex-direction: row;
		}
	}
	&__group{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__feeds-count{
		font-weight: 100;
		cursor: pointer;
		font-size: 16px;

		span{
			font-weight: 500;
			text-decoration: underline;
		}
	}
	&__buy-block{
		.item__descr-bottom-row{
			align-items: flex-start;
			&:before{
				display: none; 
			}
		}
		.item__favorites{
			margin: 4px 0 0 14px;

			@include media($def){
				margin: 8px 0 0 28px;
			}
		}
	}
}
.rating-star{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	margin: 0 2px;
	height: 24px;
	@include media($defp){
		margin: 0 10px;
	}
	svg{
		width: 20px;
		height: 20px;
		fill: $c-dblue; 
	}

	&.is-active{
		svg{ 
			fill: $c-gold;
		}
	}
}
.cart-item__opener{
	.cart-button{
		height: 52px;
	}
}
.blue-decor{
	position: absolute;
	font-size: 100px;
	line-height: 100%;
	color: $c-lblue;
	opacity: 0.3;
	display: none;
	font-family: 'Playfair Display';
	font-style: italic;
	@include media($def){
		display: block;
	}
	&--top{
		top: -20px;
		bottom: auto;
	}
	&--bottom{
		bottom: 6px;
		top: auto;
	}
	&--right{
		right: 10px;
		left: auto;
	}
	&--left{
		left: 30px;
		right: auto;
	}
}
._tac{
	text-align: center;
}
.inner-page{
	padding-bottom: 30px;

	&__content{
		z-index: 5;
		position: relative;
	}

	&--search{
		p{
			font-size: 16px;
			font-family: 'Museo Sans';
			font-weight: 600;
		}
	}
}
._mgb70{
	margin-bottom: 70px;
}
.pagination{
	padding-bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap:wrap;

	&__btn{
		min-width: 30px;
		height: 30px;
		color: $c-dblue;
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		border-radius: 100%;
		margin: 0 6px;
		transition: background-color 0.3s ease, color 0.3s ease;
		font-weight: 500;
		font-size: 16px;

		@include media($md){
			min-width: 38px;
			height: 38px;
			font-size: 18px;
			margin: 0 14px;
		}

		&:hover{
			background-color: $c-orange;
			color: $c-white;
		}

		&.is-active{
			background-color: $c-lgrey;
			pointer-events: none;
			color: $c-white;
		}
	}
	&__arrow{
		text-decoration: none;
		display: none;

		@include media($ms){
			display: block;
		}
		svg{
			width: 24px;
			height: 14px;
			fill: #a9acbd;
		}

		&--left{
			margin-right: 40px;
		}

		&--right{
			margin-left: 40px;
		}

		&:hover{
			svg{
				fill: $c-orange;
			}
		}
	}
}
.main-slider{
	position: relative;
	width: 100%;
	overflow: hidden;
	max-height:490px;
	@include media($defp){
		width: 62.5%;
	}
	&__item{
		height: 300px;
		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat;
		position: relative;

		@include media($ms){
			height: 340px;
		}
		@include media($lg){
		    height: 490px;
		}
	}
	&__descr{
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 20px;
		z-index: 15;
		max-width: 540px;

		@include media($md){
		    padding:  50px 40px;
		}
	}
	&__text{
		color: $c-white;
		font-size: 26px;
		line-height: 100%;
		font-weight: 700;
		margin-bottom: 24px;

		@include media($md){
		    font-size: 34px;
		}

		@include media($lg){
			font-size: 48px;
		}

		i{
			font-weight: normal;
			font-family: 'Playfair Display';
		}
	}
}
.arrows-group{
	position: absolute;
	display: none;
	flex-direction: column;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto 0;
	z-index: 15;
	height: 100px;

	@include media($md){
	    display: flex;
	}
}
.main-slider-block{
	display: flex;
	flex-direction: column;
	margin-bottom: 60px;
	position: relative;
	
	&:after{
		position: absolute;
		width: 628px;
		height: 935px;
		right: -154px;
		bottom: -470px;
		z-index: -1;
		background-image: url(../images/circle_bg.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50%;
		content: "";
	}
	@include media($defp){
	    flex-direction: row;
	}
}
.main-slider-banner{
	width: 100%;
	position: relative;
	background-size: cover;
	background-position: 50%;
	background-repeat: no-repeat;
	height: 300px;
	&:before{
		content:'';
		position: absolute;
		left:0;
		top:0;
		background: rgba(0,0,0,0.3);
		z-index: 2;
		width: 100%;
		height: 100%;
	}
	@include media($ms){
		height: 340px;
	}
	@include media($defp){
	    width: 37.6%;
	}
	@include media($lg){
	    height: 490px;
	}
}
.slider-custom-arrow{
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	background-color: $c-lwblue;
	cursor: pointer;
	transition: background-color 0.3s ease;
	user-select: none;
	margin: 0 auto;

	svg{
		width: 24px;
		height: 16px;
		fill: $c-darken;
		transition: fill 0.3s ease;
	}
	&:hover{
		background-color: $c-darken;
		svg{
			fill: $c-lwblue;
		}
	}
}
.partners{
	height: 180px;
	padding: 30px 0;
	border-top: 1px solid $c-lblue;
	cursor: grab;
	border-bottom: 1px solid $c-lblue;
	background-color: $c-white;
	&__item{
		max-width: 230px;
		max-height: 110px;
		display: flex;
		img{
			margin: 0 auto;
		}
	}
}
.soc-main-item{
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	border-radius: 8px;
	margin: 0 12px 12px 0;
	position: relative;	
	overflow: hidden;
	border: 2px solid $c-orange;
	transition: background-color 0.3s ease;
	&__content{
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		transition: top 0.5s ease;
		svg{
			fill: $c-dblue;
		}

		&--copy{
			position: absolute;
			top: 300%;
			bottom: 0;
			right: 0;
			left: 0;
			margin: auto;
			transition: top 0.5s ease;

			svg{
				fill: $c-white;
			}
		}
	}
	svg{
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		width: 26px;
		height: 26px;
	}
	&:hover{
		background-color: $c-orange;
		.soc-main-item__content{
			top: -300%;
			&--copy{
				position: absolute;
				top: 0;
			}
		}
	}
}
._fc{
	display: flex;
	justify-content: center;
	align-items: center;
}


@import '_partials/_js-nav';

.js-main-slider{
	opacity:0;
	visibility:hidden;
}

.js-main-slider.swiper-container{
	opacity:1;
	visibility:visible;
}

.wrapper-sliders{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: nowrap;
	//max-width:900px;
	margin: 0 auto;
	width: 100%;
	@include media($md){
		width: 68%;
	}
	@include media($lg){
		width: 50%;
	}
	@include media($lg){
		margin: 0;
	}
}
.sub-items{
	max-width: 128px;
	flex-basis: 128px;
	width:128px;
	display: none;
	@include media($ms){
		display: inline-block;
	}

	@include media($def){
		max-width: 150px;
		flex-basis: 150px;
		width:150px;
	}
	&__list{
		position: relative;
		max-height: 608px;
	}
	&__item{
		margin:10px 14px;
		max-height: 630px;
		position: relative;
		height: 100px !important;
		width: 100px !important;
		@include media($def){
			height: 120px !important;
			width: 120px !important;
		}
		&:before{
			content: '';
			position: absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			transition: .6s ease;
		}
		&:hover:before{
			box-shadow: rgba(0,0,0,.4) 0 4px 34px -2px;
		}
		&.slick-current{
			&:before{
				content: '';
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
				box-shadow: rgba(0,0,0,.4) 0 4px 34px -2px;
                z-index: -1;
			}
		}
		a{
			display: block;
			height: 100%;
            position: relative;
            z-index: 1;
		}
		img{
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			max-height: 100%;
		}
	}
	&__slider{

	 }
	.slider-custom-arrow{
		position: absolute;
		z-index:20;
		&--left{
			top:-32px;
			left:50%;
			transform:translateX(-50%);
		}
		&--right{
			bottom:-32px;
			left:50%;
			transform:translateX(-50%);
		}
	}
}

.item-sliders{
	position: relative;
	max-width: 100%;
	flex-basis: 100%;
	width:100%;
	max-height:610px;
	overflow: hidden;
	padding:0 10px;
	@include media($ms){
		max-width: calc(100% - 120px);
		flex-basis: calc(100% - 120px);
		width:calc(100% - 120px);
	}
	@include media($def){
		padding:0;
		max-width: calc(100% - 150px);
		flex-basis: calc(100% - 150px);
		width:calc(100% - 150px);
	}
	&__list{
		margin-top:-20px;
	}
	&__item{
		padding:20px;
		height: 480px;
		display:flex !important;
		align-items:center;
		justify-content: center;
		cursor:pointer;
		outline:none;
		@include media($ms){
			height: 600px;
		}
		img{
			box-shadow: 0px 9px 33px 0px rgba(0, 0, 0, 0.4);
			height: auto;
		}
	}
}

.item-label{
	position: absolute;
	right:0;
	top:20px;
	z-index: 2;
	min-width: 160px;
}

.contacts__title._mgb10{
	margin-bottom: 10px;
}

._mt10{
	margin-top: 10px;
}

.mfp-image-holder .mfp-close{
	display: block !important;

}

.widget{
	&--filter-tovar{
		padding:0;
		@include media($def){
			padding:0 20px;
		}
	}
}

.view-grid-else{
	padding:0;
	width: 100%;
}

.catalog{
	&--page{
		margin:0;
		@include media($def){
			margin:0 -20px;
		}
	}
}

.sub-items__list{
  opacity:0;
  visibility: hidden;
  &.slick-initialized{
    opacity:1;
    visibility: visible;
  }
}

.item-sliders__list{
  opacity:0;
  visibility: hidden;
  &.slick-initialized{
    opacity:1;
    visibility: visible;
  }
}

.selectize-control{
	width: 100%;
}
.selectize-input{
	outline: none;
	background-color: #fff;
	border: 2px solid #bde0f1;
	border-radius: 8px;
	height: 44px;
	-webkit-box-align: center;
	align-items: center;
	display: -webkit-box;
	display: flex;
	padding-right: 30px;
	padding-left: 14px;
	max-width: auto;
}

.selectize-input .item{
	height: auto !important;
	padding:0;
	display: inline-block !important;
	box-sizing: inherit !important;
	font-weight: 100;
	font-family: 'Museo Sans';
	font-size: 14px;
	color: #445568;
	transition: color 0.5s ease;
	line-height: 24px !important;
	width: auto !important;
	&:after{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: "";
		box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.4);
		opacity: 0;
		transition: opacity 0.3s ease;
		z-index: -1;
		display: none !important;
	}
}

.selectize-control.single .selectize-input:after{
	background: url(../images/arrow_bottom.svg);
	background-repeat: no-repeat;
	background-size: cover;
	width: 14px;
	height: 6px;
	position: absolute;
	right: 14px;
	top: 2px;
	bottom: 0;
	margin: auto 0;
	content: "";
	-webkit-transition: -webkit-transform .3s ease;
	transition: -webkit-transform .3s ease;
	transition: transform .3s ease;
	transition: transform .3s ease, -webkit-transform .3s ease;
	border:none !important;
}

.selectize-control.single .selectize-input.input-active, .selectize-input{
	border-radius: 8px !important;
}

.selectize-control.single .selectize-input.dropdown-active:after {
	margin-top: auto !important;
	border:none !important;
}

.selectize-dropdown{
	border: 1px solid #bde0f1 !important;
	border-radius: 3px !important;
}

.selectize-dropdown [data-selectable]{
	min-height: 44px;
	display: flex;
	align-items: center;
	padding-right: 30px;
	padding-left: 14px;
	background-color: #fff;
	border-bottom: 1px solid #bde0f1;
	height: 44px;
	font-weight: 100;
	font-family: 'Museo Sans';
	font-size: 14px;
	color: #445568;
	transition: color 0.5s ease;
}
.selectize-control{
	height:44px !important;
}

.notate-order{
	color: #445568;
	margin: 0;
	font:{
		size: 12px;
		style: italic;
	}
	@include media($md){
		font:{
			size: 14px;
		}
	}
}

.partners{
	opacity:0;
	visibility: hidden;
	&.swiper-container{
		opacity:1;
		visibility: visible;
	}
}

.item__descr-bottom-row{
	flex-wrap: wrap;
}

.item__spec--row{
	width: 100%;
	margin-bottom: 20px;
}
.item__spec{
	margin-bottom: 8px;
	font-weight: bold;

	b{
		font-weight: normal;
	}
}
.item{

	height: auto;
}
._bottom-mega-hr-line{
	position: relative;
	width: 100%;
	border-top: 1px solid #bde0f1;
}

.is-costil{
	.item-descr__bottom-row{
		flex-direction: column;
		padding: 0;
	}
}



.search-results{
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	max-width: 100%;
	background-color: $c-white;
	border-radius: 2px;
	border: 1px solid #ecf6fb;
	display: none;
	padding: 14px;
	//max-height: calc(100vh - 150px);
	max-height: 100vh;
	overflow: auto;

	&.is-open{
		display: block;
	}

	&__list{
		list-style-type: none;
		padding: 0;
		margin: 0;
		margin-bottom: 14px;
		border-top: 1px solid #fe5400;
	    padding-top: 10px;
	    padding-bottom: 10px;
    	margin-top: 10px;
		border-bottom: 1px solid #fe5400;
	}
	&__title{
		padding: 4px 0;
		display: block;
		font-weight: 100;
		font-size: 16px;
		color: #000;
		transition: color 0.1s ease;
		text-decoration: none;

		&:hover{
			color: $c-orange;
		}
	}
	&__allresult{
		color: $c-black;
		font-size: 14px;
		display: block;
		font-weight: 300;
		text-decoration: none;

		&:hover{
			color: $c-orange;
		}
	}
}

.result-item{
	text-decoration: none;
	display: flex;
	padding: 9px 4px 9px 0;

	&__descr-bottom{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	&__descr{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: calc(100% - 80px);
		padding-left: 4px;
	}
	&__image{
		border: 1px solid grey;
		max-width: 75px;
		max-height: 75px;
		margin: auto;
		transition: border-color 0.1s ease;
	}
	&__status{
		color: #4da2cb;

		&--in-stock{
			color: light-green;
		}
		&--on-stock{
			color: light-orange;
		}
		&--not-available{
			color: tomato;
		}
	}
	&__price{
		font-size: 18px;
		font-weight: 500;
		color: black;
		@include media ($def) {
			font-size: 15px;
		}
	}
	&__title{
		font-size: 14px;
		font-weight: 300;
		max-height: 56px;
		color: #445568;
		overflow: hidden;
		transition: color 0.1s ease;
	}
	&:hover{
		.result-item{
			&__image{
				border-color: black;
			}
			&__title{
				color: #fe5400;
			}
		}
	}
}
