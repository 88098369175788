////
/// @group _partials/view-header.scss
////


// scss
// ================================

.view-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 50;
	height: 60px;
	background-color: $c-white;
	@include media($def){
		position: relative;
		height: auto;
	}

	&__bottom-content{
		display: flex;
		border-top: none;
		position: absolute;
		right: 0;
		top: 8px;
		align-items: center;

		@include media($def){
			border-top: 1px solid $c-lblue;
			position: relative;
			top: 0;
		}
	}
	&__top-content{
		display: flex;
		justify-content: space-between;
		margin-bottom: 0;
		width: 100%;
		@include media($def){
			margin-bottom: 18px;
		}
	}
	&__content{
		position: relative;
		z-index: 15;
		height: 60px;
		display: flex;
		align-items: center;
		width: 100%;
		background-color: $c-white;
		@include media($def){
			height: auto;
			width: auto;
			display: block;
			align-items: stretch;
		}
	}
	&__widgets{
		display: flex;
		padding-top: 0;
		padding-right: 50px;
		@include media($md){
			padding-right: 60px;
		}
		@include media($def){
			padding-top: 14px;
			padding-right: 0;
		}
	}
	&__logo{
		display: none;
		@include media($def){
			display: block;
		}
	}
	&__callback-btn{
		//padding-top: 30px;
		align-items: center;
		justify-content: center;
		display: none;
		@include media($def){
			display: flex;
		}
		
		.button__content{
			height: 48px;
			width: 48px;
			padding: 0;
			transition: background-color 0.3s ease;
			@include media($lg){
				height: 48px;
				width: 230px;
			}
			span{
				display: none;
				@include media($lg){
					display: flex;
				}
			}
			&:before,
			&:after{
				display: none!important;
				@include media($lg){
					display: flex!important;
				}
			}
		}
		.button{
			&:hover{
				.button__icon{
					fill: $c-white;
				}
				.button__content{
					background-color: $c-orange;
				}
			}
		}
		.button__icon{
			display: flex;
			margin: 0;
			width: 28px;
			height: 28px;
			fill: $c-dblue;
			@include media($lg){
			    display: none;
			}
		}
	}
	&__contacts-block{
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
		padding-top: 32px;
		margin: 0 14px;
		display: none;
		@include media($def){
			display: flex;
		}
	}
}
