.lk-orders {

  &__head,
  &__order {
    background-color: transparent;
    text-align: center;
    transition: .25s ease;
  }

  &__head {
    color: #000;
    font-weight: bold;
    border-bottom-width: 12px;
  }

  &__order {
    &:last-child {
      border-bottom-width: 0;
    }
  }

  &__order--done &__status {
    color: #4dab19;
  }

  &__order--done {
    background-color: lighten(#4dab19, 56%);
  }

  &__order--in-progress &__status {
    color: blue;
  }

  &__order--canceled {
    background-color: lighten(red, 27%);
  }

  &__order--canceled &__status {
    color: red;
  }

  &__toggler {
    cursor: pointer;
    user-select: none;
    transition: background-color 0.5s ease;

    .no-touchevents &:hover {
      background-color: #ecf6fb;
    }

    td:first-child {
      width: 14px;
      svg{
        width: 14px;
        height: 12px;
      }
    }
  }

  &__info {

    border-top: 1px solid #000;

    :not(.is-active) > & {
      display: none;
    }
  }
}

.lk-history{
  padding: 20px 0;
  overflow: overlay;

  &__table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    td {
      padding: 11px .5rem;
    }
  }
}


.lk-order {
  width: 100%;
  max-width: 708px;

  td:first-child {
    width: 2rem;
  }

  &__service {
    td {
      padding-top: 0;
    }
  }

  &__item {
    &:not(:first-child) td {
      border-top: 1px solid darken(#ccc, 7%);
    }
  }

  &__img {
    display: block;
    padding: 10px;
    border: 1px solid darken(#ccc, 10%);
    background-color: #fff;
    transition: border-color 0.5s ease;
    span {
      display: block;
      width: 90px;
      height: 80px;
      background: center no-repeat;
      background-size: contain;
      margin: auto;

      .lk-order__service & {
        height: 50px;
        width: 65px;
      }
    }
    .lk-order__service & {
      margin-left: 25px;
    }
    &:hover {
      border-color:darken(#ccc, 30%);
    }
  }

  &__link {
    white-space: normal !important;
    text-align: left;
    line-height: 1.4;

    a {
      font-weight: bold;
      text-decoration: none;
      font-size: 90%;
      color: inherit;
      transition: color 0.5s ease;
      &:hover {
        color: blue;
      }
    }
  }

  &__total {
    > td {
      width: auto;
      border-top: 1px solid darken(#ccc, 20%);
      padding-bottom: 0;
    }
  }
}